import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Question} from "../../../models/Question";
import {QuestionService} from "../../../services/question.service";
import {AnswerOption} from "../../../models/AnswerOption";
import {AnswerOptionService} from "../../../services/answer-option.service";
import {QuestionType} from "../../../models/QuestionType";
import {AngularEditorConfig, UploadResponse} from "@kolkov/angular-editor";
import {QuestionManagerComponent} from "../question-manager.component";
import {AngularEditorConfigsUtil} from "../../../util/angular-editor-configs.util";
import {ActivatedRoute} from "@angular/router";
import {catchError, from, map, Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpEvent, HttpResponse} from "@angular/common/http";
import {NotificationModel} from "../../../models/NotificationModel";
import {EditorValid} from "../../../util/editor-valid";

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})
export class AddQuestionComponent implements OnInit {

  quizId: string | undefined;
  questionEditorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons.filter(button => button !== 'insertImage')
    ],
    upload: (file: File) => this.uploadImageForEditor(file)
  }
  configForDescriptiveEditor: AngularEditorConfig = {
    editable: false,
    sanitize: false,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  }
  config: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons.filter(button => button !== 'insertImage')
    ],
    upload: (file: File) => this.uploadImageForEditor(file)

  };
  @ViewChild('modalBody') modalBody: ElementRef | undefined;
  loading = false;
  serialNumber: number = 0;
  addQuestionForm!: FormGroup;
  questionTypes = [
    {enumType: QuestionType.SINGLE_SELECT, type: 'Single choice'},
    {enumType: QuestionType.MULTI_SELECT, type: 'Multiple choice'},
    {enumType: QuestionType.TRUE_OR_FALSE, type: 'True or False'},
    {enumType: QuestionType.SHORT_ANSWER, type: 'Short answer'},
    {enumType: QuestionType.DESCRIPTIVE, type: 'Descriptive'}
  ];
  protected readonly QuestionType = QuestionType;
  editQuestion!: Question | null;
  protected readonly EditorValid = EditorValid;
  isChecked = false;

  constructor(private questionManagerComponent: QuestionManagerComponent,
              private questionService: QuestionService,
              private answerOptionService: AnswerOptionService,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParams => {
      this.quizId = queryParams['id'];
    })
  }

  ngOnInit(): void {
    this.onSuccess();
  }

  get answers(): FormArray {
    return this.addQuestionForm.get('answers') as FormArray;
  }

  get shortAnswers(): FormArray {
    return this.addQuestionForm.get('shortAnswers') as FormArray;
  }

  get trueOrFalseAnswers(): FormGroup {
    return this.addQuestionForm.get('trueOrFalseAnswers') as FormGroup;
  }

  get askValue(): string {
    return this.addQuestionForm.get('ask')?.value
  }


  addAnswer(variantName: string = '', isSingleCorrect: boolean = false, isMultiCorrect: boolean = false): void {
    const control = this.answers;
    control.push(
      new FormGroup({
        variantName: new FormControl(variantName, [Validators.required]),
        isSingleCorrect: new FormControl(isSingleCorrect),
        isMultiCorrect: new FormControl(isMultiCorrect),
      })
    );
  }

  addShortAnswer(variantName: string = ''): void {
    const control = this.shortAnswers;
    control.push(
      new FormGroup({
        variantName: new FormControl(variantName.trim(), [Validators.required]),
      })
    );
  }

  removeAnswer(i: number): void {
    const control = this.answers;
    control.removeAt(i);
  }

  removeShortAnswer(i: number): void {
    const controls = this.shortAnswers;
    controls.removeAt(i);
  }

  selectSingleCorrectAnswer(i: number): void {
    this.answers.controls.forEach((control, index) => {
      control.get('isSingleCorrect')?.setValue(index === i);
    });
  }

  selectTrueOrFalseAnswer(i: number): void {
    this.trueOrFalseAnswers.get('isPositiveOptionCorrect')?.setValue(i === 0);
    this.trueOrFalseAnswers.get('isNegativeOptionCorrect')?.setValue(i === 1)
  }

  isSingleChoiceAnswersValid(): boolean {
    var filteredByVariantNameNotBlank: FormGroup[] = this.filterByVariantNameNotBlank(this.answers);
    const filledAnswersCount = filteredByVariantNameNotBlank.length;
    const correctAnswersCount = filteredByVariantNameNotBlank.map((control) => control as FormGroup)
      .filter((group) => group.get('isSingleCorrect')!.value != '').length;
    return this.areBasicFieldsValid() && filledAnswersCount >= 2 &&
      correctAnswersCount == 1;
  }

  isMultiChoiceAnswersValid(): boolean {
    var filteredByVariantNameNotBlank: FormGroup[] = this.filterByVariantNameNotBlank(this.answers);
    const filledAnswersCount: number = filteredByVariantNameNotBlank.length;
    const correctAnswersCount: number = filteredByVariantNameNotBlank.map((control) => control as FormGroup)
      .filter((group) => group.get('isMultiCorrect')!.value != '').length;
    return this.areBasicFieldsValid() && filledAnswersCount >= 3 && correctAnswersCount >= 2;
  }

  isTrueOrFalseQuestionValid(): boolean {
    const isPositiveOptionCorrect = this.trueOrFalseAnswers.get('isPositiveOptionCorrect')?.value;
    const positiveOptionVariantName = this.trueOrFalseAnswers.get('positiveOptionVariantName')?.value;
    const isNegativeOptionCorrect = this.trueOrFalseAnswers.get('isNegativeOptionCorrect')?.value;
    const negativeOptionVariantName = this.trueOrFalseAnswers.get('negativeOptionVariantName')?.value;

    return this.areBasicFieldsValid() && (isPositiveOptionCorrect || isNegativeOptionCorrect) &&
      positiveOptionVariantName !== "" && negativeOptionVariantName !== "";
  }

  isShortAnswersValid(): boolean {
    const filledAnswersCount: number = this.filterByVariantNameNotBlank(this.shortAnswers).length;
    return this.areBasicFieldsValid() && filledAnswersCount >= 1;
  }

  areBasicFieldsValid(): boolean {
    const ask = this.addQuestionForm.get('ask') as FormControl;
    const questionType = this.addQuestionForm.get('questionType') as FormControl;
    const score = this.addQuestionForm.get('score') as FormControl;
    return ask.valid &&
      score.valid &&
      questionType.valid;
  }

  filterByVariantNameNotBlank(answersArray: FormArray): FormGroup[] {
    return answersArray.controls
      .map((control) => control as FormGroup)
      .filter((group) => {
        const variantNameValue = group.get('variantName')!.value;
        return variantNameValue !== '' && variantNameValue !== '<br>';
      });
  }

  isFormValid(): boolean {
    const questionType = this.addQuestionForm.get('questionType')?.value;
    switch (questionType) {
      case QuestionType.SINGLE_SELECT:
        return this.isSingleChoiceAnswersValid();
      case QuestionType.MULTI_SELECT:
        return this.isMultiChoiceAnswersValid();
      case QuestionType.TRUE_OR_FALSE:
        return this.isTrueOrFalseQuestionValid();
      case QuestionType.SHORT_ANSWER:
        return this.isShortAnswersValid();
      case QuestionType.DESCRIPTIVE:
        return this.areBasicFieldsValid();
      default:
        return false;
    }
  }

  save() {
    if (this.modalBody) {
      this.modalBody.nativeElement.scrollTop = 0;
    }
    if (this.isFormValid()) {
      const question = this.addQuestionForm.getRawValue() as Question
      const ask = this.addQuestionForm.get('ask') as FormControl;
      question.ask = (ask.value as string).replace(/&#160;/g, '');
      question.serialNumber = this.serialNumber;
      question.quizId = this.quizId!;
      this.saveOrEditQuestion(question);
    }
  }

  saveOrEditQuestion(question: Question): void {
    this.loading = true;
    if (this.editQuestion) {
      question.id = this.editQuestion.id;
      this.questionService.editQuestion(question).pipe(
        catchError((httpError: HttpErrorResponse): Observable<any> => this.handleEditError(httpError, question))
      ).subscribe(
        (savedQuestion) => {
          this.saveAnswerOptions(savedQuestion, question);
        }
      );
    } else
      this.questionService.saveQuestion(question).subscribe(
        (savedQuestion) => {
          this.saveAnswerOptions(savedQuestion, question);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private handleEditError(httpError: HttpErrorResponse, question: Question): Observable<any> {
    if (httpError.error.notifications) {
      httpError.error.notifications.forEach((notification: NotificationModel, index: number) => {
        this.questionManagerComponent.editErrors.set(`Error ${index + 1}:`, notification.text);
      });
    }
    this.questionManagerComponent.openAlertError();
    return throwError(() => httpError);
  }

  saveAnswerOptions(savedQuestion: Question, question: Question) {
    const answerOptions = this.getAnswerOptionsBasedOnQuestionType(question.questionType);
    if (this.addQuestionForm.get('questionType')?.value === QuestionType.DESCRIPTIVE) {
      this.questionManagerComponent.loadQuestions();
      this.onSuccess();
    } else {
      answerOptions.forEach(answerOption => answerOption.questionId = savedQuestion.id)
      this.answerOptionService.saveAnswerOptions({'answers': answerOptions}).subscribe(
        () => {
          this.questionManagerComponent.loadQuestions();
          this.onSuccess();
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getAnswerOptionsBasedOnQuestionType(questionType: string): AnswerOption[] {
    switch (questionType) {
      case QuestionType.SINGLE_SELECT:
        return this.getAnswerOptions(this.answers, 'isSingleCorrect', false);
      case QuestionType.MULTI_SELECT:
        return this.getAnswerOptions(this.answers, 'isMultiCorrect', false);
      case QuestionType.TRUE_OR_FALSE:
        return this.getTrueOrFalseOptions();
      case QuestionType.SHORT_ANSWER:
        return this.getAnswerOptions(this.shortAnswers, '', true);
      default:
        return [];
    }
  }

  private getAnswerOptions(formArray: FormArray, isCorrect: string, isShortAnswer: boolean = false): AnswerOption[] {
    var filteredByVariantNameNotBlank: FormGroup[] = this.filterByVariantNameNotBlank(formArray);
    return filteredByVariantNameNotBlank.map(answerGroup => (
      this.getAnswerOption(answerGroup as FormGroup, 'variantName', isCorrect, isShortAnswer))
    );
  }

  private getTrueOrFalseOptions(): AnswerOption[] {
    const options: AnswerOption[] = [];
    options.push(this.getAnswerOption(this.trueOrFalseAnswers, 'positiveOptionVariantName', 'isPositiveOptionCorrect'));
    options.push(this.getAnswerOption(this.trueOrFalseAnswers, 'negativeOptionVariantName', 'isNegativeOptionCorrect'));
    return options;
  }

  private getAnswerOption(formGroup: FormGroup, variantName: string, isCorrect: string, isShortAnswer: boolean = false): AnswerOption {
    return {
      variantName: formGroup.get(variantName)?.value.trim(),
      isCorrectAnswer: isShortAnswer || formGroup.get(isCorrect)?.value != "",
    }
  }

  public initializeForm(questionType: QuestionType = QuestionType.SINGLE_SELECT, ask: string = '', score: string = '1'): void {
    this.addQuestionForm = new FormGroup({
      ask: new FormControl(ask, [Validators.required]),
      questionType: new FormControl(questionType, Validators.required),
      answers: new FormArray([]),
      shortAnswers: new FormArray([]),
      trueOrFalseAnswers:
        new FormGroup({
          positiveOptionVariantName: new FormControl('True'),
          isPositiveOptionCorrect: new FormControl(false),
          negativeOptionVariantName: new FormControl('False'),
          isNegativeOptionCorrect: new FormControl(false)
        }),
      score: new FormControl(score, [Validators.required, Validators.pattern(/^\d+$/)]),
      isCapitalized: new FormControl(false)
    });
    for (let i = 0; i <= 2; i++) {
      this.addAnswer();
    }
  }

  onSuccess(): void {
    this.editQuestion = null;
    this.questionService.totalCount(this.quizId).subscribe(
      (count) => {
        this.serialNumber = count + 1;
      },
      (error) => {
        console.error("Error fetching questions count:", error);
      });
    this.initializeForm();
    this.loading = false;
  }

  uploadImageForEditor(file: File): Observable<HttpEvent<UploadResponse>> {
    return from(new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 300;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            height *= maxSize / width;
            width = maxSize;
          } else {
            width *= maxSize / height;
            height = maxSize;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);
          const resizedImageUrl = canvas.toDataURL(file.type);
          resolve(resizedImageUrl);
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })).pipe(
      map(resizedImageUrl => new HttpResponse({body: {imageUrl: resizedImageUrl}}))
    );
  }

  initializeEditQuestionForm() {
    if (this.editQuestion) {
      const editQuestion = this.editQuestion;
      this.serialNumber = editQuestion.serialNumber;

      this.addQuestionForm = new FormGroup({
        ask: new FormControl(editQuestion.ask || '', [Validators.required]),
        questionType: new FormControl(editQuestion.questionType, Validators.required),
        answers: new FormArray([]),
        shortAnswers: new FormArray([]),
        trueOrFalseAnswers: new FormGroup({}),
        score: new FormControl(editQuestion.score || '', [Validators.required, Validators.pattern(/^\d+$/)]),
        isCapitalized: new FormControl(false)
      });

      const answerOptions = editQuestion.answerOptions;
      switch (editQuestion.questionType) {
        case QuestionType.SINGLE_SELECT:
          this.addSingleSelectAnswersForEdit(answerOptions);
          break;
        case QuestionType.MULTI_SELECT:
          this.addMultiSelectAnswersForEdit(answerOptions);
          break;
        case QuestionType.SHORT_ANSWER:
          this.addShortAnswerOptionsForEdit(answerOptions);
          break;
        case QuestionType.TRUE_OR_FALSE:
          this.addTrueOrFalseAnswersForEdit(editQuestion);
          break;
      }

      this.loading = false;
    }
  }

  addSingleSelectAnswersForEdit(answerOptions: AnswerOption[]) {
    answerOptions.forEach((answerOption: AnswerOption) => {
      this.addAnswer(answerOption.variantName, answerOption.isCorrectAnswer, false);
    });
  }

  addMultiSelectAnswersForEdit(answerOptions: AnswerOption[]) {
    answerOptions.forEach((answerOption: AnswerOption) => {
      this.addAnswer(answerOption.variantName, false, answerOption.isCorrectAnswer);
    });
  }

  addShortAnswerOptionsForEdit(answerOptions: AnswerOption[]) {
    answerOptions.forEach((answerOption: AnswerOption) => {
      this.addShortAnswer(answerOption.variantName);
    });
  }

  addTrueOrFalseAnswersForEdit(editQuestion: Question) {
    this.addQuestionForm.controls['trueOrFalseAnswers'] = new FormGroup({
      positiveOptionVariantName: new FormControl(editQuestion.answerOptions[0].variantName),
      isPositiveOptionCorrect: new FormControl(editQuestion.answerOptions[0].isCorrectAnswer),
      negativeOptionVariantName: new FormControl(editQuestion.answerOptions[1].variantName),
      isNegativeOptionCorrect: new FormControl(editQuestion.answerOptions[1].isCorrectAnswer)
    });
  }

  isIdenticalAnswersExists(): boolean {
    const answerValues = this.answers.controls
      .map(control => control.get('variantName')?.value.toLowerCase())
      .filter(value => value.trim() !== '');
    const uniqueValues = new Set(answerValues);

    return uniqueValues.size !== answerValues.length;
  }

  onToggleChange(event: Event, controlName: string) {
    this.isChecked = (<HTMLInputElement> event.target).checked;
    this.addQuestionForm.get(controlName)?.setValue(this.isChecked);
  }
}
