import { Injectable } from '@angular/core';
import {QuizStatus} from "../models/quizStatus";

@Injectable({
  providedIn: 'root'
})
export class QuizStatusCheckService {

  constructor() { }

  public isReadOnly(status: QuizStatus | undefined): boolean {
    return status == QuizStatus.ACTIVE || status == QuizStatus.ENDED;
  }
}
