import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {QuizService} from "../../services/quiz.service";
import {FormGroup} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomSnackbarComponent} from "../other-settings/custom-snackbar/custom-snackbar.component";
import {SharedService} from "../../services/shered.service";

@Component({
  selector: 'app-duplicate-quiz-dialog',
  templateUrl: './quiz-dialog.component.html',
  styleUrls: ['./quiz-dialog.component.css']
})
export class QuizDialogComponent {
  duplicateName: string;
  duplicateQuizId: string;
  duplicateUserId: string;
  duplicateForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<QuizDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private quizService: QuizService,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) {
    this.duplicateName = this.data.quizName + '-copy';
    this.duplicateUserId = this.data.userId;
    this.duplicateQuizId = this.data.quizId;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  duplicate() {
    if (!this.duplicateName.trim()) {
      return;
    }
    this.quizService.duplicateQuiz(this.duplicateQuizId, this.duplicateName, this.duplicateUserId).subscribe(
      (response) => {
        this._snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 4000,
          horizontalPosition: 'start',
          verticalPosition: 'bottom',
          panelClass: 'mat-toolbar',
        })
        this.sharedService.needToUpdate();
      },
    );
  }

  deleteQuiz() {
    this.quizService.deleteQuiz(this.duplicateQuizId).subscribe(() => {
        this.sharedService.needToUpdate();
      },
    );
  }
}
