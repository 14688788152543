import {Component, ElementRef, ViewChild,} from '@angular/core';
import {Question} from "../../models/Question";
import {QuestionService} from "../../services/question.service";
import {AnswerOption} from "../../models/AnswerOption";
import {forkJoin, Observable} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {QuestionType} from "../../models/QuestionType";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AngularEditorConfigsUtil} from "../../util/angular-editor-configs.util";
import {AddQuestionComponent} from "./add-question/add-question.component";
import {Quiz} from "../../models/quiz";
import {QuizStatus} from "../../models/quizStatus";
import {ActivatedRoute} from "@angular/router";
import {QuizService} from "../../services/quiz.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomSnackbarErrorsComponent} from "./custom-snackbar-errors/custom-snackbar-errors.component";
import {GeneralRequestModel} from "../../models/generalRequestModel";

@Component({
  selector: 'app-question-manager',
  templateUrl: './question-manager.component.html',
  styleUrls: ['./question-manager.component.css']
})
export class QuestionManagerComponent {

  quiz: Quiz | undefined;
  paginationModel: GeneralRequestModel;
  questions: Question[] = [];
  totalQuestions: number = 0;
  totalPages: number = 0;
  totalPagesArray: number[] = [];
  question: Question | undefined;
  questionIds: string[] = [];
  isCheckedList: boolean[] = [];
  selectedQuestions: Set<number> = new Set<number>();
  isChecked: boolean = false;
  @ViewChild('closebutton') closeButton!: ElementRef;
  @ViewChild('closeButtonQuestions') closeButtonQuestions!: ElementRef;
  @ViewChild('childAddQuestionComponentRef') addQuestionComponent!: AddQuestionComponent;
  protected readonly QuestionType = QuestionType;
  sectionStates: boolean[] = [];
  editErrors = new Map<string, string>();

  constructor(private _snackBar: MatSnackBar,
              private quizService: QuizService, private sanitizer: DomSanitizer, private questionService: QuestionService, private route: ActivatedRoute) {
    this.paginationModel = {page: 1, count: 10};
    this.route.queryParams.subscribe(queryParams => {
      this.sectionStates = new Array(this.questions.length).fill(false);
      const quizId = queryParams['id'];
      if (quizId) {
        this.quizService.getQuizById(quizId).subscribe(quiz => {
          this.quiz = quiz
          this.loadTotalCount();
          this.loadQuestions();
        })
      }
    })
  }

  loadQuestions(): void {
    this.questionService.findAllByQuizId(this.quiz?.id, this.paginationModel).subscribe(questionsDataModel => {
      this.questions = questionsDataModel.data;
      this.isCheckedList = new Array(this.questions.length).fill(false);
      this.isChecked = false;
      this.loadTotalCount();
      this.questionIds = [];
      const answerObservables = this.questions.map(question => this.loadAnswersForQuestion(question.id));
      forkJoin(answerObservables).subscribe(answersArray => {
        this.questions.forEach((question, index) => {
          question.answerOptions = answersArray[index];
        });
      });
    });
  }

  loadAnswersForQuestion(questionId: string): Observable<AnswerOption[]> {
    return this.questionService.findAnswersByQuestionId(questionId);
  }

  loadTotalCount(): void {
    this.questionService.totalCount(this.quiz?.id).subscribe(totalCount => {
      this.totalQuestions = totalCount;
      this.calculateTotalPages();
    });
  }

  dropped(e: DragEvent): void {
    this.cancelDefault(e);
    const oldIndex = parseInt(e.dataTransfer?.getData('text/plain') || '', 10);
    const newIndex = this.getIndex(e.target);
    this.updateQuestionSerialNumber(this.questions[oldIndex].id, this.questions[newIndex].serialNumber);
  }

  updateQuestionSerialNumber(id: string, serialNumber: number) {
    if (this.quiz?.status == QuizStatus.SETUP_IN_PROGRESS) {
      this.questionService.updateQuestionSerialNumber(id, serialNumber).subscribe(() => {
        this.loadQuestions();
      });
    }
  }

  getIndex(target: EventTarget | null): number {
    const parentElement = (target as HTMLElement).closest('li');
    const id = parentElement?.id;
    return this.questions.findIndex(question => question.id === id);
  }

  dragStart(e: DragEvent): void {
    const index = this.getIndex(e.target);
    e.dataTransfer?.setData('text/plain', index.toString());
  }

  cancelDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalQuestions / this.paginationModel.count);
    this.totalPagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
  }

  getQuestions(page: number): void {
    this.calculateTotalPages()
    if (page >= 1 && page <= this.totalPages) {
      this.paginationModel.page = page;
      if (this.quiz?.id != null) {
        this.loadQuestions();
      }
    }
  }

  anyQuestionSelected(): boolean {
    return this.isCheckedList.some(isChecked => isChecked);
  }

  selectedQuestionCount(): number {
    return this.isCheckedList.filter(isChecked => isChecked).length;
  }

  toggleCheckboxForAll() {
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.questionIds = [];
      this.questions.forEach((question, i) => {
        this.questionIds[i] = question.id;
      });
      this.isCheckedList = new Array(this.questions.length).fill(true);
    } else {
      this.questionIds = [];
      this.isCheckedList = new Array(this.questions.length).fill(false);
    }
  }

  deleteSelectedQuestions() {
    this.onDeleteQuestions(this.questionIds);
    this.selectedQuestions.clear();
    this.isChecked = !this.isChecked;
    this.closeButtonQuestions.nativeElement.click();
  }

  toggleCheckmark(index: number, questionId: string): void {
    if (!this.isCheckedList[index]) {
      this.isCheckedList[index] = true;
      this.questionIds.push(questionId);
      if (this.isCheckedList.every(Boolean)) {
        this.isChecked = true;
      }
    } else {
      this.isCheckedList[index] = !this.isCheckedList[index];
      this.isChecked = false;
      const idIndex = this.questionIds.indexOf(questionId);
      if (idIndex !== -1) {
        this.questionIds.splice(idIndex, 1);
      }
    }

  }

  deleteQuestion() {
    this.onDeleteQuestions(this.questionIds)
    this.closeButton.nativeElement.click();
  }

  onDeleteQuestions = (questionIds: string[]): void => {
    this.questionService.deleteQuestions(questionIds).subscribe(
      () => {
        this.questionIds = [];
        this.loadQuestions();
      },
    );
  };

  getQuestionType(enumType: string): string {
    switch (enumType) {
      case QuestionType.SINGLE_SELECT:
        return 'Single choice';
      case QuestionType.MULTI_SELECT:
        return 'Multi choice';
      case QuestionType.TRUE_OR_FALSE:
        return 'True or False';
      case QuestionType.SHORT_ANSWER:
        return 'Short answer';
      case QuestionType.DESCRIPTIVE:
        return 'Descriptive';
      default:
        return 'Unknown type';
    }
  }

  getSanitizedHTML(htmlContent: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  configForDescriptiveEditor: AngularEditorConfig = {
    editable: false,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  };

  isReadOnly(): boolean {
    return this.quiz?.status == QuizStatus.ACTIVE || this.quiz?.status == QuizStatus.ENDED;
  }

  onEditButtonClick(question: Question) {
    this.addQuestionComponent.editQuestion = question;
    this.addQuestionComponent.initializeEditQuestionForm();
  }

  toggleSection(index: number) {
    this.sectionStates[index] = !this.sectionStates[index];
  }

  openAlertError() {
    this._snackBar.openFromComponent(CustomSnackbarErrorsComponent, {
      duration: 4000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: 'mat-toolbar',
      data: this.editErrors
    });
  }

  toggleQuestion(index: number) {
    this.questionIds = [];
    this.questionIds = [this.questions[index].id];
  }

  deleteQuestionIds() {
    this.questionIds = [];
  }
}
