import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {Admin} from "../models/admin";
import {catchError, Observable, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {EMAIL, EMAIL_LABEL, PASSWORD, PASSWORD_LABEL, USERNAME_LABEL} from "../constants/text-constants";
import {EMAIL_ALREADY_EXISTS, SUCCESS_VERIFY_LINK} from "../constants/err-constants";

function customEmailFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;
    if (value) {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      const valid = emailRegex.test(value);

      if (!valid) {
        return {invalidEmailFormat: true};
      }
    }
    return null;
  };
}

function customPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)\S{8,}$/;
    const valid = passwordRegex.test(value);
    return valid ? null : {invalidPassword: true};
  };
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  protected readonly USERNAME_LABEL = USERNAME_LABEL;
  protected readonly EMAIL_LABEL = EMAIL_LABEL;
  protected readonly PASSWORD_LABEL = PASSWORD_LABEL;
  protected readonly SUCCESS_VERIFY_LINK = SUCCESS_VERIFY_LINK;
  protected readonly EMAIL_ALREADY_EXISTS = EMAIL_ALREADY_EXISTS;
  form!: FormGroup
  message = '';
  passwordFieldType: string = 'password';
  isEmailBlurred = false;
  isPasswordBlurred = false;
  isGoogleLoginError: boolean = false;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, customEmailFormatValidator()]),
      password: new FormControl('', [Validators.required, customPasswordValidator()])
    });
  }

  ngOnInit() {
    this.codeReceivingFromQueryParam()
  }

  private codeReceivingFromQueryParam() {
    this.route.queryParams.subscribe(queryParams => {
      const code = queryParams['code'];
      if (code) {
        this.oAuthTokenRequestByCode(code);
      }
      this.removingQueryParamAfterReceiving();
    })
  }

  private oAuthTokenRequestByCode(code: any) {
    this.authService.getOAuthTokenByCode(code).subscribe({
      next: oAuthToken => {
        this.getAdminToken(oAuthToken);
      },
      error: err => {
        if (err.status === 401) {
          this.isGoogleLoginError = true;
        }
      }
    })
  }

  private getAdminToken(oAuthToken: string) {
    this.authService.getAdminTokenByOAuthToken(oAuthToken).subscribe({
      next: adminToken => {
        this.authService.setToken(adminToken);
        this.router.navigate(["/dashboard"]);
      }
    })
  }

  private removingQueryParamAfterReceiving() {
    this.router.navigate([], {
      queryParams: {},
      replaceUrl: true,
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    } else {
      const admin = this.form.getRawValue() as Admin;
      admin.password = admin.password.trim();
      admin.email = admin.email.toLowerCase().trim();
      this.authService.registration(admin).pipe(
        catchError((httpError: HttpErrorResponse): Observable<any> => {
          if (httpError.status === 409 && httpError.error.notifications[0].text) {
            this.message = EMAIL_ALREADY_EXISTS;
          }
          if (httpError.status === 400 && httpError.error.notifications[0].text) {
            this.message = httpError.error.notifications[0].text;
          }
          return throwError(() => httpError);
        })
      ).subscribe(res => {
        this.form.reset();
        this.message = SUCCESS_VERIFY_LINK;
      });
      setTimeout(() => {
        this.message = "";
      }, 10000);
    }
  }

  togglePasswordVisibility(): void {
    this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
  }

  preventWhitespace(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  get hasUppercase() {
    return this.form.get('password')?.value?.match(/[A-Z]/);
  }

  get hasNumber() {
    return this.form.get('password')?.value?.match(/\d/);
  }

  get isLongEnough() {
    return this.form.get('password')?.value?.length >= 8;
  }

  onFocus(field: string) {
    if (field === PASSWORD) {
      this.isPasswordBlurred = false;
    } else if (field === EMAIL) {
      this.isEmailBlurred = false;
    }
  }

  onBlur(field: string) {
    if (field === PASSWORD) {
      this.isPasswordBlurred = true;
    } else if (field === EMAIL) {
      this.isEmailBlurred = true;
    }
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle().subscribe({
      next: result => {
        window.location.href = result;
      },
    });
  }

  protected readonly EMAIL = EMAIL;
  protected readonly PASSWORD = PASSWORD;
}
