<main>
  <section class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <!-- Card -->
        <div class="card shadow">
          <!-- Card body -->
          <div class="card-body p-6">
            <div class="mb-4">
              <a href="../index.html"><img src="assets/images/brand/logo/logo-icon.svg" class="mb-4" alt="logo"></a>
              <div *ngIf="message" class="alert mt-4"
                   [ngClass]="{
       'alert-success': message === 'A verification link has been successfully sent to your email address.',
       'alert-danger': message === 'This email address already exists' || message === 'Password should not contain spaces'
     }">
                {{ message }}
              </div>

              <h1 class="mb-1 fw-bold">Sign up</h1>
              <span>Already have an account?
								<a href="/" class="ms-1">Sign in</a></span>
            </div>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="mb-3">
                <label for="username" class="form-label">User Name</label>
                <input formControlName="username" type="text" id="username" class="form-control"
                       placeholder="User Name" autocomplete="new-username">
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input formControlName="email" type="email" id="email" class="form-control"
                       placeholder="Email address here" autocomplete="new-email">
                <div class="validation"
                     *ngIf="form.get('email')!.hasError('required') && (form.get('email')!.touched || form.get('email')?.dirty)">
                  Email address is required.
                </div>
                <div class="validation"
                     *ngIf="form.get('email')!.hasError('invalidEmailFormat') && (form.get('email')!.touched || form.get('email')?.dirty)">
                  Please enter a valid email address
                </div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <div class="position-relative">
                  <input formControlName="password"
                         [type]="passwordFieldType"
                         id="password"
                         class="form-control"
                         placeholder="Enter your password"
                         (keydown)="preventWhitespace($event)" autocomplete="new-password">
                  <span class="password-toggle-icon">
                  <i [class]="passwordFieldType === 'password' ?  'far fa-eye-slash' : 'far fa-eye'"
                     (click)="togglePasswordVisibility()"></i>
                </span>
                </div>
                <div class="validation"
                     *ngIf="form.get('password')!.invalid && (form.get('password')!.touched || form.get('password')!.dirty)">
                  Password will have min 8 characters, 1 uppercase, 1 number
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button [disabled]="this.form.invalid" type="submit" class="btn btn-primary">
                    Create Account
                  </button>
                </div>
              </div>
              <hr class="my-4">
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

