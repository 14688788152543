import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private sanitizer: DomSanitizer) {}

  getSafeUrl(imagePath: string | undefined): SafeUrl | null {
    if (imagePath) {
      return this.sanitizer.bypassSecurityTrustUrl(imagePath);
    }
    return null;
  }
}
