<div *ngIf="!isLoading">
  <div mat-dialog-content class="overflow-auto">
    <form [formGroup]="quizGenerationForm">
      <div class="mb-3">
        <label for="name" class="form-label">Quiz name<span class="text-danger">*</span></label>
        <input id="name" class="form-control" formControlName="quizName" type="text" placeholder="Test Name">
      </div>

      <div class="mb-3">
        <label class="form-label">Language<span class="text-danger">*</span></label>
        <select class="form-select" formControlName="language">
          <option value="">Select Language</option>
          <option *ngFor="let language of languageEnum | keyvalue" [value]="language.key">{{ language.value | titlecase }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label class="form-label">Quiz category<span class="text-danger">*</span></label>
        <div class="d-flex">
          <select class="form-select w-65" formControlName="category">
            <option value="">Select category</option>
            <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
          </select>
          <a class="btn btn-primary d-inline ms-3 w-40" (click)="openCategoryDialog()">Add New Category</a>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label mb-3">Description of the Quiz<span class="text-danger">*</span></label>
        <angular-editor [config]="config" placeholder="Add your Content here" formControlName="description"></angular-editor>
      </div>

      <div class="mb-3">
        <label class="form-label">Difficulty Level<span class="text-danger">*</span></label>
        <div class="d-flex">
          <select class="form-select" formControlName="difficultyLevel">
            <option value="">Select Difficulty</option>
            <option *ngFor="let difficultyLevel of difficultyLevels" [value]="difficultyLevel">{{ difficultyLevel | titlecase }}</option>
          </select>
        </div>
      </div>

      <div>
        <label class="form-label">Total Number of Questions<span class="text-danger">*</span></label>
        <input required type="number" formControlName="totalQuestions" class="roww form-control mb-0" id="score">
        <div class="validation" *ngIf="(quizGenerationForm.get('totalQuestions')?.touched || quizGenerationForm.get('totalQuestions')?.dirty) && quizGenerationForm.get('totalQuestions')?.invalid">
          Provide value between 1 and 15
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end p-4">
    <button class="btn btn-primary d-inline ms-3" (click)="close()">Cancel</button>
    <button class="btn btn-primary d-inline ms-3" [disabled]="quizGenerationForm.invalid" (click)="generateQuiz()">Generate Quiz</button>
  </div>
</div>

<app-spinner [isLoading]="isLoading"></app-spinner>

<div *ngIf="isGenerationFailed" class="mat-elevation-z4 generation-fail-card">
  <mat-icon color="warn" class="mx-3">error</mat-icon>
  <span>Generation Failed. Please try again later.</span>
</div>

