<main>
  <div class="main-background position-absolute bg-cover bg-center"></div>
  <section class="container d-flex flex-column mb-105">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
        <div class="mt-100 text-center"><span class="it-quiz-text f-col fw-bold fs-lh-itquiz">
          IT&nbsp;<span class="font-outfit col-spec fw-bold fs-lh-itquiz">Q</span>uiz</span></div>
        <div class="card shadow custom-size br-10 position-relative">
          <div class="card-body p-6">
            <div class="mb-4">
              <div *ngIf="message === SUCCESS_VERIFY_LINK">
                <div class="media-screen-w verify-link w-48 h-48 d-flex br-10 position-relative">
                  <img src="../../assets/images/tick/rounded-tick.png" alt="rounded-tick" class="rounded-tick position-absolute">
                  <span class="tick-span f-col">{{ message }}</span>
                </div>
              </div>
              <div *ngIf="message === EMAIL_ALREADY_EXISTS">
                <div class="media-screen-w invalidity-link w-48 h-48 d-flex br-10 position-relative">
                  <img src="/assets/images/error/cancel.png" alt="cancel" class="rounded-tick position-absolute">
                  <span class="tick-span f-col">{{ message }}</span>
                </div>
              </div>
              <div class="h-36 w-48 text-align-center">
                <span class="sign-up-text f-col fw-bold">Sign Up</span><br>
                <span class="col-grey f-col fs-lh text-center fw-normal">Already have an account?
                    <a href="/signin" class="ms-1">Sign In</a></span>
              </div>
            </div>
            <br>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="mb-3">
                <label for="username" class="label-focused form-label ls-01 f-col fs-lh fw-normal">
                    {{ USERNAME_LABEL }}
                </label>
                <div class="br-1 border-focused">
                <input formControlName="username" type="text" id="username"
                       class="media-screen-w form-control-div w-48 h-48 border-0 font-outfit g-1 br-10"
                       placeholder="User name" autocomplete="new-username">
                </div>
              </div>
              <div class="mb-3">
                <label for="email"
                       class="label-focused form-label ls-01 f-col fs-lh fw-normal">
                  <span [ngClass]="{
                  'validation': isEmailBlurred && (form.get('email')!.invalid && form.get('email')!.touched)}">
                    {{ EMAIL_LABEL }}</span>
                </label>
                <div class="br-1 border-focused"
                     [ngClass]="{
                  'border-validation': isEmailBlurred && (form.get('email')!.invalid && form.get('email')!.touched)}">
                <input formControlName="email" type="email" id="email"
                       class="media-screen-w form-control-div w-48 h-48 border-0 font-outfit g-1 br-10"
                       placeholder="Email address" autocomplete="new-email"
                       (focus)="onFocus(EMAIL)"
                       (blur)="onBlur(EMAIL)">
                </div>
                <div class="validation err-msg-p font-outfit d-flex fw-normal g-1 ls-01 msg-fs-lh"
                     *ngIf="isEmailBlurred && (form.get('email')!.hasError('required') && form.get('email')!.touched)">
                  Email address is Required
                </div>
                <div class="validation err-msg-p font-outfit d-flex fw-normal g-1 ls-01 msg-fs-lh"
                     *ngIf="isEmailBlurred && (form.get('email')!.hasError('invalidEmailFormat') && form.get('email')!.touched)">
                  Please enter a valid email address
                </div>
              </div>
              <div class="mb-3">
                <label for="password"
                       class="form-label ls-01 f-col fs-lh fw-normal label-focused">
                  <span [ngClass]="{
                  'validation': isPasswordBlurred && (form.get('password')!.invalid && form.get('password')!.touched)}">
                    {{ PASSWORD_LABEL }}</span>
                </label>
                <div class="position-relative">
                  <div class="br-1 border-focused"
                       [ngClass]="{
                        'border-validation': isPasswordBlurred && (form.get('password')!.invalid && form.get('password')!.touched)}">
                  <input formControlName="password"
                         [type]="passwordFieldType"
                         id="password"
                         class="media-screen-w form-control-div w-48 h-48 border-0 font-outfit g-1 br-10"
                         placeholder="Enter your password"
                         (keydown)="preventWhitespace($event)" autocomplete="new-password"
                         (focus)="onFocus(PASSWORD)"
                         (blur)="onBlur(PASSWORD)">
                  <span class="password-toggle-icon position-absolute">
                    <i [class]="passwordFieldType === PASSWORD ?  'far fa-eye-slash' : 'far fa-eye'"
                      (click)="togglePasswordVisibility()" class="password-icon-style"></i>
                  </span>
                  </div>
                </div>
                <div class="ul-li-div w-48"
                     *ngIf="!isPasswordBlurred || (isPasswordBlurred && !(form.get('password')!.invalid && form.get('password')!.touched))">
                  <ul class="d-flex list-unstyled p-0 m-0 g-1">
                    <li [ngClass]="{ 'valid': hasUppercase }">
                      <span *ngIf="hasUppercase">
                        <img src="../../assets/images/tick/tick.png" alt="tick" class="tick">
                      </span>
                      <span *ngIf="!hasUppercase">&#8226;</span>
                      <span class="three-msg msg-fs-lh ls-01 fw-normal">At least 1 uppercase</span>
                    </li>
                    <li [ngClass]="{ 'valid': hasNumber }">
                      <span *ngIf="hasNumber">
                        <img src="../../assets/images/tick/tick.png" alt="tick" class="tick">
                      </span>
                      <span *ngIf="!hasNumber">&#8226;</span>
                      <span class="three-msg msg-fs-lh ls-01 fw-normal">At least 1 number</span>
                    </li>
                    <li [ngClass]="{ 'valid': isLongEnough }">
                      <span *ngIf="isLongEnough">
                        <img src="../../assets/images/tick/tick.png" alt="tick" class="tick">
                      </span>
                      <span *ngIf="!isLongEnough">&#8226;</span>
                      <span class="three-msg msg-fs-lh ls-01 fw-normal">At least 8 characters</span>
                    </li>
                  </ul>
                </div>
                <div *ngIf="isPasswordBlurred && form.get('password')!.invalid && form.get('password')!.touched">
                  <div class="media-screen-err validation err-msg-p w-452 g-1 fw-normal ls-01 font-outfit msg-fs-lh">
                    <span>Your&nbsp;password&nbsp;is&nbsp;wrong.&nbsp;Remember&nbsp;that&nbsp;it&nbsp;must&nbsp;contain&nbsp;at&nbsp;least&nbsp;<b>8&nbsp;characters,&nbsp;1&nbsp;uppercase</b>&nbsp;and&nbsp;<b>1&nbsp;number</b></span>
                  </div>
                </div>
              </div>
              <div class="mt-40">
                  <button type="submit" class="media-screen-w bgc-p g-1 btn-br-p w-48 h-48 border-0 d-flex align-items-center justify-content-center box-sizing-border-box"
                          [disabled]="form.invalid">
                    <span class="sign-btn font-outfit col-primary text-center fw-medium">
                      Create Account
                    </span>
                  </button>
              </div>
              <div class="mt-40 align-items-center d-flex">
                <hr class="hr-line">
                <span class="f-col">or</span>
                <hr class="hr-line">
              </div>
              <div class="mt-40">
                <button (click)="loginWithGoogle()" class="media-screen-w col-w f-col g-1 btn-br-p w-48 h-48 d-flex align-items-center justify-content-center box-sizing-border-box b-1">
                  <img src="/assets/images/brand/google-icon.png" alt="google-icon" class="google-icon-size">
                    <span class="sign-btn f-col text-center fw-medium">
                      Continue with Google
                    </span>
                </button>
              </div>
              <p *ngIf="isGoogleLoginError" class="text-danger text-center">Google login failed. Please try again.</p>
            </form>
          </div>
        </div>
    </div>
  </section>
</main>
