import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {RespondentModel} from "../../models/respondentModel";
import {RespondentService} from "../../services/respondent.service";
import {QuizService} from "../../services/quiz.service";
import {Quiz} from "../../models/quiz";
import {QuizStatus} from "../../models/quizStatus";
import {ResultModel} from "../../models/resultModel";
import {switchMap, tap} from "rxjs";
import {RespondentResponse} from "../../models/RespondentResponse";
import {HttpErrorResponse} from "@angular/common/http";
import {SafeUrl} from "@angular/platform-browser";
import {ImageService} from "../../services/image.service";

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css']
})
export class StartPageComponent implements OnInit {
  startPageForm!: FormGroup;
  quizId!: string;
  quiz?: Quiz;
  isQuizEnded: boolean = false;
  protected readonly QuizStatus = QuizStatus;
  resultSavedErrorMessage?: string;

  constructor(private route: ActivatedRoute,
              private respondentService: RespondentService,
              private formBuilder: FormBuilder, private quizService: QuizService,
              private router: Router,
              private imageService: ImageService) {
    this.setupForm()
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(switchMap(queryParams => {
      this.quizId = queryParams['id'];
      this.setupForm();
      return this.getQuizById()
    })).subscribe();
  }

  setupForm() {
    this.startPageForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      emailAddress: new FormControl('', [Validators.email, Validators.required]),
      phoneNumber: new FormControl()
    });
  }

  submit() {
    const startQuiz = this.startPageForm.getRawValue() as RespondentModel;
    this.getQuizById().subscribe(quiz => {
      this.quiz = quiz;
      if (quiz.status === QuizStatus.ENDED)
        this.isQuizEnded = true;
      if (this.startPageForm.valid && this.quiz?.status === QuizStatus.ACTIVE) {
        this.respondentService.saveRespondentAndStartQuiz(startQuiz, this.quizId)
          .pipe(
            tap((respondent: RespondentResponse) => {
              if (respondent.notifications && this.isRespondentEnded(respondent)) {
                respondent.id = this.getRespondentIdFromText(respondent.notifications[0].text);
                this.handleSaveResultAndGet(respondent)
              } else {
                this.handleRespondentIsNotEnded(respondent);
              }
            })
          )
          .subscribe();
      }
    });
  }

  private handleSaveResultAndGet(respondent: RespondentResponse): void {
    if (respondent.id === '') {
      this.resultSavedErrorMessage = "Your email address has already been used to complete the quiz. Please try using a different email address to access the quiz.";
      return;
    }
    this.respondentService.saveResultAndGet(this.quizId, respondent.id)
      .subscribe({
        next: (response: ResultModel) => {
          this.navigateToRespondentResult(respondent, response);
        },
        error: (_: HttpErrorResponse) => {
          this.resultSavedErrorMessage = "Your email address has already been used to complete the quiz. Please try using a different email address to access the quiz.";
          return;
        }
      });
  }

  private handleRespondentIsNotEnded(respondent: RespondentResponse) {
    if (respondent.statusText == "You have successfully UPDATED") {
      this.navigateToQuizRespondent(respondent);
      return;
    }
    this.navigateToExam(respondent);
  }

  private isRespondentEnded(respondent: RespondentResponse) {
    return respondent.notifications && respondent.notifications[0].text.includes("You have already ended this quiz. Respondent ID:")
  }

  private getQueryParams(result: RespondentResponse): any {
    return {
      quizId: this.quizId,
      respondentId: result.id
    };
  }

  private navigateToRespondentResult(result: RespondentResponse, response: ResultModel): void {
    this.router.navigate(['respondent-result'], {
      queryParams: this.getQueryParams(result)
    });
  }

  private navigateToQuizRespondent(result: RespondentResponse): void {
    this.router.navigate(['quiz-respondent'], {
      queryParams: this.getQueryParams(result)
    });
  }

  private navigateToExam(result: RespondentResponse): void {
    this.router.navigate(['exam'], {
      queryParams: this.getQueryParams(result)
    });
  }

  private getQuizById() {
    return this.quizService.getQuizById(this.quizId).pipe(tap(quiz => {
      this.quiz = quiz
    }));
  }

  refreshPage(): void {
    location.reload();
  }

  getRespondentIdFromText(text: string | undefined): string {
    if (text && text.includes("You have already ended this quiz. Respondent ID:")) {
      const idPart = text.split("Respondent ID: ")[1];
      return idPart.split(" ")[0];
    }
    return '';
  }

  getSafeUrl(): SafeUrl | null {
    return this.imageService.getSafeUrl(this.quiz?.logoUpload);
  }
}
