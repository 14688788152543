<div id="db-wrapper" [ngClass]="{ 'toggled' : isSidebarMenuToggled}">
  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <main id="page-content">
    <!-- Header -->
    <app-header (toggleSidebarEvent)="setSidebarMenuToggled($event)"></app-header>

    <div class="wrapper">
      <!-- Sidebar  -->
      <nav id="sidebar">
        <ul class="list-unstyled components">
          <li>
            <a (click)="toggleTestConfigurationDropdown()" data-toggle="collapse"
               class="dropdown-toggle cursor-pointer">Test
              Configuration</a>
            <ul *ngIf="isTestConfigurationDropdownOpen" class=" list-unstyled">
              <li>
                <div class="step">
                  <a routerLinkActive="active" [routerLink]="['/quiz/add']" [queryParams]="{ id: quizId }"
                     type="button" class="step-trigger"
                     role="tab" id="basicSettings">
                    <span class="bs-stepper-label">Basic settings</span>
                  </a>
                </div>
              </li>
              <li>
                <div class="step">
                  <a routerLinkActive="active" [routerLink]="['/quiz/question/manager']" [queryParams]="{ id: quizId }"
                     type="button" class="step-trigger"
                     role="tab" id="questionManager"
                     [class.disabled]="isButtonDisabled()">
                    <span class="bs-stepper-label">Question Manager</span>
                  </a>
                </div>
              </li>
              <li>
                <div class="step">
                  <a routerLinkActive="active" [routerLink]="['/quiz/other/settings']" [queryParams]="{ id: quizId }"
                     type="button" class="step-trigger"
                     role="tab" id="advancedSettings"
                     [class.disabled]="isButtonDisabled()">
                    <span class="bs-stepper-label">Advanced Settings</span>
                  </a>
                </div>
              </li>
              <li class="d-flex justify-content-center mt-3">
                <button type="button" class="btn btn-primary w-85" data-bs-toggle="modal"
                        (click)="onClickActiveButton()"
                        data-bs-target="#change-quiz-status-modal"
                        [class.disabled]="isButtonDisabled()">
                  {{ getQuizStatus() === QuizStatus.SETUP_IN_PROGRESS || getQuizStatus() === QuizStatus.ENDED || isButtonDisabled() ? 'Activate' : 'End' }}
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <div class="bs-stepper-line"></div>
        <ul class="list-unstyled components">
          <li>
            <a (click)="toggleTestProgressAndResultsDropdown()" data-toggle="collapse"
               class="dropdown-toggle cursor-pointer">Test
              progress & results</a>
            <ul *ngIf="isTestProgressAndResultsDropdownOpen" class="list-unstyled">
              <li>
                <div class="step">
                  <a routerLinkActive="active" [routerLink]="['/quiz/respondent/monitoring']" [queryParams]="{ id: quizId }"
                     type="button" class="step-trigger"
                     role="tab" id="respondentMonitoring">
                    <span class="bs-stepper-label">Respondent monitoring</span>
                  </a>
                </div>
              </li>
              <li>
                <div class="step">
                  <a routerLinkActive="active" [routerLink]="['/quiz/results/table']" [queryParams]="{ id: quizId }"
                     type="button" class="step-trigger"
                     role="tab" id="resultsTable">
                    <span class="bs-stepper-label">Results table</span>
                  </a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div class="bs-stepper-line"></div>
      </nav>
      <!-- Page Content  -->
      <div id="content">
        <div class="justify-content-end d-flex button-back-to-my-tests">
          <a routerLink="/dashboard" class="btn btn-white z-1">Back to My Tests</a>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</div>

<div class="modal fade" id="change-quiz-status-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" *ngIf="!hasQuizQuestions">
      <div class="modal-header">
        <h4 class="modal-title mb-0">
          This quiz has no questions.
        </h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Please add questions to the test.
      </div>
    </div>
    <div class="modal-content" *ngIf="hasQuizQuestions">
      <div class="modal-header">
        <h4 class="modal-title mb-0">
          {{ getQuizStatus() === QuizStatus.ACTIVE ? 'End test' : 'Test activation' }}
        </h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        {{
          getQuizStatus() === QuizStatus.ACTIVE ? 'Are you sure you want to end this test?' :
            'Are you sure you want to activate this test? Make sure all settings are correct.'
        }}
        <div class="d-flex justify-content-end">
          <button data-bs-dismiss="modal" aria-label="Close" class="me-2 btn btn-secondary">No</button>
          <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary" (click)="changeQuizStatus()">Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
