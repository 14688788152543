import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {ResultModel} from "../models/resultModel";
import {AuthService} from "../services/auth.service";
import {Router} from "@angular/router";
import {GeneralRequestModel} from "../models/generalRequestModel";
import {MatDialog} from "@angular/material/dialog";
import {ResultDialogComponent} from "../result-dialog/result-dialog.component";
import {ResultService} from "../services/result.service";
import {FormatUtil} from "../util/format.util";
import {DateUtil} from "../util/date.util";
import {QuizCategory} from "../models/quiz-category";
import {QuizService} from "../services/quiz.service";
import {QuizDataModel} from "../models/quizDataModel";
import {ApiResponse} from "../models/ApiResponse";

@Component({
  selector: 'app-results-database',
  templateUrl: './results-database.component.html',
  styleUrls: ['./results-database.component.css']
})
export class ResultsDatabaseComponent implements OnInit {
  protected readonly Math = Math;
  protected readonly FormatUtil = FormatUtil;
  isSidebarMenuToggled: boolean = false;
  currentUserId: string | null;
  resultsTotalCount: number = 0;
  results: ResultModel [] = [];
  selectedResults = new SelectionModel<ResultModel>(true, []);
  paginationModel: GeneralRequestModel;
  searchTerm: string = '';
  searchResults: ResultModel[] = []
  showFilter?: boolean = false
  quizCategories: QuizCategory[] = []
  quizzes: QuizDataModel[] = []
  selectedQuizId!: string
  selectedTestCategoryId!: string
  quizFilterDropdownSize: number = 1;
  quizCategoryDropdownSize: number = 1;
  sortField: string = 'endTime';
  @ViewChild('windowRef') windowRef?: ElementRef;
  @ViewChild('filterIcon') filterIcon?: ElementRef;
  @ViewChild('filterContainer') filterContainer?: ElementRef;


  constructor(private resultService: ResultService,
              private quizService: QuizService,
              private authService: AuthService,
              private router: Router,
              public dialog: MatDialog,) {
    this.paginationModel = {page: 1, count: 10, sortDirection: 'desc'};
    this.currentUserId = this.authService.getCurrentUserId();
    if (!this.currentUserId) {
      this.router.navigate(["/signIn"]);
      return;
    }
  }

  ngOnInit(): void {
    this.resultService.updatedEvent.subscribe(_ => {
      this.paginationModel = {page: 1, count: 10};
      this.handleResultRetrieval();
    })
    this.getResults();
    this.initializeCategories();
    this.initializeQuizzes();
  }

  private getResults() {
    this.resultService.getResultsByAdminId(this.currentUserId, this.paginationModel, this.sortField).subscribe(results => {
      this.initializeProperties(results);
    })
  }

  onSearch(paginationModel: GeneralRequestModel) {
    this.paginationModel = paginationModel;
    if (this.searchTerm.length >= 2) {
      this.resultService.searchResults(this.currentUserId, this.paginationModel, this.searchTerm.trim()
      ).subscribe(results => {
        this.initializeProperties(results);
        this.resetFilter();
      });
    } else if (this.searchTerm === '') {
      this.getResults();
    }
  }

  onFilter(paginationModel: GeneralRequestModel, categoryId: string, quizId: string): void {
    this.paginationModel = paginationModel;
    this.onSelectQuiz(quizId);
    this.onSelectCategory(categoryId);
    if (this.selectedQuizId !== '' || this.selectedTestCategoryId !== '') {
      this.resultService.getResultsByAdminIdQuizIdAndCategoryId(this.currentUserId, this.paginationModel,
        this.selectedQuizId, this.selectedTestCategoryId)
        .subscribe(results => {
          this.initializeProperties(results);
          this.showFilter = false;
          this.searchTerm = '';
        })
    } else {
      this.getResults();
    }
  }

  private initializeQuizzes(): void {
    this.quizService.getAllForSelectFilter(this.currentUserId).subscribe(quizzes => {
      this.quizzes = quizzes;
    })
  }

  private initializeCategories() {
    if (this.currentUserId) {
      this.resultService.getAllCategoriesByAdminId(this.currentUserId)
        .subscribe(response => {
          this.quizCategories = response
        })
    }
  }

  private initializeProperties(results: ApiResponse<ResultModel>) {
    this.results = results.data
    this.searchResults = results.data
    this.results.forEach(item => {
      item.endTime = DateUtil.convertUTCtoLocal(item.endTime);
    });
    this.resultsTotalCount = results.meta.total;
    this.selectedResults.clear();
  }

  onSelectQuiz(quizId: string) {
    if (quizId && quizId !== "Test Name") {
      this.selectedQuizId = quizId;
    } else {
      this.selectedQuizId = '';
    }
  }

  onSelectCategory(categoryId: string) {
    if (categoryId && categoryId !== "Test Category") {
      this.selectedTestCategoryId = categoryId;
    } else {
      this.selectedTestCategoryId = '';
    }
  }

  goToRespondentResult(result: ResultModel) {
    this.router.navigate(['respondent-result'], {
      queryParams: {
        quizId: result.quizId,
        respondentId: result.respondentId,
      }
    })
  }

  isAllSelected() {
    const numSelected = this.selectedResults.selected.length;
    const numRows = this.results.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectedResults.clear();
      return;
    }
    this.selectedResults.select(...this.results);
  }

  checkboxLabel(event: MouseEvent, row: ResultModel): void {
    event.stopPropagation();
    this.selectedResults.toggle(row);
  }

  openDeleteDialog(resultsForDelete: ResultModel[]) {
    this.dialog.open(ResultDialogComponent, {
      width: '35%',
      data: {
        showDeleteDialog: true,
        selectedResultsForDelete: resultsForDelete
      }
    });
  }

  setSidebarMenuToggled(toggled: boolean) {
    this.isSidebarMenuToggled = toggled;
  }

  toggleFilter() {
    this.showFilter = !this.showFilter
  }

  @HostListener('document:click', ['$event'])
  handleClickOutsideForm(event: Event): void {
    if (this.showFilter && !this.windowRef?.nativeElement.contains(event.target)
      && !this.filterIcon?.nativeElement.contains(event.target) &&
      !this.filterContainer?.nativeElement.contains(event.target)) {
      this.showFilter = false
    }
  }

  resetFilter() {
    if (this.selectedQuizId || this.selectedTestCategoryId) {
      this.selectedTestCategoryId = this.selectedQuizId = '';
      this.getResults()
    }
    this.showFilter = false;
  }

  nextPage() {
    const pageMaxPossibleValue = Math.ceil(this.resultsTotalCount / this.paginationModel.count);
    if (this.paginationModel.page < pageMaxPossibleValue) {
      this.paginationModel.page++;
      this.handleResultRetrieval();
    }
  }

  previousPage() {
    if (this.paginationModel.page > 1) {
      this.paginationModel.page--;
      this.handleResultRetrieval();
    }
  }

  goToPageNumber(page: number) {
    if (page > 0 && page <= this.paginationModel.count) {
      this.paginationModel.page = page;
      this.getResults();
    }
  }

  private handleResultRetrieval() {
    if (this.selectedQuizId?.trim() || this.selectedTestCategoryId?.trim()) {
      this.onFilter(this.paginationModel, this.selectedTestCategoryId, this.selectedQuizId);
    } else if (this.searchTerm.trim().length > 2) {
      this.onSearch(this.paginationModel);
    } else {
      this.getResults();
    }
  }

  setSizeForQuizDropdown() {
    this.quizFilterDropdownSize = Math.min(this.paginationModel.count, this.quizzes.length)
  }

  setSizeForCategoryDropdown() {
    this.quizCategoryDropdownSize = Math.min(this.paginationModel.count, this.quizCategories.length)
  }

  sortInAscendingOrDescendingOrder() {
    this.sortField = 'actualScore';
    if (this.paginationModel.sortDirection === 'desc') {
      this.paginationModel.sortDirection = "asc"
    } else {
      this.paginationModel.sortDirection = "desc"
    }
    this.getResults();
  }
}

