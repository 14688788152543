<section class="container-fluid p-4">
  <div class="row ">
    <div class="col-lg-12 col-md-12 col-12">
      <div class="border-bottom pb-3 mb-3 d-lg-flex align-items-center justify-content-between">
        <div class="mb-2 mb-lg-0">
          <h1 class="mb-0 h2 fw-bold">Results Table</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a routerLink="/dashboard">Results Table</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12">
      <div class="card rounded-3">
        <div class="p-4 p-1">
          <a class="item">Results({{ resultsTotalCount }})</a>
          <a *ngIf="selectionResult.selected.length !== 0" class="items"
             data-bs-toggle="modal" data-bs-target="#deleteConfirmations"
             (click)="openDeleteDialog(selectionResult.selected)">
            <i class="fa-regular fa-trash-can"></i>Delete selected</a>
        </div>

        <div class="p-0">
          <div class="tab-content" id="tabContent">
            <!-- Tab -->
            <div class="tab-pane fade show active" id="all-orders" role="tabpanel" aria-labelledby="all-orders-tab">
              <div class="table-responsive">
                <!-- Table -->
                <table class="table mb-0 text-nowrap table-hover table-centered table-with-checkbox condensed-table">
                  <!-- Table Head -->
                  <thead class="table-light">
                  <tr>
                    <th>
                      <div class="form-check" (click)="toggleAllResults()">
                        <input type="checkbox" [checked]="selectionResult.selected.length === resultModel.length"
                               class="form-check-input" id="checkAl">
                        <label class="form-check-label" for="checkAl"></label>
                      </div>
                    </th>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th>EMAIL</th>
                    <th>SCORE
                      <i class="fa-solid fa-sort" (click)="toggleSort()"></i>
                    </th>
                    <th>END DATE</th>
                    <th>TIME SPENT</th>
                    <th>NUMBER OF TAB SWITCHES DETECTED</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let result of resultModel" class="cursor-pointer" (click)="goToRespondentResult(result)">
                    <td (click)="$event.stopPropagation()">
                      <div class="form-check" (click)="checkboxLabel($event,result)">
                        <input type="checkbox" [checked]=" selectionResult.selected.includes(result)"
                               class="form-check-input cursor-pointer" id="orderOne">
                        <label class="form-check-label" for="orderOne"></label>
                      </div>
                    </td>
                    <td [innerHTML]="FormatUtil.nextLineBreaker(result.firstname, 14)">
                    </td>
                    <td [innerHTML]="FormatUtil.nextLineBreaker(result.lastname, 14)">
                    </td>
                    <td [innerHTML]="FormatUtil.nextLineBreaker(result.email, 30)">
                    </td>
                    <td>
                      <span class="badge" [ngClass]="{
                           'text-success bg-light-success': result && result.testPassed && result.testEnded,
                           'text-danger bg-light-danger': result && !result.testPassed && result.testEnded,
                           'bg-secondary': !result.testEnded}">
                                 {{ result.actualPercentage + "%(" + result.actualScore + "/" + result.quizTotalScore + ")" }}
                      </span>
                    </td>
                    <td>
                      <span>{{ result.endTime === undefined ? "-" : result.endTime | date :"dd/MM/yyyy HH:mm" }}</span>
                    </td>
                    <td>
                      <span
                        class="badge text-info bg-light-info">{{ result?.timeSpent ?? 0  | formatTime }}</span>
                    </td>
                    <td>
                      <span>{{ result.tabSwitches }}</span>
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <div class="card-footer" *ngIf="resultsTotalCount >= 11">
          <nav aria-label="Page navigation example">
            <ul class="pagination mb-0">
              <li class="page-item" (click)="goToPreviousPage()" [class.disabled]="totalPages === 1">
                <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-left"></i></a>
              </li>
              <li class="page-item"
                  (click)="goToPageNumber(totalPages)">
                <a class="page-link mx-1 rounded">{{ totalPages }}</a>
              </li>
              <li class="page-item" (click)="goToNextPage()" [class.disabled]="resultModel.length < pageSize">
                <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-right"></i></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>


