import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ResultModel} from "../models/resultModel";
import {ResultService} from "../services/result.service";
import {ResultDialog} from "../models/ResultDialog";

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.css']
})
export class ResultDialogComponent {

  selectedResultsForDelete: ResultModel[] = []

  constructor(public dialogRef: MatDialogRef<ResultDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ResultDialog,
              private resultService: ResultService) {
    this.selectedResultsForDelete = data.selectedResultsForDelete;
  }

  cancel() {
    this.dialogRef.close()
  }

  deleteResults() {
    const selectedRespondentIds = this.selectedResultsForDelete.map(selectedResult => selectedResult.id) as string[];
    this.resultService.deleteTestResults(selectedRespondentIds).subscribe(_ => {
      this.resultService.needToUpdate();
    })
  }
}
