<section class="footer-section">
  <div class="higher-footer w-100 d-flex justify-content-center align-items-center d-inline-block">
    <div class="higher-inner d-flex br-2-black w-1240 br-20 position-relative">
      <div class="access-div d-inline-block">
        <div class="d-inline-block foot-mb-5"><span class="ls-02 fs-20-lh-30 fw-medium f-col">Access</span></div>
        <br>
        <div class="d-inline-block foot-mb-4"><span
          class="contact cursor-pointer col-grey font-outfit fs-16-lh-20 fw-normal">Contact</span></div>
        <br>
        <div class="d-inline-block foot-mb-4"><a routerLink="/signin"><span
          class="sign-in cursor-pointer col-grey font-outfit fs-16-lh-20 fw-normal">Sign&nbsp;In</span></a></div>
        <br>
        <div class="d-inline-block"><a routerLink="/signup"><span
          class="sign-up cursor-pointer col-grey font-outfit fs-16-lh-20 fw-normal">Sign&nbsp;Up&nbsp;Free</span></a>
        </div>
      </div>
      <div class="company-div d-inline-block">
        <div class="d-inline-block foot-mb-5"><span class="ls-02 fs-20-lh-30 fw-medium f-col">Company</span></div>
        <br>
        <div class="d-inline-block foot-mb-4"><a href="/privacy-policy"><span
          class="privacy cursor-pointer col-grey font-outfit fs-16-lh-20 fw-normal">Privacy&nbsp;Policy</span></a></div>
        <br>
        <div class="d-inline-block foot-mb-4"><a href="/terms-conditions"><span
          class="terms cursor-pointer col-grey font-outfit fs-16-lh-20 fw-normal">Terms&nbsp;of&nbsp;Conditions</span></a>
        </div>
        <br>
        <span>&nbsp;</span>
      </div>
      <div class="logo-icons mr-255">
        <img class="itspace-logo d-inline-block position-absolute"
             src="/assets/images/brand/logo/logo-itspace.png" alt="itspace-logo">
        <img class="insta-icon position-absolute d-inline-block z-index-important"
             src="./assets/images/brand/insta.png" alt="insta-icon">
        <div class="first-icon position-absolute d-inline-block br-2-black"></div>
        <img class="facebook-icon position-absolute d-inline-block z-index-important"
             src="./assets/images/brand/facebook.png" alt="facebook-icon">
        <div class="second-icon position-absolute d-inline-block br-2-black"></div>
        <img class="linkedin-icon position-absolute d-inline-block z-index-important"
             src="./assets/images/brand/linkedin.png" alt="linkedin-icon">
        <div class="third-icon position-absolute d-inline-block br-2-black"></div>
        <img class="tiktok-icon position-absolute d-inline-block z-index-important"
             src="./assets/images/brand/tiktok.png" alt="tiktok-icon">
        <div class="forth-icon position-absolute d-inline-block br-2-black"></div>
        <img class="youtube-icon position-absolute d-inline-block z-index-important"
             src="./assets/images/brand/youtube.png" alt="youtube-icon">
        <div class="fifth-icon position-absolute d-inline-block br-2-black"></div>
      </div>
    </div>
  </div>
  <div class="lower-footer w-100 d-flex justify-content-center align-items-center">
    <div class="w-1240 pb-5">
      <div class="not-mobile-div pl-50 position-absolute"><span class="it-quiz-text fw-bold font-outfit">IT&nbsp;<span
        class="q-spec">Q</span>uiz</span></div>
      <div class="reserved-rights pr-50 pt-35 position-absolute w-1240 d-flex"><span
        class="fs-16-lh-20 fw-normal f-col">©&nbsp;2024&nbsp;All&nbsp;rights&nbsp;reserved</span></div>
    </div>
  </div>
</section>
