import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/environments/environment';
import {AnswerOption} from "../models/AnswerOption";

@Injectable({
  providedIn: 'root'
})
export class AnswerOptionService {

  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) { }

  saveAnswerOptions(answerOptions: { answers: AnswerOption[] }): Observable<any> {
    let headers = this.createHeaders();
    return this.http.post(`${this.apiUrl}/answers`, answerOptions, {headers})
  }

  private createHeaders(): HttpHeaders {
    const token = localStorage.getItem('fb-token');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }
}
