import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/environments/environment';
import {Admin} from "../models/admin";
import {ResetPassword} from "../models/ResetPassword";
import {AdminLogin} from "../models/adminLogin";
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient, private jwtHelper: JwtHelperService) {
  }

  registration(admin: Admin): Observable<Admin> {
    return this.http.post<Admin>(`${this.apiUrl}/admin/registration`, admin)

  }

  confirmRegistration(token: string): Observable<Admin> {
    return this.http.get<Admin>(`${this.apiUrl}/admin/registration-confirm?emailToken=${token}`);
  }

  login(admin: AdminLogin) {
    return this.http.post(`${this.apiUrl}/admin/login`, admin)
  }

  sendMessageForPasswordReset(email: any) {
    return this.http.get(`${this.apiUrl}/admin/forgot/password?email=` + email, {responseType: 'text'})
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post<ResetPassword>(`${this.apiUrl}/admin/reset/password`, resetPassword)
  }

  public setToken(response: any) {
    if (response) {
      localStorage.setItem('fb-token', response.token)
    } else {
      localStorage.clear()
    }
  }

  get token() {
    return localStorage.getItem('fb-token')
  }

  logout() {
    this.setToken(null)
  }

  isAuthenticated() {
    return !!this.token && !this.jwtHelper.isTokenExpired(this.token);
  }

  getAdminByEmailToken(token: string):Observable<Admin> {
    return this.http.get<Admin>(`${this.apiUrl}/admin?token=` + token);
  }

  getCurrentUserId(): string | null {
    const token = localStorage.getItem('fb-token');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken.userId;
    }
    return null;
  }
}
