import {Component, Inject, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {QuizCategory} from '../../models/quiz-category';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {QuizCategoryService} from "../../services/quiz-category.service";
import {CategorySharedService} from "../../services/category-shared.service";

@Component({
  selector: 'app-quiz-category',
  templateUrl: './quiz-category.component.html',
  styleUrls: ['./quiz-category.component.css']
})
export class QuizCategoryComponent {

  @ViewChild('categoryForm', {static: true}) categoryForm!: NgForm;
  categoryExistsMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<QuizCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private quizCategoryService: QuizCategoryService,
    private categorySharedService: CategorySharedService
  ) {}

  addCategory(categoryForm: NgForm) {
    const categoryName = categoryForm.control.get('category')?.value;
    this.categorySharedService.categories$.subscribe(categories => {
      const categoryExists = categories.some((category: QuizCategory) => category.name === categoryName);

      if (!categoryExists) {
        const category: QuizCategory = { name: categoryName };
        this.quizCategoryService.save(category).subscribe(category => {
          this.categorySharedService.addCategory(category);
          this.dialogRef.close({ success: true, category });
        });
      } else {
        this.categoryExistsMessage = 'Category name should be unique';
        setTimeout(() => {
          this.categoryExistsMessage = '';
        }, 10000);
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
