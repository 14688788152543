import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {QuizService} from "../services/quiz.service";
import {Quiz} from "../models/quiz";
import {Router} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {GeneralRequestModel} from "../models/generalRequestModel";
import {QuizStatus} from "../models/quizStatus";
import {environment} from "../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {QuizDialogComponent} from "../quiz/quiz-dialog/quiz-dialog.component";
import {SharedService} from "../services/shered.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ApiResponse} from "../models/ApiResponse";
import {QuizModalComponent} from "../quiz/quiz-modal/quiz-modal.component";
import {QuizStatusCheckService} from "../services/quiz.status.check.service";

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.css']
})
export class SharedComponent implements OnInit {

  isSidebarMenuToggled: boolean = false;
  quizzes: Quiz[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  quizzesTotalCount: number = 0;
  apiUrl!: string;
  searchTerm: string = '';
  searchResults: Quiz[] = [];
  protected readonly QuizStatus = QuizStatus;
  @ViewChildren('dropdownMenu') dropdownMenus!: QueryList<ElementRef>;

  constructor(
    private authService: AuthService,
    private quizService: QuizService,
    private router: Router,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private quizStatusCheckService: QuizStatusCheckService
  ) {}

  ngOnInit(): void {
    this.sharedService.updatedEvent.subscribe(_ => {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.handleResultRetrieval();
    });

    const userId = this.authService.getCurrentUserId();
    this.getQuizzes(userId, this.pageNumber, this.pageSize);
    this.apiUrl = environment.apiUrl;
  }

  getQuizzes(userId: string | null, page: number, count: number) {
    const generalRequest: GeneralRequestModel = {page, count};
    this.quizService.getAllByUserId(userId, generalRequest).subscribe(
      (response) => {
        this.initializeProperties(response);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  getStatus(enumType: string): string {
    switch (enumType) {
      case QuizStatus.SETUP_IN_PROGRESS:
        return 'Setup in process';
      case QuizStatus.ACTIVE:
        return 'Active';
      case QuizStatus.ENDED:
        return 'Ended';
      default:
        return 'Unknown type';
    }
  }

  getSanitizedHTML(htmlContent: string | undefined): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(<string>htmlContent);
  }

  setSidebarMenuToggled(toggled: boolean) {
    this.isSidebarMenuToggled = toggled;
  }

  cleanLocalStorageAndNavigate() {
    localStorage.removeItem('quiz');
    this.router.navigate(['/quiz/add']);
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.handleResultRetrieval();
    }
  }

  nextPage() {
    const pageMaxPossibleValue = Math.ceil(this.quizzesTotalCount / this.pageSize);
    if (this.pageNumber < pageMaxPossibleValue) {
      this.pageNumber++;
      this.handleResultRetrieval();
    }
  }

  goToPage(page: number) {
    this.pageNumber = page;
    this.getQuizzes(this.authService.getCurrentUserId(), this.pageNumber, this.pageSize);
  }

  openDuplicateDialog(quizName: string | undefined, quizId: string | undefined): void {
    this.dialog.open(QuizDialogComponent, {
      width: '40%',
      data: {
        showDuplicateDialog: true,
        quizName: quizName,
        quizId: quizId,
        userId: this.authService.getCurrentUserId()
      }
    });
  }

  openDeleteDialog(quizId: string | undefined) {
    this.dialog.open(QuizDialogComponent, {
      width: '35%',
      data: {
        showDeleteDialog: true,
        quizId: quizId,
        userId: this.authService.getCurrentUserId()
      }
    });
  }

  onSearch(searchTerm: string, page: number, count: number) {
    this.searchTerm = searchTerm;
    if (searchTerm.length >= 2) {
      this.pageNumber = page;
      this.pageSize = count;
      this.quizService.searchQuiz(
        searchTerm.trim(),
        this.authService.getCurrentUserId(),
        { page: this.pageNumber, count: this.pageSize }
      ).subscribe((response) => {
        this.initializeProperties(response);
      });
    } else if (searchTerm === '') {
      this.getQuizzes(this.authService.getCurrentUserId(), this.pageNumber, this.pageSize);
    }
  }

  onEnterKeyPressed(searchTerm: string) {
    this.onSearch(searchTerm, 1, 10);
  }

  private initializeProperties(apiResponse: ApiResponse<Quiz>) {
    this.quizzes = apiResponse.data.map(quiz => ({
      ...quiz,
      readOnly: this.quizStatusCheckService.isReadOnly(quiz.status)
    }));
    this.searchResults = this.quizzes;
    this.quizzesTotalCount = apiResponse.meta.total;
  }

  private handleResultRetrieval() {
    if (this.searchTerm.trim().length > 2) {
      this.onSearch(this.searchTerm, this.pageNumber, this.pageSize);
    } else {
      this.getQuizzes(this.authService.getCurrentUserId(), this.pageNumber, this.pageSize);
    }
  }

  openQuizGenerationDialog() {
    const dialogRef = this.dialog.open(QuizModalComponent);
    dialogRef.afterClosed().subscribe();
  }

  isReadOnly(quizStatus: QuizStatus): boolean {
    return this.quizStatusCheckService.isReadOnly(quizStatus)
  }
}
