<section class="pt-5 pb-5">
  <div class="container">
    <img
      *ngIf="getSafeUrl()"
      [src]="getSafeUrl()"
      alt="Quiz Logo"
      class="w-100 img-fluid custom-cover"
    />
    <div class="card-body pb-4">
      <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3">
            <h3 *ngIf="quiz" class="mb-0 mb"><a class="text-inherit">{{ quiz.name }}</a></h3>
          </div>
        </div>
        <div *ngIf="quiz && quiz.duration" class="timer-interval">
          <span class="text-danger me-3">
            <i class="fe fe-clock me-1 align-middle"></i>
            {{ hours < 10 ? '0' + hours : hours }} :
            {{ minutes < 10 ? '0' + minutes : minutes }} :
            {{ seconds < 10 ? '0' + seconds : seconds }}
          </span>
        </div>
      </div>
      <div class="mt-3">
        <!-- text -->
        <div class="d-flex justify-content-between">
          <span class="left">Exam Progress:</span>
          <span class="margin"> Question {{ currentQuestionNumber }} out of {{ totalQuestionsCount }}</span>
        </div>
        <!-- progress bar -->
        <div class="mt-2">
          <div class="progress">
            <div class="progress-bar bg-success" [style.width.%]="(currentQuestionNumber / totalQuestionsCount) * 100"
                 role="progressbar" [attr.aria-valuenow]="(currentQuestionNumber / totalQuestionsCount) * 100"
                 aria-valuemin="0"
                 aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <span class="ml-3">Question {{ currentQuestionNumber }}</span>
        <div *ngIf="questionAnswers" class="text-truncate truncate-exam">
          <h3 class="mb-3 mt-1 ml-3" [innerHTML]="getSanitizedHTML(questionAnswers.ask)"></h3>
        </div>
        <div class="ml-3 mb-2" *ngIf="questionAnswers?.questionType === QuestionType.MULTI_SELECT">You can select more
          than one answer.
        </div>
        <!-- list group -->
        <div class="editor-container"
             *ngIf="questionAnswers?.questionType === QuestionType.DESCRIPTIVE ||
             questionAnswers?.questionType === QuestionType.SHORT_ANSWER">
          <angular-editor placeholder="Add your Content here" [config]="configNoInsertImage"
                          [(ngModel)]="editorContent"></angular-editor>
        </div>
        <div class="list-group" *ngIf="questionAnswers && questionAnswers.questionType !== QuestionType.SHORT_ANSWER">
          <div *ngFor="let answer of questionAnswers.answers" class="list-group-item list-group-item-action">
            <div
              *ngIf="questionAnswers.questionType === QuestionType.SINGLE_SELECT || questionAnswers.questionType === QuestionType.TRUE_OR_FALSE"
              class="form-check">
              <ng-container *ngIf="respondentAnswers && respondentAnswers.answers; else singleContent">
                <input [checked]="this.getRespondentAnswersVariantNames()?.includes(answer.id)" class="form-check-input"
                       type="radio" [name]="'flexRadioDefault' + questionAnswers.id"
                       [id]="'flexRadioDefault' + answer.id" (click)="handleRadioClick($event, answer)">
              </ng-container>
              <ng-template #singleContent>
                <input class="form-check-input" type="radio" [name]="'flexRadioDefault' + questionAnswers.id"
                       [id]="'flexRadioDefault' + answer.id" (click)="handleRadioClick($event, answer)">
              </ng-template>
              <label class="form-check-label stretched-link text-wrap text-break" [for]="'flexRadioDefault' + answer.id"
                     [innerHTML]="getSanitizedHTML(answer.variantName)">
              </label>
            </div>
            <div *ngIf="questionAnswers.questionType === QuestionType.MULTI_SELECT" class="form-check">
              <ng-container *ngIf="this.respondentAnswers?.answers; else multiContent">
                <input [checked]="this.getRespondentAnswersVariantNames()?.includes(answer.id)" class="form-check-input"
                       type="checkbox" [name]="'flexCheckboxDefault' + questionAnswers.id"
                       [id]="'flexCheckboxDefault' + answer.id" (click)="handleCheckboxClick($event, answer)">
              </ng-container>
              <ng-template #multiContent>
                <input class="form-check-input" type="checkbox" [name]="'flexCheckboxDefault' + questionAnswers.id"
                       [id]="'flexCheckboxDefault' + answer.id" (click)="handleCheckboxClick($event, answer)">
              </ng-template>
              <label class="form-check-label stretched-link" [for]="'flexCheckboxDefault' + answer.id">
                <span class="text-dark text-wrap text-break" [innerHTML]="getSanitizedHTML(answer.variantName)"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="relative-container" *ngIf="currentQuestionNumber <= 1 || !answerInAnyOrder">
        <button class="bt btn btn-danger mt-4"
                [ngStyle]="{'left': currentQuestionNumber < totalQuestionsCount ? '0' : '131%'}"
                (click)="saveResultAndGet()"
                [disabled]="isButtonEndDisabled || !EditorValid.isEditorValid(editorContent)">End
        </button>
      </div>

      <div class="relative-container" style="margin-left: auto" *ngIf="currentQuestionNumber > 1 && answerInAnyOrder">
        <button class="bt btn btn-danger  mt-4"
                [ngStyle]="{'left': currentQuestionNumber < totalQuestionsCount ? '-7%' : '122%'}"
                (click)="saveResultAndGet()"
                [disabled]="isButtonEndDisabled || !EditorValid.isEditorValid(editorContent)">End
        </button>
      </div>

      <div style="margin-left: auto;">
        <button *ngIf="currentQuestionNumber > 1 && answerInAnyOrder" class="btn btn-primary mt-4 left"
                [ngClass]="{'cont': currentQuestionNumber === totalQuestionsCount}" (click)="previous()">
          <i class="fe fe-arrow-left"></i> Previous
        </button>


        <button
          *ngIf="currentQuestionNumber !== totalQuestionsCount &&  (this.questionAnswers?.questionType !== QuestionType.SHORT_ANSWER && this.questionAnswers?.questionType !== QuestionType.DESCRIPTIVE)"
          class="btn btn-primary mt-4 right"
          [disabled]="isButtonDisabled || (answerIds.length === 0 && !answerInAnyOrder)"
          (click)="next()">
          Next <i class="fe fe-arrow-right"></i>
        </button>

        <button
          *ngIf="currentQuestionNumber !== totalQuestionsCount && (questionAnswers?.questionType === QuestionType.SHORT_ANSWER || questionAnswers?.questionType === QuestionType.DESCRIPTIVE)"
          class="btn btn-primary mt-4 right"
          [disabled]="isButtonDisabled || (answerIds.length === 0 && !EditorValid.isEditorValid(editorContent))"
          (click)="next()">
          Next <i class="fe fe-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</section>
