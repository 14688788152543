import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ResetPassword} from "../models/ResetPassword";
import {confirmPasswordValidator} from "../validators/confirmPassword.validators";
import {AdminLogin} from "../models/adminLogin";
import {HttpErrorResponse} from '@angular/common/http';
import {Admin} from "../models/admin";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  message: string = '';
  adminEmail: string = '';
  newPasswordFieldType: string = 'password';
  confirmPasswordFiledType: string = 'password';
  resetPasswordForm!: FormGroup

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) {
    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*\\d).{8,}$')]),
      confirmPassword: new FormControl('', [confirmPasswordValidator('newPassword')]),
    });
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.resetPasswordForm.get('confirmPassword')?.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const token = queryParams['token'];
      if (token) {
        this.auth.getAdminByEmailToken(token).subscribe((admin: Admin) => {
          if (admin) {
            this.adminEmail = admin?.email;
          } else {
            this.router.navigate(['/dashboard']);
          }
        })
      }
    })
  }

  sendEmailMessage(emailForm: NgForm) {
    this.auth.sendMessageForPasswordReset(emailForm.control.value.email).subscribe(
      (response) => {
        this.message = "A verification link has been successfully sent to your email address.";
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.message = "This email address doesn't exist";
        }
      })
    emailForm.reset('');
    setTimeout(() => {
      this.message = ""
    }, 10000);
  }

  resetPassword() {
    const resetPasswordDto = this.resetPasswordForm.getRawValue() as ResetPassword;
    resetPasswordDto.email = this.adminEmail;
    this.auth.resetPassword(resetPasswordDto).subscribe(() => {
      this.resetPasswordForm.reset();
      this.auth.login(this.getAdminFromResetPassword(resetPasswordDto)).subscribe(res => {
        this.auth.setToken(res);
        this.router.navigate(["/dashboard"])
      })
    }, () => {
      this.router.navigate(["/"]);
    })
  }

  togglePasswordVisibility(passwordFiledType: string): void {
    if (passwordFiledType === 'newPasswordFieldType') {
      this.newPasswordFieldType = (this.newPasswordFieldType === 'password') ? 'text' : 'password';
    } else {
      this.confirmPasswordFiledType = (this.confirmPasswordFiledType === 'password') ? 'text' : 'password';
    }
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  getAdminFromResetPassword(resetPassword: ResetPassword): AdminLogin {
    return {
      email: this.adminEmail,
      password: resetPassword.newPassword,
      rememberMe: false
    }
  }
}
