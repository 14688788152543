<div class="bg-light rounded m-5">
  <img
    *ngIf="getSafeUrl()"
    [src]="getSafeUrl()"
    alt="Quiz Logo"
    class="w-100 img-fluid custom-cover"
  />
  <form [formGroup]="startPageForm" (ngSubmit)="submit()">
    <mat-card class="mb-3">
      <mat-card-content>
        <div><h4>{{ quiz?.name }}</h4></div>
      </mat-card-content>
    </mat-card>
    <div class="list-group list-group-flush">
      <mat-card>
        <mat-card-content *ngIf="!isQuizEnded">
          <div>
            <div>
              <div *ngIf="quiz?.status === QuizStatus.SETUP_IN_PROGRESS"
                   class="alert alert-danger mb-3 mt-2">
                Test is being configured. Contact the test owner to learn more.
              </div>
              <div *ngIf="this.resultSavedErrorMessage" class="alert alert-danger mb-3 col-md-4 mt-2 text-center">
                {{ this.resultSavedErrorMessage }}
              </div>
              <div class="mb-3 col-md-3">
                <label class="form-label" for="firstName">First Name<span class="text-danger"> *</span></label>
                <input type="text" formControlName="firstName" class="form-control" placeholder="Enter first name"
                       id="firstName" required>
              </div>
              <div class="validation"
                   *ngIf="startPageForm.get('firstName')!.errors?.['required'] && (startPageForm.get('firstName')!.touched || startPageForm.get('firstName')!.dirty)">
                First name cannot be blank.
              </div>
              <div class="mb-3 col-md-3">
                <label class="form-label" for="lastName">Last Name<span class="text-danger"> *</span></label>
                <input type="text" class="form-control" formControlName="lastname" placeholder="Last Name" id="lastName"
                       required>
              </div>
              <div class="validation"
                   *ngIf="startPageForm.get('lastname')!.errors?.['required'] && (startPageForm.get('lastname')!.touched || startPageForm.get('lastname')!.dirty)">
                Last name cannot be blank.
              </div>
              <div class="mb-3 col-md-3">
                <label class="form-label" for="email">Email<span class="text-danger"> *</span></label>
                <input type="email" class="form-control" formControlName="emailAddress"
                       placeholder="Enter email address"
                       id="email" required>
              </div>
              <div class="validation"
                   *ngIf="startPageForm.get('emailAddress')!.errors?.['required'] && (startPageForm.get('emailAddress')!.touched || startPageForm.get('emailAddress')!.dirty)">
                Email Address cannot be blank.
              </div>
              <div class="validation"
                   *ngIf="startPageForm.get('emailAddress')!.hasError('email') && (startPageForm.get('emailAddress')!.touched || startPageForm.get('emailAddress')?.dirty)">
                Please enter a valid email address
              </div>
              <div class="mb-3 col-md-3">
                <label class="form-label" for="phone">Phone</label>
                <input type="number" class="form-control" formControlName="phoneNumber" placeholder="Enter phone number"
                       id="phone">
              </div>
              <button class="btn btn-primary" type="submit"
                      [disabled]="startPageForm.invalid || quiz?.status === QuizStatus.SETUP_IN_PROGRESS">Start
              </button>
              <button *ngIf="quiz?.status === QuizStatus.SETUP_IN_PROGRESS"
                      class="btn btn-outline-primary text-dark border-dark m-2" (click)="refreshPage()">
                <i class="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
        </mat-card-content>
        <mat-card-content *ngIf="isQuizEnded">
          <div>
            <div class="mb-3 col-md-4">
              <div class="form-label"> NOTIFICATION
              </div>
              <div> This test has been already ended.
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
