<div id="db-wrapper" [ngClass]="{ 'toggled' : isSidebarMenuToggled}">
  <app-sidebar></app-sidebar>
  <main id="page-content">
    <app-header (toggleSidebarEvent)="setSidebarMenuToggled($event)"></app-header>
    <section class="container-fluid p-4">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="border-bottom pb-3 mb-3 d-lg-flex align-items-center justify-content-between">
            <div class="mb-2 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Results Database</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a routerLink="/dashboard">Results Database</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    All
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <!-- Card -->
          <div class="card rounded-3">
            <div class="p-3 row gx-5">
              <div class="left-side col-6 col-lg-6 mt-1 mb-lg-0">
                <div *ngIf="!selectedResults.selected.length; else isSelected" class="user-count">
                  Results({{ resultsTotalCount }})
                </div>
                <ng-template #isSelected>
                  <div class="user-count me-3">{{ selectedResults.selected.length }} results selected</div>

                  <div class="d-flex justify-content-between cursor-pointer"
                       (click)="openDeleteDialog(selectedResults.selected)">
                    <div class="vertical-separator-line me-3"></div>
                    <a class="items">
                      <i class="fa-regular fa-trash-can"></i>Delete</a>
                  </div>
                </ng-template>
              </div>
              <div class="col-4 col-lg-4 mb-3 mb-lg-0">
                <div class="row">
                  <div class="col-8 col-lg-8 mb-3 mb-lg-0">
                    <form class="d-flex align-items-center">
                <span class="position-absolute ps-3 search-icon">
                    <i class="fe fe-search"></i>
                </span>
                      <input type="search" class="form-control ps-6" name="search" [(ngModel)]="searchTerm"
                             (input)="onSearch({page: 1, count: 10})"
                             (keyup.enter)="onSearch({page: 1, count: 10})" placeholder="Search Test">
                    </form>
                  </div>
                  <div class="col-4 col-lg-4 mb-2 mb-lg-0">
                    <div class="form-container">
                      <div class="form-row">
                        <i class="fa-solid fa-solid-filter fa-filter" (click)="toggleFilter()" #filterIcon></i>
                      </div>
                      <div class="filters" (click)="toggleFilter()" #filterContainer>Filters</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="window" *ngIf="showFilter" #windowRef>
              <h2 class="filter-header">Filters</h2>
              <div class="wrapper">
                <select class="form-select"
                        (focus)="setSizeForQuizDropdown()"
                        (blur)="this.quizFilterDropdownSize = 1;"
                        (change)="this.quizFilterDropdownSize = 1;"
                        [size]="quizFilterDropdownSize" #quiz>
                  <option [selected]="!selectedQuizId" value="Test Name">Test Name</option>
                  <option class="font-size-decrease"
                          *ngFor="let quiz of this.quizzes"
                          [selected]=" quiz.id == selectedQuizId" [value]="quiz.id">{{ quiz.name }}
                  </option>
                </select>
              </div>

              <div class="wrapper">
                <div class="wrapper">
                  <select class="form-select"
                          (focus)="setSizeForCategoryDropdown()"
                          (blur)="this.quizCategoryDropdownSize = 1;"
                          (change)="this.quizCategoryDropdownSize = 1;"
                          [size]="quizCategoryDropdownSize" #category>
                    <option [selected]="!selectedTestCategoryId" value="Test Category">Test Category</option>
                    <option class="font-size-decrease"
                            *ngFor="let category of quizCategories"
                            [value]="category.id"
                            [selected]="category.id === selectedTestCategoryId">{{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="button-container">
                  <button [disabled]="quiz.value === 'Test Name' && category.value === 'Test Category'"
                          class="button btn btn-primary"
                          type="button" (click)=" resetFilter()">Reset
                  </button>
                  <button [disabled]="quiz.value === 'Test Name' && category.value === 'Test Category'"
                          class="button btn btn-primary"
                          type="button" (click)="onFilter({page: 1, count: 10}, category.value, quiz.value)">Apply
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div class="tab-content" id="tabContent">
                <div class="tab-pane fade show active" id="all-orders" role="tabpanel"
                     aria-labelledby="all-orders-tab">
                  <div class="table-responsive">
                    <table class="table mb-0  text-nowrap table-hover table-centered table-with-checkbox">
                      <thead class="table-light">
                      <tr>
                        <th>
                          <div class="form-check" (click)="toggleAllRows()">
                            <input type="checkbox" [checked]="selectedResults.selected.length === results.length"
                                   class="form-check-input cursor-pointer" id="checkAll">
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </th>
                        <th>Test Name</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>SCORE
                          <i class="fa-solid fa-sort" (click)="sortInAscendingOrDescendingOrder()"></i>
                        </th>
                        <th>End Date</th>
                        <th>Time</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let result of searchResults" class="cursor-pointer"
                          (click)="goToRespondentResult(result)">
                        <td>
                          <div class="form-check" (click)="checkboxLabel($event,result)">
                            <input type="checkbox" [checked]=" selectedResults.selected.includes(result)"
                                   class="form-check-input cursor-pointer" id="orderOne">
                            <label class="form-check-label" for="orderOne"></label>
                          </div>
                        </td>
                        <td>
                          <a [innerHTML]="FormatUtil.nextLineBreaker(result.quizName, 14)">
                          </a>
                        </td>
                        <td [innerHTML]="FormatUtil.nextLineBreaker(result.firstname, 14)">
                        </td>
                        <td [innerHTML]="FormatUtil.nextLineBreaker(result.lastname, 14)">
                        </td>
                        <td [innerHTML]="FormatUtil.nextLineBreaker(result.email, 30)">
                        </td>
                        <td>
                          <span class="badge" [ngClass]="{
                           'text-success bg-light-success': result && result.testPassed && result.testEnded,
                           'text-danger bg-light-danger': result && !result.testPassed && result.testEnded,
                           'bg-secondary': !result.testEnded}">
                            {{ result.actualPercentage + "%(" + result.actualScore + "/" + result.quizTotalScore + ")" }}
                          </span>
                        </td>
                        <td>
                          <span>{{ result.endTime === undefined ? "-" : result.endTime | date :"dd/MM/yyyy HH:mm" }}</span>
                        </td>
                        <td>
                          <span class="badge text-bg-secondary">{{ result?.timeSpent ?? 0 | formatTime }}</span>
                        </td>
                        <td>
                          <span class="dropdown dropstart" (click)="$event.stopPropagation()">
                            <a class="btn-icon btn btn-ghost btn-sm rounded-circle" role="button"
                               id="orderDropdownOne" data-bs-offset="-20,20"
                               aria-label="Open menu" [matMenuTriggerFor]="beforeMenu">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <span class="dropdown-menu">
                              <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <a class="dropdown-item cursor-pointer"
                                   (click)="goToRespondentResult(result)">
                                  <i class="fa-solid fa-arrow-right dropdown-item-icon"></i>Open</a>
                                <a class="dropdown-item cursor-pointer"
                                   (click)="openDeleteDialog([result])"><i
                                  class="fe fe-trash dropdown-item-icon"></i>Delete</a>
                              </mat-menu>
                            </span>
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <span class="error"
                  *ngIf="(searchResults.length === 0 && searchTerm.trim()) ||
                   searchResults.length === 0 && results.length !== 0 || ((this.selectedTestCategoryId || this.selectedQuizId) && results.length === 0)">There are no Quiz Results</span>

            <div class="card-footer d-md-flex justify-content-end"
                 *ngIf="resultsTotalCount > this.paginationModel.count">
              <nav aria-label="">
                <ul class="pagination  mb-0">
                  <li class="page-item cursor-pointer" (click)="previousPage()"
                      [class.disabled]="paginationModel.page === 10">
                    <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-left"></i></a>
                  </li>
                  <li class="page-item cursor-pointer"
                      (click)="goToPageNumber(paginationModel.page)">
                    <a class="page-link mx-1 rounded">{{ paginationModel.page }}</a>
                  </li>
                  <li class="page-item cursor-pointer" (click)="nextPage()"
                      [class.disabled]="results.length < paginationModel.count">
                    <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
