<div class="custom-snackbar d-flex justify-content-between align-items-center">
  <div>
    <img src="https://cdn0.iconfinder.com/data/icons/shift-free/32/Incorrect_Symbol-512.png" srcset="https://cdn0.iconfinder.com/data/icons/shift-free/32/Incorrect_Symbol-1024.png 2x" alt="" class="mx-3 w-20-px">
    <span class="text-muted">Error</span>
  </div>
  <button mat-icon-button (click)="dismissSnackbar()" class="close-button">
    <mat-icon class="close-icon">close</mat-icon>
  </button>


</div>
<ul style="max-width: 400px">
  <li *ngIf="editErrors.get('QuestionScoreError')">
    {{ editErrors.get('QuestionScoreError') }}
  </li>
  <li *ngIf="editErrors.get('QuizIdError')">
    {{ editErrors.get('QuizIdError') }}
  </li>
  <li *ngIf="editErrors.get('QuizNotFoundError')">
    {{ editErrors.get('QuizNotFoundError') }}
  </li>
  <li *ngIf="editErrors.get('QuestionAskError')">
    {{ editErrors.get('QuestionAskError') }}
  </li>
  <li *ngIf="editErrors.get('QuestionTypeError')">
    {{ editErrors.get('QuestionTypeError') }}
  </li>
  <li *ngIf="editErrors.get('QuestionIdError')">
    {{ editErrors.get('QuestionIdError') }}
  </li>
  <li *ngIf="editErrors.get('QuestionNotFoundError')">
    {{ editErrors.get('QuestionNotFoundError') }}
  </li>
  <li *ngIf="editErrors.get('QuizMismatchError')">
    {{ editErrors.get('QuizMismatchError') }}
  </li>
  <li *ngIf="editErrors.get('SerialNumberError')">
    {{ editErrors.get('SerialNumberError') }}
  </li>
  <li *ngIf="editErrors.get('SerialNumberMismatchError')">
    {{ editErrors.get('SerialNumberMismatchError') }}
  </li>
</ul>
