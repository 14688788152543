import { Component } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  subscriptionPlanLength = 3;
  currentPosition = 0;
  isChecked = true;
  isQuizCreationVisible: boolean = true;
  isRealTimeProgressVisible: boolean = false;
  isSeamlessIntegrationVisible: boolean = false;
  isOptimizedDevicesVisible: boolean = false;
  QUIZ_CREATION: string = 'quizCreation';
  REAL_TIME_PROGRESS: string = 'realTimeProgress';
  SEAMLESS_INTEGRATION: string = 'seamlessIntegration';
  OPTIMIZED_DEVICES: string = 'optimizedDevices';
  slides = [
    { title: 'How do I start my free trial?', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
    { title: 'Frequently asked questions?', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.' },
    { title: 'How to ask frequent questions?', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.' },
    { title: 'How can I contact customer support?', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
    { title: 'How do I start my free trial?', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.' },
  ];
  isMenuClicked = false;

  onMenuToggle() {
    this.isMenuClicked = !this.isMenuClicked;
  }

  toggleSection(section: string) {
    this.isQuizCreationVisible = false;
    this.isRealTimeProgressVisible = false;
    this.isSeamlessIntegrationVisible = false;
    this.isOptimizedDevicesVisible = false;
    switch(section) {
      case this.QUIZ_CREATION:
        this.isQuizCreationVisible = !this.isQuizCreationVisible;
        break;
      case this.REAL_TIME_PROGRESS:
        this.isRealTimeProgressVisible = !this.isRealTimeProgressVisible;
        break;
      case this.SEAMLESS_INTEGRATION:
        this.isSeamlessIntegrationVisible = !this.isSeamlessIntegrationVisible;
        break;
      case this.OPTIMIZED_DEVICES:
        this.isOptimizedDevicesVisible = !this.isOptimizedDevicesVisible;
        break;
    }
  }

  getSlideStyles(index: number) {
    const position = this.currentPosition;
    const offset = index - position;
    const absOffset = Math.abs(offset);
    const translateX = offset * (50 + 4);
    const scaleY = 1 - absOffset * 0.1;
    const baseWidth = 700;
    const width = baseWidth - absOffset * 5;
    const finalWidth = Math.max(width, 500);

    return {
      transform: `translateX(${translateX}px) scaleY(${scaleY})`,
      zIndex: 5 - absOffset,
      width: `${finalWidth}px`,
    };
  }

    getSlideStylesMobile(index: number) {
    const position = this.currentPosition;
    const offset = index - position;
    const absOffset = Math.abs(offset);
    const translateX = offset * (16 + 4);
    const scaleY = 1 - absOffset * 0.1;
    const baseWidth = 700;
    const width = baseWidth - absOffset * 5;
    const finalWidth = Math.max(width, 500);
    const zIndex = 5 - absOffset;
    const opacity = absOffset <= 1 ? 1 : 0;

    return {
      transform: `translateX(${translateX}px) scaleY(${scaleY})`,
      zIndex: zIndex,
      width: `${finalWidth}px`,
      opacity: opacity,
    };
  }

  nextSlide() {
    if (this.currentPosition < this.slides.length - 1) {
      this.currentPosition++;
    }
  }

  prevSlide() {
    if (this.currentPosition > 0) {
      this.currentPosition--;
    }
  }
}
