import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  transform(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;

    return `${this.padWithZero(hours)}:${this.padWithZero(minutes)}:${this.padWithZero(remainingSeconds)}`;
  }

  private padWithZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
