import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {QuizCategory} from "../../models/quiz-category";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AngularEditorConfigsUtil} from "../../util/angular-editor-configs.util";
import {DifficultyLevel} from "../../models/difficulty-level";
import {CategorySharedService} from "../../services/category-shared.service";
import {QuizCategoryComponent} from "../quiz-category/quiz-category.component";
import {QuizService} from "../../services/quiz.service";
import {QuizGenerationRequest} from "../../models/quiz-generation-request";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Language} from "../../models/language.enum";

@Component({
  selector: 'app-quiz-modal',
  templateUrl: './quiz-modal.component.html',
  styleUrls: ['./quiz-modal.component.css']
})
export class QuizModalComponent implements OnInit {
  isLoading: boolean = false;
  isGenerationFailed: boolean = false;
  config: AngularEditorConfig = {
    editable: true,
    rawPaste: true,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  };

  quizGenerationForm!: FormGroup;
  categories!: Array<QuizCategory>;

  languageEnum = Language;

  difficultyLevels = [
    DifficultyLevel.EASY,
    DifficultyLevel.MEDIUM,
    DifficultyLevel.HIGH,
  ];

  constructor(
    public dialogRef: MatDialogRef<QuizModalComponent>,
    private categorySharedService: CategorySharedService,
    private quizService: QuizService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.categorySharedService.categories$.subscribe((categories) => {
      this.categories = categories;
    });
    this.quizGenerationForm = this.initializeQuizGenerationForm();
    this.categorySharedService.loadCategories();
  }

  initializeQuizGenerationForm(): FormGroup {
    return new FormGroup(
      {
        quizName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        language: new FormControl('', Validators.required),
        category: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        difficultyLevel: new FormControl('', Validators.required),
        totalQuestions: new FormControl('', [Validators.required, Validators.min(1), Validators.max(15)])
      }
    )
  }

  close(): void {
    this.dialogRef.close();
  }

  loadCategories() {
    this.categorySharedService.categories$.subscribe(categories => {
      this.categories = categories;
    });
  }

  openCategoryDialog(): void {
    const dialogRef = this.dialog.open(QuizCategoryComponent, {
      width: '400px',
      data: {existingCategories: this.categories}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.loadCategories();
        const newlyAddedCategory = result.category;
        if (newlyAddedCategory) {
          this.quizGenerationForm.get('category')?.patchValue(newlyAddedCategory.id);
        }
      }
    });
  }

  generateQuiz() {
    if (this.quizGenerationForm.valid) {
      this.isLoading = true;
      this.quizService.generateWithAi(this.quizGenerationForm.getRawValue() as QuizGenerationRequest).subscribe({
        next: (data) => {
          this.dialogRef.close({success: true});
          this.router.navigate(['/quiz/add'], { queryParams: { id: data.id } });
        },
        error: () => {
          this.isGenerationFailed = true;
          setTimeout(() => {
            this.isLoading = false;
            this.isGenerationFailed = false;
          }, 3000);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }
}
