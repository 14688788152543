<div id="db-wrapper">
  <main id="page-content" class="mx-10 ms-10">
    <section class="container-fluid p-4">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="border-bottom pb-3 mb-3 d-lg-flex justify-content-between align-items-center">
            <div class="mb-3 mb-lg-0">
              <h1 class="mb-0 h2 fw-bold">Result</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-6 col-md-12 col-12">
          <!-- Card -->
          <div class="card mb-4">
            <!-- Card body -->
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between mb-3 lh-1">
                <div>
                  <span class="fs-6 text-uppercase fw-semibold ls-md">Respondent</span>
                </div>
              </div>
              <span class="ms-1 fw-medium">
                <i class="fe fe-user mx-1 ms-n2 purple"></i>
                {{ result && result.firstname + ' ' + result.lastname }}
              </span><br>
              <span class="ms-1 fw-medium">
                <i class="fe fe-mail ms-n2 mx-1 purple"></i>
                E-mail address: {{ result && result.email }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-6 col-md-12 col-12">
          <!-- Card -->
          <div class="card mb-4">
            <!-- Card body -->
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between mb-3 lh-1">
                <div>
                  <span class="fs-6 text-uppercase fw-semibold ls-md">Summary</span>
                </div>
              </div>
              <span class="ms-1 fw-medium">
                <i class="fe fe-bell m-1 ms-n2 purple"></i>
                <span *ngIf="result && result.endMessage" [innerHTML]="getSanitizedHTML(result.endMessage)"></span>
                <span *ngIf="result && !result.endMessage">There is no end Message.</span>
              </span><br>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="result?.actualPercentage != null" class="row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-12">
          <div class="card mb-4">
            <div
              class="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
              <div>
                <h4 class="mb-0">Result</h4>
              </div>
            </div>
            <div class="card-body h-190">
              <div id="earning" class="apex-charts d-flex justify-content-between" *ngIf="!result?.testEnded">
                <div>
                  <h3 class="text-purple"><i class="fe fe-check-square mx-2"></i>Test Ended</h3><br>
                  <h4 class="text-dark-warning mx-2"> Not all descriptive questions have been graded.</h4>
                </div>
                <div class="spinner-container mx-5">
                  <mat-progress-spinner class="custom-spinner width-height" [mode]="mode"
                                        [value]="result?.actualPercentage"></mat-progress-spinner>
                  <span class="ms-n10-new">{{ result?.actualPercentage }}%<br>
                    {{ result?.actualScore + '/ ' + result?.quizTotalScore + ' p.' }}</span>
                </div>
              </div>
              <div class="apex-charts d-flex justify-content-between"
                   *ngIf="result && !result.testPassed && result.testEnded">
                <div>
                  <h3 class="text-danger"><i class="fe fe-x-square mx-2"></i>Test Failed</h3><br>
                  <span class="text-black mx-2"> Your result is available.</span>
                </div>
                <div class="spinner-container mx-5">
                  <mat-progress-spinner class="custom-spinner-failed width-height" [mode]="mode"
                                        [value]="result.actualPercentage"></mat-progress-spinner>
                  <span class="ms-n10-new">{{ result.actualPercentage }}%<br>
                    {{ result.actualScore + '/ ' + result.quizTotalScore + ' p.' }}</span>
                </div>
              </div>
              <div class="apex-charts d-flex justify-content-between"
                   *ngIf="result && result.testPassed && result.testEnded">
                <div>
                  <h3 class="text-success"><i class="fe fe-check-square mx-2"></i>Test Passed</h3><br>
                  <span class="text-black mx-2"> Your result is available.</span>
                </div>
                <div class="spinner-container mx-5">
                  <mat-progress-spinner class="custom-spinner-passed width-height" [mode]="mode"
                                        [value]="result.actualPercentage"></mat-progress-spinner>
                  <span class="ms-n10-new">{{ result.actualPercentage }}%<br>
                    {{ result.actualScore + '/ ' + result.quizTotalScore + ' p.' }}</span>
                </div>
              </div>
              <span class="text-black mx-2">We’ll email your test results, and you’ll be notified of any updates as well.</span>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-12">
          <div class="card mb-4">
            <div
              class="card-header align-items-center card-header-height d-flex justify-content-between align-items-center">
              <div>
                <h4 class="mb-0">Timer</h4>
              </div>
            </div>
            <div class="card-body">
              <div id="traffic" class="apex-charts ">
                <h4 class="d-flex align-items-center">
                  <mat-icon class="text-gray-400 mx-2 ms-n1">schedule</mat-icon>
                  Total Time
                </h4>
                <div class="d-flex">
                  <p>{{ (result?.timeSpent ?? 0) | formatTime }}</p>
                  <p *ngIf="result?.quizDuration !== 0">/{{ (result?.quizDuration ?? 0) * 60 | formatTime }}</p>
                </div>
                <mat-progress-bar mode="determinate"
                                  [value]="calculateProgress(result?.timeSpent, result?.quizDuration)">
                </mat-progress-bar>
                <br>
                <span>
                 <span class="font-weight-bold">Start time:</span>
                  <span class="m-2">{{ startTimeLocal | date: 'HH:mm' }}</span>
                  <span class="mx-5">
                    <span class="font-weight-bold">Date:</span>
                    {{ startTimeLocal | date }}
                  </span>
                </span>
                <br>
                <span>
                  <span class="font-weight-bold">End time:</span>
                  <span class="m-2">{{ endTimeLocal | date: 'HH:mm' }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="accordion p-4" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <a class="d-flex align-items-center text-inherit text-decoration-none active cursor-pointer"
             data-bs-toggle="collapse"
             data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            <div class="me-auto">
              <h4 class="mb-0"> Questions </h4>
            </div>
            <span class="chevron-arrow ms-4">
            <i class="fe fe-chevron-down fs-4"></i>
          </span>
          </a>
        </div>
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="card-body">
            <div class="bg-light rounded p-2 mb-4">
              <div class="list-group list-group-flush border-top-0" id="courseList">
                <ng-container>
                  <ul id="items-list">
                    <li *ngFor="let question of result?.questionAnswerNameDtoList; let i = index"
                        class="list-group-item rounded px-3 text-nowrap mb-1 hover-white">
                      <div class="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 text-truncate cursor-pointer">
                          <a class="text-inherit d-flex justify-content-between">
                            <span class="align-middle question-number">Q. &nbsp;{{ i + 1 }}</span>
                            <div
                              *ngIf="question.questionType === QuestionType.MULTI_SELECT || question.questionType === QuestionType.SINGLE_SELECT || question.questionType === QuestionType.TRUE_OR_FALSE"
                              class="position-absolute text-right"
                              [ngClass]="{'custom-red-right': respondentScore(question.id) === 0, 'custom-green-right': respondentScore(question.id) !== 0}">
                              {{ respondentScore(question.id) }}/{{ question.questionScore }}p
                            </div>
                            <div *ngIf="question.questionType === QuestionType.SHORT_ANSWER"
                                 class="position-absolute text-right"
                                 [ngClass]="{'custom-red-right': shortAnswerScore(question.id) === 0, 'custom-green-right': shortAnswerScore(question.id) !== 0}">
                              {{ shortAnswerScore(question.id) }}/{{ question.questionScore }}p
                            </div>
                            <div *ngIf="question.questionType === QuestionType.DESCRIPTIVE"
                                 class="position-absolute text-right"
                                 [ngClass]="{'custom-red-right': descriptiveScore(question.id) === 0, 'custom-green-right': descriptiveScore(question.id) !== 0}">
                              {{ descriptiveScore(question.id) }}/{{ question.questionScore }}p
                            </div>
                          </a>
                        </h5>
                      </div>
                      <div
                        *ngIf="question.questionType === QuestionType.MULTI_SELECT || question.questionType === QuestionType.SINGLE_SELECT || question.questionType === QuestionType.TRUE_OR_FALSE ">
                        <div class="text-truncate truncate">
                          <span class="text-dark text-wrap text-break"
                                [innerHTML]="getSanitizedHTML(question.ask)"></span>
                        </div>
                        <div *ngFor="let answer of question.answers">
                          <div class="collapse show" id="courseTwo"
                               data-bs-parent="#courseAccordion course-details">
                            <div class="pt-2 pb-1 max-width-77"
                                 [ngClass]="{'correct-answer-container': answer.isCorrectAnswer}">
                              <div
                                class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate text-spaces">
                                  <div class="d-flex">
                                    <div class="d-flex">
                                      <div *ngFor="let respondentAnswer of question.respondentAnswers">
                                        <div *ngIf="respondentAnswer.id == answer.id">
                                          <mat-icon *ngIf="answer.isCorrectAnswer" class="green-check">done</mat-icon>
                                          <mat-icon *ngIf="!answer.isCorrectAnswer" class="red-cross">clear</mat-icon>
                                        </div>
                                      </div>
                                      <span class="checkbox me-3" [ngClass]="{
                                            'circle-style': question.questionType === QuestionType.SINGLE_SELECT || question.questionType === QuestionType.TRUE_OR_FALSE,
                                            'rounded-style': question.questionType !== QuestionType.SINGLE_SELECT && question.questionType !== QuestionType.TRUE_OR_FALSE}">
                                      </span>
                                    </div>
                                    <div>
                                      <span class="text-dark text-wrap text-break"
                                            [innerHTML]="getSanitizedHTML(answer.variantName)"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="question.questionType === QuestionType.DESCRIPTIVE">
                        <div class="text-truncate truncate pl-4">
                          <span class="text-dark text-wrap text-break"
                                [innerHTML]="getSanitizedHTML(question.ask)"></span>
                        </div>
                        <br>
                        <div>
                          <div class="answer-container">
                            <div class="answer-left">Answer</div>
                            <div class="break-word-style d-flex">
                              <span class="text-dark width"
                                    [innerHTML]="getSanitizedHTML(question.descriptiveQuestion || 'No answer')"></span>
                              <br><br>
                            </div>
                          </div>
                          <br>
                          <div class="answer-container">
                            <div class="break-word-style d-flex">
                              <span class="text-dark width margin-right" *ngIf="result && question?.descriptiveFeedBack"
                                    [innerHTML]="getSanitizedHTML(question.descriptiveFeedBack)"></span>
                              <span class="text-dark width margin-right"
                                    *ngIf="result && !question?.descriptiveFeedBack"
                                    [innerHTML]="getSanitizedHTML('No feedback has been added')"></span>
                              <div *ngIf="getAdminId() && question.descriptiveQuestion">
                                <label class="form-label">Score</label>
                                <select class="selectpicker" data-width="100%" [(ngModel)]="question.descriptiveScore"
                                        (change)="selectedScore(question)">
                                  <option *ngFor="let score of getScoreRange(question.questionScore)" [value]="score"
                                          [selected]="score === descriptiveScore(question.id)">{{ score }}
                                  </option>
                                </select>
                                <a class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#newFeedback"
                                   (click)="addFeedback(question)">Add feedback</a>
                              </div>
                              <br>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="question.questionType === QuestionType.SHORT_ANSWER">
                        <div class="text-truncate truncate">
                          <span class="text-dark dark flex-grow-1 text-wrap text-break"
                                [innerHTML]="getSanitizedHTML(question.ask)"></span>
                        </div>
                        <div>
                          <div class="answer-container">
                            <div class="possible-answers-list">
                              <div class="question_item_possible_answers_label">&nbsp;Possible
                                answers
                              </div>
                              <div class="answer-variant"
                                   *ngFor="let answer of question.answers">
                                <div class="possible-answer-option text-dark"
                                     [innerHTML]="getSanitizedHTML(answer.variantName)"></div>
                              </div>
                            </div>
                            <div class="possible-answers-list">
                              <div *ngIf="question.shortAnswers; else noAnswer"
                                   class="question_item_possible_answers_label">
                                Answer
                              </div>
                              <ng-template #noAnswer>
                                <div class="question_item_possible_answers_label">
                                  No Answer
                                </div>
                              </ng-template>
                              <div class="answer-variant" *ngIf="question.shortAnswers">
                                <span *ngFor="let answer of question.shortAnswers"
                                      class="possible-answer-option text-dark max-width-short-answer"
                                      [innerHTML]="getSanitizedHTML(answer)"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<div class="modal fade" id="newFeedback" tabindex="-1" role="dialog" aria-labelledby="newFeedbackLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title mb-0" id="newFeedbackLabel">Create feedback</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <angular-editor placeholder="Add your Content here" [config]="configNoInsertImage"
                        [(ngModel)]="editorContent"></angular-editor>
      </div>
      <div class="modal-footer">
        <button type="button" #closebutton class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="me-2 btn btn-primary" (click)="saveFeedback()">Save</button>
      </div>
    </div>
  </div>
</div>
<app-spinner [isLoading]="isLoading"></app-spinner>
