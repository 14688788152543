import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class  CustomMaxLengthValidator {
  static customMaxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValue: string = control.value;
      const value = new DOMParser().parseFromString(controlValue, 'text/html').body.textContent
      if (value) {
        const actualLength = value.length;
        if (actualLength > maxLength) {
          return {'maxlength': {'requiredLength': maxLength, 'actualLength': actualLength}};
        }
      }
      return null;
    }
  }

}
