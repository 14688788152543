import {Component} from '@angular/core';
import {RespondentService} from "../../services/respondent.service";
import {QuizService} from "../../services/quiz.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Quiz} from "../../models/quiz";
import {QuizStatus} from "../../models/quizStatus";
import {catchError, throwError} from "rxjs";
import {RespondentResponse} from "../../models/RespondentResponse";

@Component({
  selector: 'app-quiz-respondent-validation',
  templateUrl: './quiz-respondent-validation.component.html',
  styleUrls: ['./quiz-respondent-validation.component.css']
})
export class QuizRespondentValidationComponent {

  protected readonly QuizStatus = QuizStatus;
  quiz!: Quiz;
  quizId: string | undefined;
  respondentId!: string;
  respondent!: RespondentResponse;
  verificationCode: string = '';
  isVerificationCodeValid: boolean = true;


  constructor(private router: Router, private respondentService: RespondentService, private quizService: QuizService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParams => {
      const respondentId = queryParams['respondentId'];
      if (respondentId) {
        this.respondentId = respondentId;
        this.getRespondent();
      }
    })
    this.route.queryParams.subscribe(queryParams => {
      const quizId = queryParams['quizId'];
      if (quizId) {
        this.quizService.getQuizById(quizId).subscribe(quiz => {
          this.quizId = quiz.id
          this.quiz = quiz;
        });
      }
    })
  }

  getRespondent() {
    this.respondentService.getRespondentIdById(this.respondentId).subscribe((respondent) => {
      this.respondent = respondent;
    });
  }

  onSubmit() {
    if (this.verificationCode.length >= 6) {
      this.respondentService.checkRespondentVerify(this.verificationCode, this.quizId)
        .pipe(
          catchError(error => {
            if (error.status === 404) {
              this.verificationCode = '';
              this.isVerificationCodeValid = false;
            }
            return throwError(error);
          })
        )
        .subscribe(response => {
          if (response.id == this.respondentId) {
            this.router.navigate(['exam'], {
              queryParams: {
                quizId: this.quizId, respondentId: response.id
              }
            });
          }
        });
    }
  }
}
