import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegistrationComponent} from "./registration/registration.component";
import {LoginComponent} from "./login/login.component";
import {SharedComponent} from "./shared/shared.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {AuthGuard} from "./guards/auth.guard";
import {RememberMeGuard} from "./guards/remember-me.guard";
import {AddQuizComponent} from "./quiz/add-quiz/add-quiz.component";
import {TestConfigurationComponent} from "./quiz/test-configuration/test-configuration.component";
import {QuestionManagerComponent} from "./quiz/question-manager/question-manager.component";
import {AddQuizGuard} from "./guards/add-quiz.guard";
import {OtherSettingsComponent} from "./quiz/other-settings/other-settings.component";
import {ResultsTableComponent} from "./quiz/results-table/results-table.component";
import {StartPageComponent} from "./quiz/start-page/start-page.component";
import {ExamComponent} from "./exam/exam.component";
import {RespondentResultComponent} from "./quiz/respondent-result/respondent-result.component";
import {
  QuizRespondentValidationComponent
} from "./quiz/quiz-respondent-validation/quiz-respondent-validation.component";
import {ResultsDatabaseComponent} from "./results-database/results-database.component";
import {RespondentMonitoringComponent} from "./quiz/respondent-monitoring/respondent-monitoring.component";
import {LandingComponent} from "./landing/landing.component";


const routes: Routes = [
  {path: 'exam', component: ExamComponent},
  {path: 'respondent-result', component: RespondentResultComponent},
  {path: 'respondent-monitoring', component: RespondentMonitoringComponent},
  {path: 'signin', component: LoginComponent, canActivate: [RememberMeGuard]},
  {path: 'start/quiz', component: StartPageComponent},
  {path: 'signup', component: RegistrationComponent},
  {path: '', component: LandingComponent},
  {path: 'forgot/password', component: ForgotPasswordComponent, pathMatch: 'full'},
  {path: 'dashboard', component: SharedComponent, canActivate: [AuthGuard]},
  {path: 'quiz-respondent', component: QuizRespondentValidationComponent, pathMatch: 'full'},
  {path: 'results-database', canActivate: [AuthGuard], component: ResultsDatabaseComponent, pathMatch: 'full'},
  {path: 'results/test-result/:quizId/:respondentId', component: RespondentResultComponent},
  {
    path: 'quiz', component: TestConfigurationComponent, canActivate: [AuthGuard], children: [
      {path: 'add', component: AddQuizComponent, canActivate: [AuthGuard, AddQuizGuard], pathMatch: 'full'},
      {path: 'question/manager', canActivate: [AuthGuard], component: QuestionManagerComponent, pathMatch: 'full'},
      {path: 'other/settings', canActivate: [AuthGuard], component: OtherSettingsComponent, pathMatch: 'full'},
      {path: 'results/table', canActivate: [AuthGuard], component: ResultsTableComponent, pathMatch: 'full'},
      {path: 'respondent/monitoring', canActivate: [AuthGuard], component: RespondentMonitoringComponent, pathMatch: 'full'},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
