<main>
  <div class="main-background position-absolute bg-cover bg-center"></div>
  <section class="container d-flex flex-column mb-105">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="mt mt-100 text-center"><span class="it-quiz-text f-col fw-bold fs-lh-itquiz">
        IT&nbsp;<span class="font-outfit col-spec fw-bold fs-lh-itquiz">Q</span>uiz</span></div>
      <div class="card shadow custom-size position-relative br-10">
        <div class="card-body p-6">
            <div class="mb-4">
              <div class="h-36 w-48 text-align-center">
                <span class="sign-in-text f-col fw-bold">Sign In</span><br>
                <span class="col-grey f-col fs-lh fw-normal text-center">Don’t have an account?
                  <a href="/signup" class="ms-1">Sign Up</a></span>
              </div>
            </div>
            <br>
            <form [formGroup]="loginForm" (ngSubmit)="submit()">
              <div class="mb-3">
                <label for="email" class="label-focused form-label ls-01 f-col fs-lh fw-normal"
                       [ngClass]="{
                       'validation': isEmailBlurred && (email?.touched && email?.invalid)}">
                  {{ EMAIL_LABEL }}</label>
                <div class="br-1 border-focused" [ngClass]="{
                       'border-validation': isEmailBlurred && (email?.touched && email?.invalid)}">
                <input formControlName="email"  type="email" id="email"
                       class="media-screen-w form-control-div w-48 h-48 border-0 font-outfit g-1 br-10" name="email" placeholder="Email address" autocomplete="username"
                       [(ngModel)]="admin.email"
                       (focus)="onFocus(EMAIL)"
                       (blur)="onBlur(EMAIL)">
                </div>
                <div *ngIf="isEmailBlurred && email?.errors?.['required']">
                  <div class="validation err-msg font-outfit d-flex fw-normal ls-01 g-1" *ngIf='email?.touched && email?.invalid'>Email address is Required</div>
                </div>
                <div *ngIf="isEmailBlurred && email?.errors?.['email']">
                  <div class="validation err-msg font-outfit d-flex fw-normal ls-01 g-1" *ngIf='email?.touched && email?.invalid'>This email address is invalid</div>
                </div>
              </div>
              <div class="password-div">
              <label for="password" class="label-focused form-label ls-01 f-col fs-lh fw-normal"
                     [ngClass]="{
                     'validation': isPasswordBlurred && password?.errors?.['required']}">
                {{ PASSWORD_LABEL }}
              </label>
              <div class="position-relative">
                <div class="br-1 border-focused"
                     [ngClass]="{
                       'border-validation': isPasswordBlurred && password?.errors?.['required']}">
                <input formControlName="password" [type]="passwordFieldType" id="password"
                       class="media-screen-w form-control-div w-48 h-48 border-0 font-outfit g-1 br-10" placeholder="Enter your password"
                       autocomplete="new-password"
                       (focus)="onFocus(PASSWORD)"
                       (blur)="onBlur(PASSWORD)">
                </div>
                <span class="password-toggle-icon position-absolute">
                    <i [class]="passwordFieldType === PASSWORD ? 'far fa-eye-slash' : 'far fa-eye'"
                       (click)="togglePasswordVisibility()" class="password-icon-style"></i>
                </span>
              </div>
              </div>
              <div *ngIf="isPasswordBlurred && password?.errors?.['required']">
                <div class="validation err-msg font-outfit d-flex fw-normal ls-01 g-1" *ngIf='password?.touched && password?.invalid'>Password is Required</div>
              </div>
              <br>
              <div class="d-lg-flex justify-content-between align-items-center mb-4">
                <div class="form-check d-inline-block">
                  <input type="checkbox" formControlName="rememberMe" name="rememberMe" class="form-check-input checkbox-custom" id="rememberme">
                  <label class="form-check-label fs-lh fw-normal f-col ls-01 text-center remember-me" for="rememberme">Remember&nbsp;me</label>
                </div>
                <div class="d-inline-block">
                  <a routerLink="/forgot/password" class="media-ml col-spec fs-lh fw-normal font-outfit">Forgot password?</a>
                </div>
              </div>
              <div *ngIf="!isAuthenticated" class="wrong-cred-div mt-40 d-flex w-48 h-48 br-10">
                <div class="cancel-div d-inline-block position-relative">
                  <img src="/assets/images/error/cancel.png" alt="cancel" class="cancel-img d-inline-block position-absolute">
                </div>
                <span class="wrong-cred-msg f-col fw-medium ls-01">Wrong email or password</span>
              </div>
              <div class="mt-40">
                <button type="submit" class="media-screen-w bgc-p btn-br-p w-48 h-48 g-1 border-0 d-flex align-items-center justify-content-center box-sizing-border-box"
                        [disabled]="loginForm.invalid"><span class="sign-btn font-outfit col-primary text-center fw-medium">Sign In</span></button>
              </div>
              <div class="mt-40 align-items-center d-flex">
                <hr class="hr-line">
                <span class="f-col">or</span>
                <hr class="hr-line">
              </div>
              <div class="mt-40">
                <button (click)="loginWithGoogle()" class="media-screen-w col-w f-col btn-br-p w-48 h-48 g-1 d-flex align-items-center justify-content-center box-sizing-border-box b-1">
                  <img src="/assets/images/brand/google-icon.png" alt="google-icon" class="google-icon-size">
                  <span class="sign-btn f-col text-center fw-medium">
                      Continue with Google
                  </span>
                </button>
              </div>

              <p *ngIf="isGoogleLoginError" class="text-danger text-center">Google login failed. Please try again.</p>
            </form>
          </div>
        </div>
      </div>
  </section>
</main>
