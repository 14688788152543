import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ResetPassword} from "../models/ResetPassword";
import {confirmPasswordValidator} from "../validators/confirmPassword.validators";
import {AdminLogin} from "../models/adminLogin";
import {HttpErrorResponse} from '@angular/common/http';
import {Admin} from "../models/admin";
import {
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_LABEL,
  EMAIL,
  EMAIL_LABEL,
  NEW_PASSWORD,
  NEW_PASSWORD_LABEL, PASSWORD
} from "../constants/text-constants";
import {EMAIL_NOT_FOUND, SUCCESS_VERIFY_LINK} from "../constants/err-constants";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  message: string = '';
  adminEmail: string = '';
  newPasswordFieldType: string = 'password';
  confirmPasswordFiledType: string = 'password';
  resetPasswordForm!: FormGroup;
  isNewPasswordBlurred = false;
  isConfirmPasswordBlurred = false;
  isEmailBlurred = false;

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) {
    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*\\d).{8,}$')]),
      confirmPassword: new FormControl('', [confirmPasswordValidator('newPassword')]),
    });
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.resetPasswordForm.get('confirmPassword')?.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const token = queryParams['token'];
      if (token) {
        this.auth.getAdminByEmailToken(token).subscribe((admin: Admin) => {
          if (admin) {
            this.adminEmail = admin?.email;
          } else {
            this.router.navigate(['/dashboard']);
          }
        })
      }
    })
  }

  sendEmailMessage(emailForm: NgForm) {
    this.auth.sendMessageForPasswordReset(emailForm.control.value.email).subscribe(
      (response) => {
        this.message = SUCCESS_VERIFY_LINK;
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.message = EMAIL_NOT_FOUND;
        }
      })
    emailForm.reset('');
    setTimeout(() => {
      this.message = ""
    }, 10000);
  }

  resetPassword() {
    const resetPasswordDto = this.resetPasswordForm.getRawValue() as ResetPassword;
    resetPasswordDto.email = this.adminEmail;
    this.auth.resetPassword(resetPasswordDto).subscribe(() => {
      this.resetPasswordForm.reset();
      this.auth.login(this.getAdminFromResetPassword(resetPasswordDto)).subscribe(res => {
        this.auth.setToken(res);
        this.router.navigate(["/dashboard"])
      })
    }, () => {
      this.router.navigate(["/"]);
    })
  }

  togglePasswordVisibility(passwordFiledType: string): void {
    if (passwordFiledType === 'newPasswordFieldType') {
      this.newPasswordFieldType = (this.newPasswordFieldType === 'password') ? 'text' : 'password';
    } else {
      this.confirmPasswordFiledType = (this.confirmPasswordFiledType === 'password') ? 'text' : 'password';
    }
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  getAdminFromResetPassword(resetPassword: ResetPassword): AdminLogin {
    return {
      email: this.adminEmail,
      password: resetPassword.newPassword,
      rememberMe: false
    }
  }

  get hasUppercase() {
    return this.newPassword?.value?.match(/[A-Z]/);
  }

  get hasNumber() {
    return this.newPassword?.value?.match(/\d/);
  }

  get isLongEnough() {
    return this.newPassword?.value?.length >= 8;
  }

  onBlur(field: string) {
    if (field === EMAIL) {
      this.isEmailBlurred = true;
    } else if (field === NEW_PASSWORD) {
      this.isNewPasswordBlurred = true;
    } else if (field === CONFIRM_PASSWORD) {
      this.isConfirmPasswordBlurred = true;
    }
  }

  onFocus(field: string) {
    if (field === EMAIL) {
      this.isEmailBlurred = false;
    } else if (field === NEW_PASSWORD) {
      this.isNewPasswordBlurred = false;
    } else if (field === CONFIRM_PASSWORD) {
      this.isConfirmPasswordBlurred = false;
    }
  }

  protected readonly SUCCESS_VERIFY_LINK = SUCCESS_VERIFY_LINK;
  protected readonly EMAIL_NOT_FOUND = EMAIL_NOT_FOUND;
  protected readonly EMAIL_LABEL = EMAIL_LABEL;
  protected readonly NEW_PASSWORD_LABEL = NEW_PASSWORD_LABEL;
  protected readonly CONFIRM_PASSWORD_LABEL = CONFIRM_PASSWORD_LABEL;
  protected readonly CONFIRM_PASSWORD = CONFIRM_PASSWORD;
  protected readonly NEW_PASSWORD = NEW_PASSWORD;
  protected readonly EMAIL = EMAIL;
  protected readonly PASSWORD = PASSWORD;
}
