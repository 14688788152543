import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-warning-snackbar',
  templateUrl: './warning-snackbar.component.html',
  styleUrls: ['./warning-snackbar.component.css']
})
export class WarningSnackbarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string,
              private snackBarRef: MatSnackBar) { }

  dismissSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
