import { AbstractControl, ValidatorFn } from '@angular/forms';

export function confirmPasswordValidator(newPasswordControlName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const newPassword = control.root.get(newPasswordControlName)?.value;
    const confirmPassword = control.value;

    return newPassword === confirmPassword ? null : { passwordMismatch: true };
  };
}
