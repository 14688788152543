import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css']
})
export class HeadingComponent {
  @Output() menuClicked = new EventEmitter<void>();
  isMenuClicked = false;
  isLinkClicked = false;

  onMenuClick() {
    this.isMenuClicked = !this.isMenuClicked;
    this.isLinkClicked = !this.isMenuClicked;
    this.menuClicked.emit();
  }

  onLinkClick(): void {
    if (this.isMenuClicked) {
      this.isLinkClicked = this.isMenuClicked;
      this.onMenuClick();
    }
  }
}
