import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {Quiz} from "../../models/quiz";
import {QuizService} from "../../services/quiz.service";
import {ActivatedRoute, Router} from "@angular/router";
import {QuizCategoryService} from "../../services/quiz-category.service";
import {QuizCategory} from "../../models/quiz-category";
import {catchError, Observable, switchMap, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AngularEditorConfigsUtil} from "../../util/angular-editor-configs.util";
import {NotificationModel} from "../../models/NotificationModel";
import {CustomMaxLengthValidator} from "../../validators/customMaxLengthValidator";
import {EditorValid} from "../../util/editor-valid";
import {Language} from "../../models/language.enum";
import {QuizStatusCheckService} from "../../services/quiz.status.check.service";


@Component({
  selector: 'app-add-quiz',
  templateUrl: './add-quiz.component.html',
  styleUrls: ['./add-quiz.component.css']
})
export class AddQuizComponent implements OnInit {

  saveQuizForm!: FormGroup
  initialFormValues!: Quiz;
  defaultImgSrcValue: string = './assets/default-ui-image-placeholder-wireframes-600nw-1037719192.webp';
  imgSrc: any = this.defaultImgSrcValue;
  currentImage = this.imgSrc
  selectedImg!: File | null;
  quizCategories!: Array<QuizCategory>
  quizLanguages = [
    {enumType: Language.ENGLISH, type: 'English'},
    {enumType: Language.ARMENIAN, type: 'Armenian'},
    {enumType: Language.RUSSIAN, type: 'Russian'}
  ]
  @ViewChild('closebutton') closeButton: any;
  categoryExistsMessage: string = '';
  editErrors = new Map<string, string>();
  editedQuiz!: Quiz
  config: AngularEditorConfig = {
    editable: true,
    rawPaste: true,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  };
  @ViewChild('categoryForm', {static: false}) categoryForm!: NgForm;
  protected readonly EditorValid = EditorValid;
  readOnly = false;

  constructor(private quizService: QuizService,
              private route: ActivatedRoute,
              private quizCategoryService: QuizCategoryService,
              private router: Router,
              private quizStatusCheckService: QuizStatusCheckService) {
    this.initializeForm()
    this.route.queryParams.subscribe(queryParams => {
      const quizId = queryParams['id'];
      if (quizId) {
        this.quizService.getQuizById(quizId).subscribe(quiz => {
          this.editedQuiz = quiz
          this.initializeForm()
          this.imgSrc = quiz.logoUpload ? quiz.logoUpload : this.defaultImgSrcValue;
          this.currentImage = quiz.logoUpload || this.defaultImgSrcValue;
        })
      }
    })
  }

  get nameValue(): string {
    return this.saveQuizForm.get('name')?.value;
  }

  get descriptionValue(): string {
    return this.saveQuizForm.get('description')?.value
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const quizId = queryParams['id'];
      if (quizId) {
        this.quizService.getQuizById(quizId).subscribe(quiz => {
          this.editedQuiz = quiz;
          this.updateReadOnlyStatus();
          this.initializeForm();
          this.initializeEditorConfig();
        });
      }
    });

    this.quizCategoryService
      .getAllCategories()
      .subscribe((quizCategories) => (this.quizCategories = quizCategories));
  }

  updateReadOnlyStatus(): void {
    this.readOnly = this.quizStatusCheckService.isReadOnly(this.editedQuiz?.status);
  }

  initializeEditorConfig(): void {
    this.config = AngularEditorConfigsUtil.initializeEditorConfig(this.config);
  }

  private initializeForm(): void {
    this.saveQuizForm = new FormGroup({
      name: new FormControl({
        value: this.editedQuiz ? this.editedQuiz.name : '',
        disabled: this.readOnly
      }, [Validators.required, Validators.maxLength(100)]),
      categoryId: new FormControl({
        value: this.editedQuiz ? this.editedQuiz.categoryId : '',
        disabled: this.readOnly
      }, [Validators.required]),
      description: new FormControl({
        value: this.editedQuiz ? this.editedQuiz.description : '',
        disabled: this.readOnly
      }, Validators.compose([CustomMaxLengthValidator.customMaxLengthValidator(100)])),
      language: new FormControl({
        value: this.editedQuiz ? this.editedQuiz.language : '',
        disabled: this.readOnly
      }, [Validators.required])
    });
    this.initialFormValues = this.saveQuizForm.value;
  }

  submit() {
    if (this.saveQuizForm.controls['name'].valid && this.saveQuizForm.controls['categoryId'].valid) {
      const quiz = this.saveQuizForm.getRawValue() as Quiz
      if (this.editedQuiz) {
        this.editQuiz(quiz);
      } else {
        this.saveQuiz(quiz);
      }
    }
  }

  saveQuiz(quiz: Quiz) {
    this.quizService.saveQuiz(quiz).subscribe(res => {
      this.editedQuiz = res;
      if (this.selectedImg) {
        this.uploadImage(res.id);
      } else {
        this.onSuccess();
      }
    })
  }

  private onSuccess(): void {
    this.imgSrc = this.defaultImgSrcValue;
    this.saveQuizForm.reset();
    this.router.navigate(['/quiz/question/manager'], {queryParams: {id: this.editedQuiz.id}});
  }

  private uploadImage(quizId: string): void {
    this.quizService.uploadImage(this.selectedImg, quizId).subscribe(
      () => {
        this.onSuccess();
      },
      (error) => {
        console.error('Error uploading image:', error);
      }
    );
  }

  editQuiz(quiz: Quiz) {
    const editedQuiz = this.prepareEditedQuiz(quiz);
    this.quizService.edit(editedQuiz).pipe(
      catchError((httpError: HttpErrorResponse): Observable<any> => this.handleEditError(httpError))
    ).subscribe(res => {
      if (this.selectedImg) {
        this.uploadImage(res.id)
      } else {
        this.onSuccess()
      }
    })
  }

  private handleEditError(httpError: HttpErrorResponse): Observable<any> {
    if (httpError.error.notifications) {
      httpError.error.notifications.forEach((notification: NotificationModel, index: number) => {
        this.editErrors.set(`Error ${index + 1}:`, notification.text);
      });
    }
    return throwError(() => httpError);
  }

  private prepareEditedQuiz(quiz: Quiz): Quiz {
    quiz.id = this.editedQuiz.id;
    if (this.imgSrc !== this.defaultImgSrcValue && !this.selectedImg) {
      const logoUpload = this.editedQuiz.logoUpload;
      quiz.logoUpload = logoUpload.slice(logoUpload.indexOf('=') + 1)
    }
    return quiz;
  }

  addCategory(categoryForm: NgForm) {
    const categoryName = categoryForm.control.get('category')?.value;
    const categoryExists = this.quizCategories.some(category => category.name === categoryName);
    if (!categoryExists) {
      const category: QuizCategory = {
        name: categoryName
      }
      this.quizCategoryService.save(category)
        .pipe(
          switchMap(() => this.quizCategoryService.getAllCategories())
        )
        .subscribe((quizCategories) => {
          this.closeCategoryModal(categoryForm);
          this.quizCategories = quizCategories;
          this.updateCategoryIdByName(category.name);
        });
    } else {
      this.categoryExistsMessage = "Category name should be unique";
      setTimeout(() => {
        this.categoryExistsMessage = '';
      }, 10000)
    }
  }

  updateCategoryIdByName(categoryName: string): void {
    const newlyAddedCategory = this.quizCategories.find(c => c.name === categoryName);
    if (newlyAddedCategory) {
      this.saveQuizForm.controls['categoryId'].setValue(newlyAddedCategory.id);
    }
  }

  closeCategoryModal(categoryForm: NgForm) {
    categoryForm.reset();
    this.closeButton.nativeElement.click();
  }

  showPreview($event: any) {
    const files = $event.target?.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imgSrc = e.target?.result;
      };
      reader.readAsDataURL(files[0]);
      this.selectedImg = files[0] as File;
    }
  }

  closePreview(logoImage: any) {
    this.imgSrc = this.editedQuiz == undefined ? this.defaultImgSrcValue : (this.editedQuiz.logoUpload && this.imgSrc === this.editedQuiz.logoUpload) ? this.defaultImgSrcValue : this.editedQuiz.logoUpload || this.defaultImgSrcValue;
    this.selectedImg = null;
    logoImage.value = '';
  }

  getFileName(): string {
    return this.imgSrc != this.defaultImgSrcValue ?
      this.selectedImg ? this.selectedImg.name : (this.editedQuiz && this.editedQuiz.logoUpload ?
        this.editedQuiz.logoUpload.slice(this.editedQuiz.logoUpload.indexOf('_') + 1) :
        'No file selected') :
      'No file selected';
  }

  displayErrorMessage(element: HTMLElement | null, message: string): void {
    if (element) {
      element.textContent = message;
    }
  }

  checkFile(event: any): void {
    const file: File = event.target.files[0];
    const allowedFormats: string[] = ['image/png', 'image/jpeg', 'image/jpg'];
    const errorMessage: HTMLElement | null = document.getElementById('errorMessage');

    if (!file) {
      this.displayErrorMessage(errorMessage, '');
      return;
    }
    if (!allowedFormats.includes(file.type)) {
      this.displayErrorMessage(errorMessage, 'Please select a PNG, JPEG, or JPG file.');
      event.target.value = '';
    } else {
      this.displayErrorMessage(errorMessage, '');
      this.showPreview(event);
    }
  }

  resetCategoryInput() {
    this.categoryForm.resetForm();
  }

  isFormModified(): boolean {
    const currentFormValues = this.saveQuizForm.value;
    return this.currentImage !== this.imgSrc ||
      JSON.stringify(currentFormValues) !== JSON.stringify(this.initialFormValues);
  }
}
