<nav class="navbar-vertical navbar">
  <div class="vh-100 sidebar-color"  data-simplebar="">
    <!-- Brand logo -->
    <a class="navbar-brand">
      <img src="./assets/images/brand/logo/logo-icon-white.svg" alt="" class="icon-left">
      <img src="./assets/images/brand/logo/logo-word.svg" alt="" class="text-in-menu text-left">
    </a>
    <!-- Navbar nav -->
    <ul class="navbar-nav flex-column" id="sideNavbar">

      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
          <i class="nav-icon mdi mdi-trello me-2" title="My Tests"></i>
          <span class="text-in-menu">My Tests</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/results-database" routerLinkActive="active">
          <i class="nav-icon fa-solid fa-square-poll-vertical me-2 result-icon" title="Result Database"></i>
          <span class="text-in-menu">Results Database</span>
        </a>
      </li>
      <!-- add other nav-items here -->
      <li class="nav-item">
        <div class="nav-divider"></div>
      </li>
    </ul>
  </div>
</nav>
