import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/environments/environment';
import {QuizCategory} from "../models/quiz-category";

@Injectable({
  providedIn: 'root'
})
export class QuizCategoryService {

  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {
  }

  save(category: QuizCategory): Observable<QuizCategory> {
    let headers = this.createHeaders();
    return this.http.post<QuizCategory>(`${this.apiUrl}/categories/save`, category, {headers})
  }

  getAllCategories(): Observable<any> {
    let headers = this.createHeaders();
    return this.http.get(`${this.apiUrl}/categories`, {headers})
  }

  private createHeaders(): HttpHeaders {
    const token = localStorage.getItem('fb-token');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }
}
