import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {QuestionType} from "../models/QuestionType";
import {DomSanitizer, SafeHtml, SafeUrl} from "@angular/platform-browser";
import {QuestionService} from "../services/question.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Quiz} from "../models/quiz";
import {RespondentService} from "../services/respondent.service";
import {QuestionAnswer} from "../models/QuestionAnswer";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AngularEditorConfigsUtil} from "../util/angular-editor-configs.util";
import {Answer} from "../models/Answer";
import {ResultModel} from "../models/resultModel";
import {concatMap, Observable, of, switchMap, tap} from "rxjs";
import {RespondentAnswerModel} from "../models/RespondentAnswerModel";
import {QuizStatus} from "../models/quizStatus";
import {EditorValid} from "../util/editor-valid";
import {MatSnackBar} from "@angular/material/snack-bar";
import {WarningSnackbarComponent} from "./warning-snackbar/warning-snackbar.component";
import {Alert} from "../models/alert.enum";
import {ImageService} from "../services/image.service";
import {GeneralRequestModel} from "../models/generalRequestModel";

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit, OnDestroy {

  quiz!: Quiz;
  private countdownWorker!: Worker;
  protected readonly QuizStatus = QuizStatus;
  totalQuestionsCount!: number;
  respondentId!: string;
  questionAnswers!: QuestionAnswer;
  currentQuestionNumber: number = 1;
  answer!: string;
  answers: Answer[] = [];
  answersName: string[] = [];
  isChange: boolean = false;
  isMultiChange: boolean = false;
  answerIds: string[] = [];
  isSkip: boolean = true;
  isButtonDisabled: boolean = false
  isButtonPreviousDisabled: boolean = false
  isButtonEndDisabled: boolean = false
  protected readonly EditorValid = EditorValid;
  configNoInsertImage: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  }
  protected readonly QuestionType = QuestionType;
  editorContent: string = '';
  respondentAnswers?: RespondentAnswerModel;
  hours!: number;
  minutes!: number;
  seconds: number = 0;
  answerInAnyOrder: boolean = false;
  duration!: number;
  startTime!: string;
  private visibilityChangeListener!: () => void;
  tabsCount: number = 0;
  originalWindowSize = window.innerWidth;
  previousWindowWidth!: number;
  paginationModel: GeneralRequestModel;

  constructor(private sanitizer: DomSanitizer, private respondentService: RespondentService,
              private questionService: QuestionService, private route: ActivatedRoute,
              private router: Router,
              private _snackBar: MatSnackBar,
              private imageService: ImageService) {
    this.paginationModel = {page: 1, count: 10};
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      switchMap(queryParams => {
        const respondentId = queryParams['respondentId'];
        if (respondentId) {
          this.respondentId = respondentId;
        }
        const quizId = queryParams['quizId'];
        if (quizId) {
          return this.getCurrentQuestionAnswer(quizId, this.respondentId, "");
        } else {
          return of(null);
        }
      })
    ).subscribe(
      (response: QuestionAnswer | null) => {
        if (response && this.isQuestionAnswerValid(response)) {
          this.startTime = response.startTime;
          this.duration = response.quiz.duration;
          this.answerInAnyOrder = response.quiz.isAnswerInAnyOrder;
          this.quiz = response.quiz;
          this.calculateDuration();
          this.loadQuestionsTotalCount();
          this.startWorker();
        }
      }
    );
    this.visibilityChangeListener = () => {
      if (document.visibilityState !== 'visible') {
        this.windowChangeListener();
        this.showWarning();
        this.endQuiz();
      }
    }
    document.addEventListener('visibilitychange', this.visibilityChangeListener);
  }

  ngOnDestroy(): void {
    if (this.countdownWorker) {
      this.countdownWorker.terminate();
    }
    document.removeEventListener('visibilitychange', this.visibilityChangeListener);
  }

  getCurrentQuestionAnswer(quizId: string | undefined, respondentId: string, direction: string): Observable<QuestionAnswer | null> {
    return this.respondentService.getQuestionAnswerByQuizAndByRespondent(quizId, respondentId, direction).pipe(
      tap((response: QuestionAnswer | null) => {
        if (response) {
          if (this.isQuestionAnswerValid(response)) {
            this.questionAnswers = response;
            this.isChange = false;
            this.isMultiChange = false;
            this.answerIds = [];
            this.answers = response.answers;
            this.currentQuestionNumber = response.currentIndex + 1;
            this.getRespondentAnswers(response.id);
          } else {
            this.router.navigate(['start/quiz'], {queryParams: {id: quizId}});
          }
        }
      }),
    );
  }

  private isQuestionAnswerValid(response: QuestionAnswer) {
    return !(response.notifications && response.notifications[0].text === 'You have already ended this quiz')
  }

  loadQuestionsTotalCount(): void {
    this.questionService.totalCount(this.quiz?.id).subscribe(totalCount => {
      this.totalQuestionsCount = totalCount;
    });
  }

  next() {
    if (this.isButtonDisabled) {
      return;
    }
    this.isButtonDisabled = true;
    this.saveResponse().subscribe(() => {
      this.getCurrentQuestionAnswer(this.quiz.id, this.respondentId, "next").subscribe(() => {
        this.editorContent = '';
        this.isButtonDisabled = false;
      });
    });
  }

  previous() {
    if (this.isButtonPreviousDisabled) {
      return;
    }
    this.isButtonPreviousDisabled = true
    if (this.isChange || (this.editorContent && this.editorContent?.length)) {
      this.saveResponse();
    }
    this.getCurrentQuestionAnswer(this.quiz.id, this.respondentId, "prev").subscribe(
      _ => {
        this.editorContent = '';
        this.isButtonPreviousDisabled = false
      }
    )
  }

  saveResponse(): Observable<void> {
    this.processDescriptiveQuestionAndSetSkipFlag();
    this.answersName = [this.editorContent];

    return this.respondentService.saveAnswers(
      this.quiz?.id,
      this.questionAnswers?.id,
      this.respondentId,
      this.isSkip,
      this.answersName,
      this.answerIds
    ).pipe(
      tap(() => {
        this.answerIds = [];
        this.answersName = [];
      })
    );
  }

  processDescriptiveQuestionAndSetSkipFlag() {
    if (this.questionAnswers.questionType === QuestionType.DESCRIPTIVE && this.respondentAnswers && this.respondentAnswers.descriptiveQuestionDto) {
      this.answerIds.push(this.respondentAnswers.descriptiveQuestionDto.id);
    }
    if (this.editorContent) {
      this.isSkip = false;
    }
  }

  getRespondentAnswers(responseId: string | undefined) {
    this.respondentService.getRespondentAnswers(responseId, this.respondentId).subscribe((respondentAnswers: RespondentAnswerModel) => {
      this.respondentAnswers = respondentAnswers;
      this.setEditorContentIfDescriptive()
      this.setEditorContentIfShortAnswer()
    });
  }

  setEditorContentIfDescriptive(): void {
    if (this.respondentAnswers && Object.keys(this.respondentAnswers).length !== 0) {
      if (this.questionAnswers.questionType === QuestionType.DESCRIPTIVE) {
        const descriptiveQuestionDto = this.respondentAnswers.descriptiveQuestionDto;
        this.editorContent = descriptiveQuestionDto.name;
      }
    }
  }

  setEditorContentIfShortAnswer(): void {
    if (this.respondentAnswers && Object.keys(this.respondentAnswers).length !== 0) {
      if (this.questionAnswers.questionType === QuestionType.SHORT_ANSWER) {
        this.respondentAnswers.shortAnswersNames.forEach(shortAnswers => {
          this.editorContent = shortAnswers;
        })
      }
    }
  }

  saveResultAndGet() {
    if (this.isButtonEndDisabled) {
      return;
    }
    this.isButtonEndDisabled = true;
    of(null).pipe(
      concatMap(() => {
        if (this.isChange || (this.editorContent && this.editorContent?.length)) {
          return this.saveResponse();
        }
        return of(null);
      }),
      concatMap(() => {
        return this.respondentService.setEndTime(this.quiz.id, this.respondentId, "ended");
      }),
      concatMap(() => {
        return this.respondentService.saveResultAndGet(this.quiz.id, this.respondentId);
      })
    ).subscribe((result: ResultModel) => {
      if (result) {
        result.questionAnswerNameDtoList.map(questionAnswer => {
          this.questionService.findById(questionAnswer.id).subscribe(question => {
            this.router.navigate(['respondent-result'], {
              queryParams: {
                quizId: this.quiz.id,
                respondentId: this.respondentId,
                isCapitalized: question.isCapitalized
              }
            });
          });
        });
      }
    });
  }

  getRespondentAnswersVariantNames() {
    return this.respondentAnswers?.answers.map(answerOption => answerOption.id);
  }

  calculateDuration() {
    const quizDurationMinutes = this.quiz.duration;
    const durationMilliseconds = quizDurationMinutes * 60 * 1000;
    const currentTime = new Date().toLocaleString('en-US', {timeZone: 'UTC'});
    const startTime = new Date(this.startTime).getTime();
    this.duration = durationMilliseconds - (Date.parse(currentTime) - startTime);
    const durationSeconds = Math.floor(this.duration / 1000);
    this.hours = Math.floor(durationSeconds / 3600);
    this.minutes = Math.floor((durationSeconds % 3600) / 60);
    this.seconds = durationSeconds % 60;
  }

  getSanitizedHTML(htmlContent: string | undefined): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(<string>htmlContent);
  }

  handleCheckboxClick(event: MouseEvent, answer: Answer) {
    const target = event.target as HTMLInputElement;
    this.isChange = true;
    if (!this.isMultiChange) {
      if (this.respondentAnswers && this.respondentAnswers.answers.length > 0) {
        this.answers.forEach(answerDb => {
          if (this.getRespondentAnswersVariantNames()?.includes(answerDb.id)) {
            this.answerIds.push(answerDb.id);
          }
        })
      }
    }
    if (target.checked) {
      this.answerIds.push(answer.id);
    } else {
      const index = this.answerIds.indexOf(answer.id);
      this.answerIds.splice(index, 1);
    }
    this.isSkip = !(this.answerIds.length != 0);
    this.isMultiChange = true;
  }

  handleRadioClick(event: MouseEvent, answer: Answer) {
    this.isChange = true;
    this.answerIds = [];
    this.answerIds = [answer.id];
    this.isSkip = !(this.answerIds.length != 0);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(e: BeforeUnloadEvent) {
    e.preventDefault()
    if (this.isChange) {
      this.saveResponse();
    }
    this.countdownWorker?.terminate();
    this.respondentService.setEndTime(this.quiz.id, this.respondentId, "updated").subscribe();
  }

  private startWorker(): void {
    if (this.questionAnswers.quiz.duration) {
      if (typeof Worker !== 'undefined') {
        this.countdownWorker = new Worker(new URL('./exam.worker', import.meta.url), {type: 'module'});
        this.countDownOnMessageGet();
        this.countdownWorker.postMessage({command: 'start', duration: this.calculateDurationInSeconds()});
      } else {
        this.router.navigate(['start/quiz'], {queryParams: {id: this.quiz.id}})
      }
    }
  }

  private calculateDurationInSeconds(): number {
    return (this.hours * 3600) + (this.minutes * 60) + this.seconds;
  }

  private updateTime(duration: number): void {
    this.hours = Math.floor(duration / 3600);
    this.minutes = Math.floor((duration % 3600) / 60);
    this.seconds = duration % 60;
  }

  private countDownOnMessageGet() {
    this.countdownWorker.onmessage = ({data}) => {
      if (data.command === 'tick') {
        this.updateTime(data.duration);
      } else if (data.command === 'done') {
        this.saveResultAndGet();
      }
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.previousWindowWidth = window.innerWidth;
    if (this.previousWindowWidth !== this.originalWindowSize) {
      return;
    } else {
      this.windowChangeListener();
      this.showWarning();
      this.endQuiz();
    }
  }

  windowChangeListener() {
    if (this.quiz.alert !== Alert.DISABLE) {
      this.respondentService.getRespondentIdById(this.respondentId).subscribe(respondent => {
        this.tabsCount = respondent.tabsCount = ++this.tabsCount;
        this.respondentService.saveRespondent(respondent).subscribe();
      });
    }
  }

  showWarning() {
    if (this.quiz.alert !== Alert.DISABLE) {
      this._snackBar.openFromComponent(WarningSnackbarComponent, {
        duration: 4000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        panelClass: 'mat-toolbar',
      });
    }
  }

  endQuiz() {
    if (this.quiz.alert === Alert.TEST_BLOCK) {
      if (this.tabsCount >= this.quiz.allowedTabNumber) {
        this.saveResultAndGet();
      }
    }
  }

  getSafeUrl(): SafeUrl | null {
    return this.imageService.getSafeUrl(this.quiz?.logoUpload);
  }
}
