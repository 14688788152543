import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Quiz} from "../models/quiz";

@Injectable()
export class AddQuizGuard implements CanActivate {

  quiz: Quiz | undefined;

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === "/quiz/add" && this.quiz?.id) {
      this.router.navigate(['/quiz/add'], {queryParams: {id: this.quiz?.id}});
      return false;
    }
    return true;
  }
}
