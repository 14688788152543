import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {QuizService} from "../../services/quiz.service";
import {QuizStatus} from "../../models/quizStatus";
import {Quiz} from "../../models/quiz";
import {QuestionService} from "../../services/question.service";

@Component({
  selector: 'app-test-configuration',
  templateUrl: './test-configuration.component.html',
  styleUrls: ['./test-configuration.component.css']
})
export class TestConfigurationComponent implements OnInit {

  isTestConfigurationDropdownOpen = true;
  isTestProgressAndResultsDropdownOpen = true;
  hasQuizQuestions: boolean = true;
  isSidebarMenuToggled: boolean = true;
  protected readonly QuizStatus = QuizStatus;
  quizId: string | undefined;
  quizDataStr: Quiz | undefined;

  constructor(private quizService: QuizService,
              private router: Router,
              private route: ActivatedRoute,
              private questionService: QuestionService) {
    this.route.queryParams.subscribe(queryParams => {
      this.quizId = queryParams['id'];
      if (this.quizId) {
        this.quizService.getQuizById(this.quizId!).subscribe(quiz => {
          this.quizDataStr = quiz;
        })
      }
    })
  }

  ngOnInit() {
  }

  toggleTestConfigurationDropdown() {
    this.isTestConfigurationDropdownOpen = !this.isTestConfigurationDropdownOpen;
  }

  toggleTestProgressAndResultsDropdown() {
    this.isTestProgressAndResultsDropdownOpen = !this.isTestProgressAndResultsDropdownOpen;
  }

  setSidebarMenuToggled(toggled: boolean) {
    this.isSidebarMenuToggled = toggled;
  }

  isButtonDisabled(): boolean {
    return !this.quizId;
  }

  changeQuizStatus(): void {
    if (this.quizDataStr) {
      const oldStatus = this.quizDataStr.status;
      const newStatus = oldStatus === QuizStatus.SETUP_IN_PROGRESS || oldStatus === QuizStatus.ENDED ?
        QuizStatus.ACTIVE : QuizStatus.ENDED;
      if (this.quizId) {
        this.quizService.changeStatus(newStatus, this.quizId).subscribe(_ => {
            if (this.quizDataStr) {
              this.quizDataStr.status = newStatus;
            }
            this.router.navigate(['quiz/results/table'], {queryParams: {id: this.quizId}});
          },
          (error) => {
            console.error("Error:", error);
          })
      }
    }
  }

  getQuizStatus(): QuizStatus | null {
    return this.quizDataStr?.status || null;
  }

  onClickActiveButton() {
    this.questionService.totalCount(this.quizId).subscribe(totalQuestions => {
      this.hasQuizQuestions = !!totalQuestions;
    })
  }
}
