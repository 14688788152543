<main>
  <div class="main-background position-absolute bg-cover bg-center"></div>
  <section class="container d-flex flex-column mb-105">
      <div class="media-ml mt text-center"><span class="it-quiz-text f-col fw-bold fs-lh-itquiz">
        IT&nbsp;<span class="font-outfit col-spec fw-bold fs-lh-itquiz">Q</span>uiz</span></div>
      <div class="card shadow custom-size position-relative br-10">
        <div class="card-body p-6 body-size br-10">
          <div>
            <div *ngIf="message === SUCCESS_VERIFY_LINK">
              <div class="media-screen-w verify-link br-10 w-48 h-48 d-flex mb-40 position-relative">
                <img src="../../assets/images/tick/rounded-tick.png" alt="rounded-tick" class="rounded-tick position-absolute">
                <span class="tick-span-ml f-col">{{ message }}</span>
              </div>
            </div>
            <div *ngIf="message === EMAIL_NOT_FOUND">
              <div class="media-screen-w invalidity-link br-10 w-48 h-48 d-flex mb-40 position-relative">
                <img src="/assets/images/error/cancel.png" alt="cancel" class="rounded-tick position-absolute">
                <span class="tick-span-ml f-col">{{ message }}</span>
              </div>
            </div>
            <div *ngIf="!adminEmail" class="h-36 w-48 mb-60">
              <span class="text-center-ml sign-in-text f-col fw-bold ls-02">Forgot Password</span><br>
              <div class="media-screen-w media-screen-ml text-align-center">
                <span class="ls-01 col-grey font-outfit fs-lh fw-normal text-center">Provide the email linked to your account, and we'll send you a password reset link.</span></div>
            </div>
            <div *ngIf="adminEmail" class="h-36 w-48 mb-40">
              <span class="create-pass-ml sign-in-text f-col fw-bold ls-02">Create New Password</span><br>
              <div class="media-screen-w text-align-center">
                <span class="ls-01 col-grey font-outfit fs-lh fw-normal text-center">Fill the form to reset your password.</span></div>
            </div>
            <br>
            <form *ngIf="!adminEmail" #emailForm='ngForm' (ngSubmit)="sendEmailMessage(emailForm)">
              <div class="mb-3">
                <label for="email" class="label-focused form-label ls-01 f-col fs-lh fw-normal">
                <span [ngClass]="{
                  'validation': isEmailBlurred && email.touched && email.invalid}">
                {{ EMAIL_LABEL }}
                </span>
                </label>
                <div class="br-1 border-focused"
                     [ngClass]="{
                   'border-validation': isEmailBlurred && email.touched && email.invalid}">
                  <input type="email" id="email" class="media-screen-w form-control-div fw-normal w-48 h-48 g-1 br-10 border-0 f-col" name="email"
                         placeholder="Enter your email address"
                         ngModel #email="ngModel"
                         required email
                         (focus)="onFocus(EMAIL)"
                         (blur)="onBlur(EMAIL)">
                </div>
                <div *ngIf="isEmailBlurred && email.errors?.['required']">
                  <div class="validation err-msg-p g-1 fw-normal ls-01 font-outfit msg-fs-lh" *ngIf='email.touched && email.invalid'>
                    Email address is Required
                  </div>
                </div>
                <div *ngIf="isEmailBlurred && email.errors?.['email']">
                  <div class="validation err-msg-p g-1 fw-normal ls-01 font-outfit msg-fs-lh" *ngIf='email.touched && email.invalid'>
                    We can’t find this email in our records. Try again.
                  </div>
                </div>
              </div>
              <div class="mt-40">
                <button [disabled]="emailForm.invalid" type="submit"
                        class="media-screen-w reset-link fw-medium ls-02 bgc-p h-48 g-1 btn-br-p w-48 border-0 d-flex text-center align-items-center justify-content-center box-sizing-border-box">
                  <span class="font-outfit col-primary">Send Reset Link</span>
                </button>
              </div>
              <div class="return-to text-center"><span class="col-grey font-outfit fs-lh fw-normal">Return to
                <a routerLink="/signin" class="ms-1 col-spec">Sign In</a></span></div>
            </form>
            <form *ngIf="adminEmail" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
              <div class="mb-3">
                <label for="newPassword" class="label-focused form-label ls-01 f-col fs-lh fw-normal">
                  <span [ngClass]="{
                  'validation': isNewPasswordBlurred && (newPassword?.invalid && newPassword?.touched)}">
                    {{ NEW_PASSWORD_LABEL }}</span>
                </label>
                <div class="position-relative">
                  <div class="br-1 border-focused" [ngClass]="{
                  'border-validation': isNewPasswordBlurred && (newPassword?.invalid && newPassword?.touched)}">
                    <input id="newPassword" placeholder="Enter new password"
                           [type]="newPasswordFieldType"
                           class="media-screen-w form-control-div fw-normal w-48 h-48 g-1 br-10 border-0 f-col" name="newPassword"
                           formControlName="newPassword"
                           (focus)="onFocus(NEW_PASSWORD)"
                           (blur)="onBlur(NEW_PASSWORD)">
                  </div>
                  <span class="password-toggle-icon position-absolute">
                    <i [class]="newPasswordFieldType === PASSWORD ?  'far fa-eye-slash' : 'far fa-eye'"
                       (click)="togglePasswordVisibility('newPasswordFieldType')" class="password-icon-style"></i>
                  </span>
                </div>
                <div class="ul-li-div w-48"
                     *ngIf="!isNewPasswordBlurred || (isNewPasswordBlurred && !(resetPasswordForm.get('newPassword')!.invalid && resetPasswordForm.get('newPassword')!.touched))">
                  <ul class="d-flex list-unstyled p-0 m-0">
                    <li [ngClass]="{ 'valid': hasUppercase }">
                      <span *ngIf="hasUppercase">
                        <img src="../../assets/images/tick/tick.png" alt="tick" class="tick">
                      </span>
                      <span *ngIf="!hasUppercase">&#8226;</span>
                      <span class="three-msg fw-normal font-outfit ls-01 msg-fs-lh">At least 1 uppercase</span>
                    </li>
                    <li [ngClass]="{ 'valid': hasNumber }">
                      <span *ngIf="hasNumber">
                        <img src="../../assets/images/tick/tick.png" alt="tick" class="tick">
                      </span>
                      <span *ngIf="!hasNumber">&#8226;</span>
                      <span class="three-msg fw-normal font-outfit ls-01 msg-fs-lh">At least 1 number</span>
                    </li>
                    <li [ngClass]="{ 'valid': isLongEnough }">
                      <span *ngIf="isLongEnough">
                        <img src="../../assets/images/tick/tick.png" alt="tick" class="tick">
                      </span>
                      <span *ngIf="!isLongEnough">&#8226;</span>
                      <span class="three-msg fw-normal font-outfit ls-01 msg-fs-lh">At least 8 characters</span>
                    </li>
                  </ul>
                </div>
                <div *ngIf="isNewPasswordBlurred && newPassword?.errors?.['pattern']">
                  <div class="media-screen-err validation err-msg-p w-452 g-1 fw-normal ls-01 font-outfit msg-fs-lh" *ngIf='newPassword?.invalid && newPassword?.touched'>
                    <span>Your&nbsp;password&nbsp;is&nbsp;wrong.&nbsp;Remember&nbsp;that&nbsp;it&nbsp;must&nbsp;contain&nbsp;at&nbsp;least&nbsp;<b>8&nbsp;characters,&nbsp;1&nbsp;uppercase</b>&nbsp;and&nbsp;<b>1&nbsp;number</b></span>
                  </div>
                </div>
                <div *ngIf="isNewPasswordBlurred && newPassword?.errors?.['required']">
                  <div class="validation err-msg-p g-1 fw-normal ls-01 font-outfit msg-fs-lh"
                       *ngIf='newPassword?.invalid && newPassword?.touched'>
                    Password is Required
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="confirmPassword"
                       class="label-focused form-label ls-01 f-col fs-lh fw-normal">
                  <span [ngClass]="{
                  'validation': isConfirmPasswordBlurred && (confirmPassword?.invalid && confirmPassword?.touched)}">
                    {{ CONFIRM_PASSWORD_LABEL }}</span>
                </label>
                <div class="position-relative">
                  <div class="br-1 border-focused" [ngClass]="{
                  'border-validation': isConfirmPasswordBlurred && (confirmPassword?.invalid && confirmPassword?.touched)}">
                    <input formControlName="confirmPassword"
                           [type]="confirmPasswordFiledType"
                           id="confirmPassword"
                           class="media-screen-w form-control-div fw-normal w-48 h-48 g-1 br-10 border-0 f-col"
                           placeholder="Confirm Password"
                           (focus)="onFocus(CONFIRM_PASSWORD)"
                           (blur)="onBlur(CONFIRM_PASSWORD)">
                    <span class="password-toggle-icon position-absolute">
                    <i [class]="confirmPasswordFiledType === PASSWORD ?  'far fa-eye-slash' : 'far fa-eye'"
                       (click)="togglePasswordVisibility('confirmPasswordFiledType')" class="password-icon-style"></i>
                  </span>
                  </div>
                </div>
                <div *ngIf="isConfirmPasswordBlurred && confirmPassword?.errors?.['passwordMismatch']">
                  <span [ngClass]="{'validation err-msg g-1 fw-normal ls-01 font-outfit msg-fs-lh': (confirmPassword?.invalid && confirmPassword?.touched)}">
                    Password do not match. Try again.
                  </span>
                </div>
              </div>
              <div class="mt-40">
                <button [disabled]="resetPasswordForm.invalid" type="submit"
                        class="media-screen-w reset-link fw-medium ls-02 bgc-p g-1 btn-br-p w-48 h-48 border-0 d-flex text-center align-items-center justify-content-center box-sizing-border-box">
                  <span class="font-outfit col-primary">Reset Password</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
  </section>
</main>
