<div class="bs-stepper-content mt-5">
  <form [formGroup]="saveQuizForm">
    <div id="test-l-1" role="tabpanel" class="bs-stepper-content " aria-labelledby="courseFormtrigger1">
      <!-- Card -->
      <div class="card mb-3 ">
        <div class="card-header border-bottom px-4 py-3">
          <h4 class="mb-0">Basic Information</h4>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div class="mb-3">
            <label for="name" class="form-label">Test name<span class="text-danger">*</span></label>
            <input id="name" class="form-control is-invalid"
                   formControlName="name"
                   [ngClass]="{'is-invalid': saveQuizForm.get('name')!.hasError('required') && saveQuizForm.get('name')!.touched}"
                   type="text"
                   placeholder="Test Name">
            <div class="validation"
                 *ngIf="saveQuizForm.get('name')!.hasError('maxlength') && (saveQuizForm.get('name')!.touched || saveQuizForm.get('name')!.dirty)">
              Maximum character count is 100
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Language<span class="text-danger">*</span></label>
            <div class="d-flex ">
              <select class="form-select d-inline h-75 w-lg-75"
                      [ngClass]="{'is-invalid': saveQuizForm.get('language')!.hasError('required') && saveQuizForm.get('language')!.touched}"
                      data-width="100%"
                      name="language"
                      formControlName="language">
                <option value="">Select Language</option>
                <option *ngFor="let language of quizLanguages" [value]="language.enumType">{{ language.type }}</option>
              </select>
            </div>
            <small>Help people find your tests by choosing
              categories that represent your test.</small>
          </div>
          <div class="mb-3">
            <label class="form-label">Test category<span class="text-danger">*</span></label>
            <div class="d-flex ">
              <select class="form-select d-inline h-75 w-lg-75"
                      [ngClass]="{'is-invalid': saveQuizForm.get('categoryId')!.hasError('required') && saveQuizForm.get('categoryId')!.touched}"
                      data-width="100%"
                      name="categoryId"
                      formControlName="categoryId">
                <option value="">Select category</option>
                <option *ngFor="let category of quizCategories" [value]="category.id">{{ category.name }}</option>
              </select>
              <a class="btn btn-primary d-inline h-75 mx-3"
                 [ngClass]="{'disabled-link': readOnly}"
                 (click)="!readOnly && resetCategoryInput()"
                 [attr.data-bs-toggle]="readOnly ? null : 'modal'"
                 [attr.data-bs-target]="readOnly ? null : '#newCategory'">
                Add New Category
              </a>
            </div>
            <small>Help people find your tests by choosing
              categories that represent your test.</small>
          </div>
          <div class="col-12 mb-4">
            <h5 class="mb-3">Test logo</h5>
            <div class="custom-file-input-container">
              <div class="fallback" [ngClass]="{'disabled': readOnly}">
                <i *ngIf="selectedImg || imgSrc !== defaultImgSrcValue && !readOnly"
                   class="fa fa-window-close position-absolute top-0 end-0 fs-3"
                   aria-hidden="true" (click)="closePreview(logoImage)"></i>
                <img [src]="imgSrc" class="form-control img-fluid img-preview">
                <input id="logoImage" name="logoUpload" #logoImage type="file" class="form-control d-none"
                       accept="image/png, image/jpeg, image/jpg" (change)="checkFile($event)">
                <label for="logoImage" class="btn btn-light font-weight-normal">Choose file</label>
                <label for="logoImage" class="btn btn-outline-secondary font-weight-normal">{{ getFileName() }}</label>
                <div id="errorMessage" style="color: red;"></div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label mb-3">Test Description &nbsp;<span class="text-muted font-weight-normal">(optional notes visible for you)</span></label>
            <angular-editor [config]="config" placeholder="Add your Content here"
                            formControlName="description" [ngClass]="{'disableClick': readOnly}"></angular-editor>
            <div class="validation"
                 *ngIf="saveQuizForm.get('description')!.hasError('maxlength') && (saveQuizForm.get('description')!.touched || saveQuizForm.get('description')!.dirty)">
              Maximum character count is 200
            </div>
          </div>
          <div *ngIf="editErrors.get('CategoryNotFoundError')">
            {{ editErrors.get('CategoryNotFoundError') }}
          </div>
          <div *ngIf="editErrors.get('QuizNotFoundError')">
            {{ editErrors.get('QuizNotFoundError') }}
          </div>
        </div>
      </div>
      <!-- Button -->
      <div class="container position-relative justify-content-end d-flex p-0">
        <button type="button"
                [disabled]="!isFormModified() || this.saveQuizForm.invalid ||
                !EditorValid.isEditorValid(descriptionValue) ||
                !EditorValid.isEditorValid(nameValue)"
                class="btn btn-primary end-0" (click)="submit()">
          {{ editedQuiz ? 'Save changes' : 'Create' }}
        </button>
      </div>
    </div>
  </form>
</div>

<div class="modal fade" id="newCategory" tabindex="-1" role="dialog" aria-labelledby="newCatgoryLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title mb-0" id="newCatgoryLabel">
          Create New Category
        </h4>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form #categoryForm='ngForm' (ngSubmit)="addCategory(categoryForm)">
          <div class="mb-3 mb-2">
            <label class="form-label" for="title">Title<span class="text-danger">*</span></label>
            <input type="text"
                   name="category"
                   class="form-control"
                   [ngClass]="{'is-invalid': categoryName.touched && categoryName.errors?.['required']}"
                   placeholder="Write a Category"
                   ngModel
                   #categoryName='ngModel'
                   id="title" required>
            <div class="validation" *ngIf="categoryExistsMessage">{{ categoryExistsMessage }}</div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit" [disabled]="categoryForm.invalid || readOnly" class="btn btn-primary">Add New Category</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

