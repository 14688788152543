<main>
  <section class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <!-- Card -->
        <div class="card shadow">
          <!-- Card body -->
          <div class="card-body p-6">
            <div class="mb-4">
              <a href="../index.html"><img src="../assets/images/brand/logo/logo-icon.svg" class="mb-4" alt="logo-icon"></a>
              <div *ngIf="message" [class]="{'alert-danger' : message === 'This email address doesn\'t exist'}"
                   class="alert alert-success mt-4">
                {{ message }}
              </div>
              <h1 class="mb-1 fw-bold">Forgot Password</h1>
              <p>Fill the form to reset your password.</p>
            </div>
            <!-- Form -->
            <form *ngIf="!adminEmail" #emailForm='ngForm' (ngSubmit)="sendEmailMessage(emailForm)">
              <!-- Email -->
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" id="email" class="form-control" name="email" placeholder="Enter Your Email"
                       ngModel #email='ngModel'
                       required email>
                <div *ngIf=" email.errors?.['required']">
                  <div class="validation" *ngIf='(email.touched || email.dirty) && email.invalid'> Email address is
                    Required
                  </div>
                </div>
                <div *ngIf=" email.errors?.['email']">
                  <div class="validation" *ngIf='(email.touched || email.dirty) && email.invalid'>
                    This email address is invalid
                  </div>
                </div>
              </div>
              <!-- Button -->
              <div class="mb-3 d-grid">
                <button [disabled]="emailForm.invalid" type="submit" class="btn btn-primary">
                  Send Resest Link
                </button>
              </div>
              <span>Return to <a routerLink="/">sign in</a></span>
            </form>

            <!--Reset Password form , will display when admin from email will click reset password button  and send email as a query param-->
            <form *ngIf="adminEmail" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
              <!-- New Password -->
              <div class="mb-3">
                <label for="newPassword" class="form-label">New Password</label>
                <div class="position-relative">
                  <input id="newPassword" placeholder="Enter New Password" [type]="newPasswordFieldType"
                         class="form-control" name="newPassword"
                         formControlName="newPassword"
                  >
                  <span class="password-toggle-icon">
                  <i [class]="newPasswordFieldType === 'password' ? 'far fa-eye-slash' : 'far fa-eye'"
                     (click)="togglePasswordVisibility('newPasswordFieldType')"></i>
                </span>
                </div>
                <div *ngIf=" newPassword?.errors?.['required']">
                  <div class="validation" *ngIf='newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)'>
                    Please enter a new
                    password
                  </div>
                </div>
                <div *ngIf=" newPassword?.errors?.['pattern']">
                  <div class="validation" *ngIf='newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)'>
                    Must contain at
                    least one uppercase letter,one numeric value and should not be less than 8 characters
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <!-- Confirm Password -->
                <label for="confirmPassword" class="form-label">Confirm Password</label>
                <div class="position-relative">
                  <input type="email" id="confirmPassword" [type]="confirmPasswordFiledType" class="form-control"
                         name="confirmPassword" formControlName="confirmPassword"
                         placeholder="Confirm Password">
                  <span class="password-toggle-icon">
                  <i [class]="confirmPasswordFiledType === 'password' ? 'far fa-eye-slash' : 'far fa-eye'"
                     (click)="togglePasswordVisibility('confirmPasswordFiledType')"></i>
                  </span>
                </div>
                <div *ngIf="confirmPassword?.errors?.['passwordMismatch']">
                  <div class="validation"
                       *ngIf='confirmPassword?.invalid && (confirmPassword?.touched || confirmPassword?.dirty) '>
                    Passwords do not match
                  </div>
                </div>

              </div>
              <!-- Button-->
              <div class="mb-3 d-grid">
                <button [disabled]="resetPasswordForm.invalid " type="submit" class="btn btn-primary">
                  Reset Password
                </button>
              </div>
            </form>
            <hr class="my-4">
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
