<ng-container *ngIf="data.showDuplicateDialog">
  <form class="example-form">
    <h3 mat-dialog-title>Test duplication</h3>
    <div mat-dialog-content>
      <mat-form-field class="example-full-width">
        <mat-label>Duplicate name</mat-label>
        <input matInput [(ngModel)]="duplicateName" name="newName"  #duplicateInput="ngModel" required>
      </mat-form-field>
      <div class="validation mt-n3"
           *ngIf="duplicateName.length > 100 || duplicateInput.invalid">
        Maximum character count is 100․
      </div>
    </div>
    <div mat-dialog-actions class="float-end mb-3">
      <button class="btn btn-light-secondary" (click)="onNoClick()">Cancel</button>
      <button class="btn btn-primary mx-2" [mat-dialog-close]="duplicateName" (click)="duplicate()" [disabled]="duplicateName.length > 100 || duplicateInput.invalid">Duplicate</button>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="data.showDeleteDialog">
  <h2 mat-dialog-title>Deleting test and results</h2>
  <div mat-dialog-content>Are you sure you want to permanently delete selected test and collected results
    (respondents will be unable to access them as well)?
  </div>
  <div mat-dialog-actions class="float-end">
    <button class="btn btn-light-secondary" (click)="onNoClick()">Cancel</button>
    <button class="btn btn-primary mx-2" [mat-dialog-close]="true" (click)="deleteQuiz()">Delete</button>
  </div>
</ng-container>
