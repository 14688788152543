import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {GeneralRequestModel} from "../models/generalRequestModel";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {EventEmitter, inject, Injectable, Output} from '@angular/core';
import {ApiResponse} from "../models/ApiResponse";
import {DescriptiveQuestionFeedBack} from "../models/DescriptiveQuestionFeedBack";
import {ResultModel} from "../models/resultModel";

@Injectable({
  providedIn: "root",
})
export class ResultService {

  apiUrl = environment.apiUrl;
  http: HttpClient = inject(HttpClient);
  @Output() updatedEvent = new EventEmitter();

  private createHeaders(): HttpHeaders {
    const token = localStorage.getItem('fb-token');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  getAllResultsByAdminIdAndQuizId(adminId: string, quizId: string, generalRequest: GeneralRequestModel): Observable<ApiResponse<ResultModel>> {
    let params = new HttpParams()
      .set('page', generalRequest.page)
      .set('count', generalRequest.count)
      .set('adminId', quizId)
      .set('quizId', adminId)
    if (generalRequest.sortDirection) {
      params = params.set('sortDirection', generalRequest.sortDirection);
    }
    return this.http.get<ApiResponse<ResultModel>>(`${this.apiUrl}/admin/quiz-results`, {params})
  }

  getResultsByAdminId(adminId: string | null, generalRequestModel: GeneralRequestModel, sortField: string): Observable<ApiResponse<ResultModel>> {
    let headers = this.createHeaders();
    let params: HttpParams = new HttpParams()
      .set("page", generalRequestModel.page)
      .set("count", generalRequestModel.count)
      .set("sortField", sortField)
    if (generalRequestModel.sortDirection) {
      params = params.set('sortDirection', generalRequestModel.sortDirection);
    }
    return this.http.get<ApiResponse<ResultModel>>(`${this.apiUrl}/admin/quiz-results/${adminId}`, {headers, params});
  }

  deleteTestResults(resultIds: string[]): Observable<String[]> {
    let httpHeaders = this.createHeaders();
    let params = new HttpParams();
    params = params.append('resultIds', resultIds.join(", "));
    return this.http.delete<String[]>(`${this.apiUrl}/results/test-results/delete`,
      {
        params,
        headers: httpHeaders
      });
  }

  needToUpdate() {
    this.updatedEvent.emit();
  }

  searchResults(adminId: string | null, generalRequestModel: GeneralRequestModel,
                search: string): Observable<ApiResponse<ResultModel>> {
    let headers = this.createHeaders();
    let params: HttpParams = new HttpParams()
      .set("page", generalRequestModel.page)
      .set("count", generalRequestModel.count)
      .set('search', search)
    return this.http.get<ApiResponse<ResultModel>>(`${this.apiUrl}/admin/quiz-results/${adminId}`, {headers, params});
  }

  getResultsByAdminIdQuizIdAndCategoryId(adminId: string | null, generalRequestModel: GeneralRequestModel,
                                         quizId: string, categoryId: string): Observable<ApiResponse<ResultModel>> {
    let headers = this.createHeaders();
    let params: HttpParams = new HttpParams()
      .set("page", generalRequestModel.page)
      .set("count", generalRequestModel.count);
    if (quizId) {
      params = params.set('quizId', quizId);
    }
    if (categoryId) {
      params = params.set('categoryId', categoryId);
    }
    return this.http.get<ApiResponse<ResultModel>>(`${this.apiUrl}/admin/quiz-results/${adminId}`, {headers, params});
  }

  getAllCategoriesByAdminId(adminId: string | null): Observable<any> {
    let headers = this.createHeaders();
    return this.http.get(`${this.apiUrl}/categories/${adminId}`, {headers})
  }


  setFeedback(feedback: DescriptiveQuestionFeedBack): Observable<void> {
    let headers = this.createHeaders();
    return this.http.post<void>(`${this.apiUrl}/results/questions/descriptive/feedback/`, feedback, {headers})
  }

  getTestResult(quizId: string, respondentId: string): Observable<ResultModel> {
    return this.http.get<ResultModel>(`${this.apiUrl}/results/test-result/${quizId}/${respondentId}`);
  }
}
