import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import {QuizForOtherSettingsModel} from "../models/quizForOtherSettingsModel";


@Injectable({
  providedIn: 'root'
})
export class OtherSettingsService {
  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {
  }

  saveOtherSettings(quizForOtherSettingsDto: QuizForOtherSettingsModel): Observable<any> {
    let headers = this.createHeaders();
    return this.http.patch(`${this.apiUrl}/quizzes`, quizForOtherSettingsDto, {headers})
  }

  getQuizSettings(quizId: string | null): Observable<any> {
    let headers: HttpHeaders = this.createHeaders();
    return this.http.get(`${this.apiUrl}/quizzes/settings?id=${quizId}`, {headers});
  }

  private createHeaders(): HttpHeaders {
    const token = localStorage.getItem('fb-token');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }
}
