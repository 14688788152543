<section class="justify-content z-index-11 h-8 w-100 position-fixed d-flex fixed-header-bg">
  <div class="it-quiz-div d-flex h-8 w-1240">
    <div class="z-index-quiz"><a href="/"><span class="it-quiz-text f-col fw-bold fs-lh">
        IT&nbsp;<span class="font-outfit col-spec fw-bold fs-lh">Q</span>uiz</span></a></div>
    <div class="menu-div">
      <img src="/assets/images/responsive-landing/menu.png" alt="menu" class="menu-icon"
           (click)="onMenuClick()" *ngIf="!isMenuClicked || (isLinkClicked && isMenuClicked)">
      <img src="/assets/images/dot/close-menu.png" alt="close-menu" class="close-menu"
           (click)="onMenuClick()" *ngIf="isMenuClicked && !isLinkClicked">
    </div>
    <div *ngIf="!isMenuClicked" class="header-cards d-inline-block d-flex justify-content-between f-col h-4">
      <span class="product">Product</span>
      <a href="#for-every-need" class="text-decoration-none f-col"><span
        class="who-for">Who&nbsp;it's&nbsp;for</span></a>
      <a href="#fit-needs" class="text-decoration-none f-col"><span class="pricing">Pricing</span></a>
      <a href="/contact-us" class="text-decoration-none f-col"><span class="contact-us">Contact&nbsp;Us</span></a>
    </div>
    <div *ngIf="!isMenuClicked" class="header-inner position-absolute d-flex justify-content-end w-1240">
      <div class="eng-div eng-w-50 br-5 h-4 d-flex justify-content-center align-items-center">
            <span class="f-col fw-medium ls-02 fs-16-lh-20 d-inline-block text-center cursor-pointer">
              &nbsp;Eng
            </span>
      </div>
      <div class="login-div-w">
        <a routerLink="/signin">
          <button type="submit"
                  class="login-btn bg-wh bgc-p bg-wh btn-cg h-4 login-div-w d-flex align-items-center justify-content-center box-sizing-border-box">
            <span class="f-col text-center fw-medium">Login</span>
          </button>
        </a>
      </div>
      <div class="start-trial-div">
        <a routerLink="/signup">
          <button type="submit"
                  class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 btn-wg d-flex align-items-center justify-content-center">
        <span class="f-col text-center fw-medium">
          Start&nbsp;Free&nbsp;Trial
        </span>
          </button>
        </a>
      </div>
    </div>
  </div>
</section>
<div *ngIf="isMenuClicked" class="text-center position-absolute">
  <div class="menu-main">
    <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-menu">
    <div class="d-inline-block f-col h-4">
      <div class="d-inline-block"><span class="product fs-24-lh-36-mobile">Product</span></div>
      <img src="/assets/images/arrow/arrow-outward.png" alt="arrow-outward" class="arrow-outward">
    </div>
    <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-menu-mobile">
    <div (click)="onLinkClick()">
      <a href="#for-every-need" class="text-decoration-none f-col"><span
        class="who-for fs-24-lh-36-mobile">Who&nbsp;it's&nbsp;for</span></a>
      <img src="/assets/images/arrow/arrow-outward.png" alt="arrow-outward" class="arrow-outward">
    </div>
    <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-menu-mobile">
    <div (click)="onLinkClick()">
      <a href="#fit-needs" class="text-decoration-none f-col"><span
        class="pricing fs-24-lh-36-mobile">Pricing</span></a>
      <img src="/assets/images/arrow/arrow-outward.png" alt="arrow-outward" class="arrow-outward">
    </div>
    <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-menu-mobile">
    <div>
      <a href="/contact-us" class="text-decoration-none f-col"><span class="contact-us fs-24-lh-36-mobile">Contact&nbsp;Us</span></a>
      <img src="/assets/images/arrow/arrow-outward.png" alt="arrow-outward" class="arrow-outward">
    </div>
    <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-menu-mobile">
    <div class="start-trial-button">
      <a routerLink="/signup">
        <button type="submit"
                class="trial-style-p fs-16-lh-24 ls-02 bgc-p border-0 btn-start-trial-menu start-trial-p d-flex align-items-center justify-content-center">
        <span class="f-col text-center fw-medium">
          Start&nbsp;Free&nbsp;Trial
        </span>
        </button>
      </a>
    </div>
    <div class="login-menu login-div-w">
      <a routerLink="/signin">
        <button type="submit"
                class="login-btn bg-wh bgc-p bg-wh h-4 login-div-w d-flex align-items-center justify-content-center box-sizing-border-box">
          <span class="f-col text-center fw-medium fs-16-lh-24 ls-02">Login</span>
        </button>
      </a>
    </div>
    <div
      class="eng-menu eng-w-50 br-5 h-4 d-flex justify-content-center align-items-center position-absolute">
            <span class="f-col fw-medium ls-02 fs-16-lh-24 d-inline-block text-center">
              &nbsp;Eng
            </span>
    </div>
  </div>
</div>
