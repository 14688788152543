<main>
  <section class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <div class="card shadow ">
          <div class="card-body p-6">
            <div class="mb-4">
              <a href="../index.html"><img src="assets/images/brand/logo/logo-icon.svg" class="mb-4" alt="logo-icon"></a>
              <h1 class="mb-1 fw-bold">Sign in</h1>
              <span>Don’t have an account? <a href="/signup" class="ms-1">Sign up</a></span>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="submit()">
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input formControlName="email"  type="email" id="email" class="form-control" name="email" placeholder="Email address"  autocomplete="username"
                       [(ngModel)]="admin.email">
                <div *ngIf=" email?.errors?.['required']">
                  <div class="validation" *ngIf='(email?.touched || email?.dirty) && email?.invalid'> Email address is Required</div>
                </div>
                <div *ngIf=" email?.errors?.['email']">
                  <div class="validation" *ngIf='(email?.touched || email?.dirty) && email?.invalid'> This email address is invalid</div>
                </div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <div class="position-relative">
                  <input formControlName="password" [type]="passwordFieldType" id="password" class="form-control" placeholder="Enter your password" autocomplete="new-password">
                  <span class="password-toggle-icon">
                    <i [class]="passwordFieldType === 'password' ? 'far fa-eye-slash' : 'far fa-eye'"
                    (click)="togglePasswordVisibility()"></i>
                </span>
                </div>
                <div *ngIf="password?.errors?.['required']">
                  <div class="validation" *ngIf='(password?.touched || password?.dirty) && password?.invalid'>Password is required.</div>
                </div>
              </div>
              <div class="d-lg-flex justify-content-between align-items-center mb-4">
                <div class="form-check">
                  <input type="checkbox" formControlName="rememberMe" name="rememberMe" class="form-check-input" id="rememberme">
                  <label class="form-check-label" for="rememberme">Remember me</label>
                </div>
                <div>
                  <a routerLink="/forgot/password">Forgot your password?</a>
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">Sign in</button>
                </div>
              </div>
              <div *ngIf="!isAuthenticated" class="alert alert-danger mt-4">
                Wrong email or password
              </div>
              <hr class="my-4">
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
