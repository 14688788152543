import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {Admin} from "../models/admin";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminLogin} from "../models/adminLogin";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm!: FormGroup
  admin: Admin
  isAuthenticated: boolean = true;
  passwordFieldType: string = 'password';

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private elementRef: ElementRef,
              private router: Router) {
    this.admin = {} as Admin;
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*\\d).{8,}$')]),
      rememberMe: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const emailToken = queryParams['emailToken'];
      if (emailToken) {
        this.authService.confirmRegistration(emailToken).subscribe(res => {
          this.admin = res
        })
      }
    })
  }

  submit() {
    if (this.loginForm.invalid) {
      return;
    } else {
      const admin = this.loginForm.getRawValue() as AdminLogin
      admin.password = admin.password.trim();
      admin.email = admin.email.toLowerCase().trim();
      this.authService.login(admin).subscribe(res => {
          this.authService.setToken(res);
          this.router.navigate(["/dashboard"])
        },
        (error) => {
          this.isAuthenticated = false;
          setTimeout(() => {
            this.isAuthenticated = true
          }, 10000);
        })
    }
  }

  ngAfterViewInit(): void {
    const inputs = this.elementRef.nativeElement.querySelectorAll('input');
    inputs.forEach((input: any) => {
      input.autocomplete = 'off';
    });
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  togglePasswordVisibility(): void {
    this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
  }
}
