import {AngularEditorConfig} from "@kolkov/angular-editor";

export class AngularEditorConfigsUtil {

  static toolbarHiddenButtons_undoRedo = [
    'undo',
    'redo',
  ];

  static toolbarHiddenButtons = [
    'textColor',
    'backgroundColor',
    'customClasses',
    'link',
    'unlink',
    'insertImage',
    'insertVideo',
    'insertHorizontalRule',
    'removeFormat',
    'toggleEditorMode'
  ]

  static initializeEditorConfig(baseConfig: AngularEditorConfig): AngularEditorConfig {
    return {
      ...baseConfig,
    };
  }
}
