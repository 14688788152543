import {EventEmitter, Injectable, Output} from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class SharedService {
    @Output() updatedEvent = new EventEmitter();

    needToUpdate() {
        this.updatedEvent.emit();
    }
}
