import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/environments/environment';
import {Quiz} from "../models/quiz";
import {GeneralRequestModel} from "../models/generalRequestModel";
import {AuthService} from "./auth.service";
import {QuizStatus} from "../models/quizStatus";
import {ApiResponse} from "../models/ApiResponse";
import {QuizDataModel} from "../models/quizDataModel";
import {QuizGenerationRequest} from "../models/quiz-generation-request";

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient, private authService: AuthService) {
  }

  saveQuiz(quiz: Quiz): Observable<any> {
    const userId = this.authService.getCurrentUserId();
    if (typeof userId === "string") {
      quiz.userId = userId;
    }

    let headers = this.createHeaders();
    return this.http.post(`${this.apiUrl}/quizzes/save`, quiz, {headers})
  }

  edit(editedQuiz: Quiz): Observable<any> {
    let headers = this.createHeaders();
    return this.http.put(`${this.apiUrl}/quizzes/${editedQuiz.id}`, editedQuiz, {headers})
  }

  getQuizById(quizId: string): Observable<Quiz> {
    return this.http.get<Quiz>(`${this.apiUrl}/quizzes/${quizId}`);
  }

  uploadImage(image: any, id: any): Observable<any> {
    const uploadImageData = new FormData();
    uploadImageData.append('image', image);
    let headers = this.createHeaders();
    return this.http.post(`${this.apiUrl}/quizzes/image/${id}/upload`, uploadImageData, {headers})
  }

  getAllByUserId(userId: string | null, generalRequest: GeneralRequestModel): Observable<ApiResponse<Quiz>> {
    let headers: HttpHeaders = this.createHeaders();
    let params = new HttpParams()
      .set('page', generalRequest.page)
      .set('count', generalRequest.count)
    return this.http.get<ApiResponse<Quiz>>(`${this.apiUrl}/admin/myTests?userId=${userId}`, {headers, params});
  }

  changeStatus(status: QuizStatus, id: string): Observable<any> {
    let headers = this.createHeaders();
    return this.http.patch(`${this.apiUrl}/quizzes/status?id=${id}&status=${status}`, null, {headers})
  }

  duplicateQuiz(quizId: string | undefined, newName: string | undefined, userId: string | undefined) {
    const body = {
      quizId: quizId,
      newName: newName,
      userId: userId
    };
    let headers = this.createHeaders();
    return this.http.post<Quiz>(`${this.apiUrl}/quizzes/${quizId}/duplicate`, body, {headers});
  }

  private createHeaders(): HttpHeaders {
    const token = localStorage.getItem('fb-token');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  deleteQuiz(quizId: string | undefined) {
    let headers = this.createHeaders();
    return this.http.delete<Quiz>(`${this.apiUrl}/quizzes/${quizId}`, {headers});
  }

  searchQuiz(searchTerm: string | undefined, userId: string | null, paginationModel: GeneralRequestModel): Observable<ApiResponse<Quiz>> {
    let headers = this.createHeaders();
    let params = new HttpParams()
      .set('page', paginationModel.page)
      .set('count', paginationModel.count)
    return this.http.get<ApiResponse<Quiz>>(`${this.apiUrl}/quizzes/search/${searchTerm}?userId=${userId}`, {
      headers,
      params
    })
  }

  getAllForSelectFilter(currentUserId: string | null) {
    let headers = this.createHeaders();
    return this.http.get<QuizDataModel[]>(`${this.apiUrl}/quizzes/filter-select/${currentUserId}`, {headers});
  }

  generateWithAi(generationRequest: QuizGenerationRequest) {
    const userId = this.authService.getCurrentUserId();
    if (typeof userId === "string") {
      generationRequest.userId = userId;
    }

    let headers = this.createHeaders();
    return this.http.post<Quiz>(`${this.apiUrl}/quizzes/generate`, generationRequest, {headers});
  }
}
