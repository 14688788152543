import {Component, EventEmitter, Output} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  @Output() toggleSidebarEvent = new EventEmitter<boolean>();
  isSidebarToggled = false;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  logout() {
    this.authService.logout()
    this.router.navigate(["/"])
  }


  toggleSidebarMenu() {
    if (this.isSidebarToggled) {
      this.isSidebarToggled = false;
      this.toggleSidebarEvent.emit(false);

    } else {
      this.isSidebarToggled = true;
      this.toggleSidebarEvent.emit(true);
    }
  }
}
