import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-custom-snackbar-errors',
  templateUrl: './custom-snackbar-errors.component.html',
  styleUrls: ['./custom-snackbar-errors.component.css']
})
export class CustomSnackbarErrorsComponent {

  editErrors = new Map<string, string>();

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Map<string, string>,
              private snackBarRef: MatSnackBar) {
    this.editErrors = data;
  }

  dismissSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
