import {Component, OnInit} from '@angular/core';
import {RespondentMonitoring} from "../../models/RespondentMonitoring";
import {ActivatedRoute} from "@angular/router";
import {GeneralRequestModel} from "../../models/generalRequestModel";
import {RespondentService} from "../../services/respondent.service";

@Component({
  selector: 'app-respondent-monitoring',
  styleUrls: ['./respondent-monitoring.component.css'],
  templateUrl: './respondent-monitoring.component.html'
})
export class RespondentMonitoringComponent implements OnInit {
  respondentMonitoring: RespondentMonitoring[] = [];
  quizId!: string;
  respondentsTotalCount: number = 0;
  totalPages: number = 1;
  pageSize: number = 10;
  currentSortDirection: string = 'desc';

  constructor(
    private respondentService: RespondentService,
    private activeRouter: ActivatedRoute) {
    this.activeRouter.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['id']) {
        this.quizId = queryParams['id'];
      }
    });
  }

  ngOnInit(): void {
    this.getAllRespondentsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection);
  }

  getAllRespondentsForQuiz(page: number, count: number, sortDirection: string) {
    const generalRequest: GeneralRequestModel = {page, count, sortDirection};
    this.respondentService.getByQuizId(this.quizId, generalRequest)
      .subscribe(respondents => {
        this.respondentMonitoring = respondents.data;
        this.respondentsTotalCount = respondents.meta.total;
      });
  }

  goToPreviousPage() {
    if (this.totalPages > 1) {
      this.totalPages--;
      this.getAllRespondentsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    }
  }

  goToNextPage() {
    if (this.respondentMonitoring.length >= this.pageSize) {
      this.totalPages++;
      this.getAllRespondentsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    }
  }

  goToPageNumber(page: number) {
    if (page > 0 && page <= this.totalPages) {
      this.totalPages = page;
      this.getAllRespondentsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    }
  }
}
