<section class="container-fluid p-4 height">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12">
      <div class="border-bottom pb-3 mb-3 d-lg-flex align-items-center justify-content-between">
        <div class="mb-2 mb-lg-0">
          <h1 class="mb-0 h2 fw-bold">Respondent monitoring</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a routerLink="/dashboard">Respondent monitoring</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12">
      <div class="card">
        <div class="align-items-center p-4 p-1 height">
          <a class="item">Respondents({{ respondentsTotalCount }})</a>
        </div>
        <div class="p-0 width">
          <div class="tab-content" id="tabContent">
            <div class="tab-pane fade show active" id="all-orders" role="tabpanel" aria-labelledby="all-orders-tab">
              <div class="table-responsive">
                <table class="table mb-0 text-nowrap table-hover table-centered">
                  <thead class="table-light">
                  <tr>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th>EMAIL ADDRESS</th>
                    <th>CURRENT QUESTION</th>
                    <th>CURRENT SCORE</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let respondent of respondentMonitoring" class="cursor-pointer">
                    <td><span>{{ respondent.name }}</span></td>
                    <td><span>{{ respondent.surname }}</span></td>
                    <td><span>{{ respondent.email }}</span></td>
                    <td>
                      <span>
                        {{ respondent.currentIndex + 1 + "/" + respondent.questionCount }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ respondent.actualScore + "/" + respondent.totalScore }}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="respondentsTotalCount >= 11">
          <nav aria-label="Page navigation example">
            <ul class="pagination mb-0">
              <li class="page-item" (click)="goToPreviousPage()" [class.disabled]="totalPages === 1">
                <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-left"></i></a>
              </li>
              <li class="page-item"
                  (click)="goToPageNumber(totalPages)">
                <a class="page-link mx-1 rounded">{{ totalPages }}</a>
              </li>
              <li class="page-item" (click)="goToNextPage()" [class.disabled]="respondentMonitoring.length < pageSize">
                <a class="page-link mx-1 rounded"><i class="mdi mdi-chevron-right"></i></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
