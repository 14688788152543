<div class="bs-stepper-content mt-5">
  <form [formGroup]="setupQuizForm" (ngSubmit)="submit()">

    <div>
      <div class="card mb-3 border-0">
        <div class="card-header border-bottom px-4 py-3">
          <h4 class="mb-0">Advanced Settings</h4>
        </div>
        <div class="card-body">
          <div class="bg-light rounded p-2 mb-4">
            <h4>Questions Order</h4>
            <div class="list-group list-group-flush border-top-0" id="courseList">
              <label id="example-radio-group-label">Select one of the options:</label>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                formControlName="isRandom">
                <mat-radio-button class="example-radio-button custom-scale" [value]="false" [disabled]="quiz?.status === QuizStatus.ENDED">
                  Fixed questions and answers order as
                  defined in Questions manager
                </mat-radio-button>
                <mat-radio-button class="example-radio-button custom-scale" [value]="true" [disabled]="quiz?.status === QuizStatus.ENDED">
                  Random questions and answers order
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <h4>Access link</h4>
            <div class="list-group list-group-flush border-top-0" id="courseListSecond">
              <div class="d-flex justify-content-between ">
                <div id="test_link">
                  <a [href]="appBaseUrl + '/start/quiz?id=' + quiz?.id" target="_blank">{{ appBaseUrl }}
                    /start/quiz?id={{ quiz?.id }}
                  </a>
                </div>
                <div class="d-flex">
                  <a #copiedLink [href]="appBaseUrl +'/start/quiz?id=' + quiz?.id" target="_blank"
                     class="d-flex align-items-center">
                  </a>
                  <div (click)="copyLink()" class="align-items d-flex align-items-center cursor-pointer copy-link">
                    <mat-icon>content_copy</mat-icon>
                    <span type="submit">Copy link</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <h4>Additional Options</h4>
            <div class="list-group list-group-flush border-top-0" id="courseList3">
              <ul class="list-group list-group-flush">
                <li class="d-flex justify-content-between">
                  <div>
                    Allow answering questions in any order and moving back or skipping questions
                  </div>
                  <div class="form-check form-switch">
                    <input type="checkbox" #isAnswerInAnyOrder
                           (click)="onToggleChange($event, isAnswerInAnyOrder.checked,'isAnswerInAnyOrder')"
                           formControlName="isAnswerInAnyOrder" class="form-check-input cursor-pointer" id="switch"
                           [ngClass]="{'disableClick': readOnly}">
                    <label class="form-check-label" for="switch"></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <h4>Grading Criteria</h4>
            <div class="list-group list-group-flush border-top-0" id="courseList4">
              <ul class="list-group list-group-flush">
                <li class="d-flex justify-content-between">
                  <div>
                    Pass mark
                  </div>
                  <div class="form-check form-switch">
                    <input type="checkbox" #isPercentage
                           (click)="onToggleChange($event, isPercentage.checked,'isPercentage')"
                           formControlName="isPercentage"
                           class="form-check-input cursor-pointer" id="switchForPercents"
                           [ngClass]="{'disableClick': readOnly}">
                    <label class="form-check-label" for="switchForPercents"></label>
                  </div>
                </li>
                <li class="d-flex align-items-center">
                  <div>
                    <span>{{ isPercentage.checked ? 'Value (use 0 - 100% as a range)' : 'Value' }}</span><br>
                    <input type="number" class="form-control" formControlName="passMark" [readonly]="readOnly"
                           required>
                  </div>
                  <div class="mx-3 mt-4">
                    <span>{{ isPercentage.checked ? '%' : 'p.' }}</span>
                  </div>
                </li>
                <div class="validation"
                     *ngIf="setupQuizForm.get('passMark')!.hasError('min') && (setupQuizForm.get('passMark')!.touched || setupQuizForm.get('passMark')?.dirty)">
                  min value is 0.
                </div>
                <div class="validation"
                     *ngIf="setupQuizForm.get('passMark')!.hasError('max') && (setupQuizForm.get('passMark')!.touched || setupQuizForm.get('passMark')?.dirty)">
                  max value is 100.
                </div>
              </ul>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <h4>Test duration</h4>
            <div class="form-switch-container d-flex align-items-center">
              <div class="form-check form-switch">
                <input type="checkbox" #timeTrue class="form-check-input cursor-pointer"
                       id="switchForTime" formControlName="isTime" [ngClass]="{'disableClick': readOnly}">
                <label class="form-check-label" for="switchForTime">Time to complete the test:
                  (hh:mm)</label>
              </div>
              <div [matMenuTriggerFor]="menu" class="mx-3 mt-3">
                <mat-icon class="absolute-positioned" matPrefix>schedule</mat-icon>
                <input class="custom-form-field" matInput [value]="formatTime(hours, minutes)"
                       [disabled]="!timeTrue.checked" readonly>
              </div>
            </div>
            <mat-menu #menu="matMenu">
              <mat-card *ngIf="timeTrue.checked && !readOnly" class="shadow-none">
                <mat-card-content>
                  <span>Hours: </span>
                  <mat-slider class="mx-4" [max]="maxHours" [min]="min" [step]="step"
                              [discrete]="thumbLabel"
                              (click)="$event.stopPropagation()">
                    <input matSliderThumb [(ngModel)]="hours" formControlName="hours">
                  </mat-slider>
                  <br>
                  <span>Minutes:</span>
                  <mat-slider class="mx-3" [max]="maxMinutes" [min]="min" [step]="step"
                              [discrete]="thumbLabel"
                              (click)="$event.stopPropagation()">
                    <input matSliderThumb [(ngModel)]="minutes" formControlName="minutes">
                  </mat-slider>
                </mat-card-content>
                <mat-card-actions class="d-flex justify-content-between">
                  <button class="btn btn-light" (click)="resetValues()" [class.disabled]="readOnly">Reset</button>
                  <button class="btn btn-primary" [class.disabled]="readOnly">Confirm</button>
                </mat-card-actions>
              </mat-card>
            </mat-menu>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <h4>AI Answer Review</h4>
            <div class="form-switch-container d-flex align-items-center">
              <div class="form-check form-switch">
                <input type="checkbox" #isReviewedByAI class="form-check-input cursor-pointer" [ngClass]="{'disableClick': readOnly}"
                       id="ai-review-toggle" (click)="onToggleChange($event, isReviewedByAI.checked, 'isReviewedByAI')" formControlName="isReviewedByAI">
                <label>Enable AI answer review for descriptive questions</label>
              </div>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <div class="d-flex">
              <h4 class="me-3">Test start page</h4>
              <div class="tooltip">
                <img src="https://cdn-icons-png.flaticon.com/128/665/665049.png" class="mt-1" loading="lazy" alt="Info"
                     width="20" height="20">
                <div class="tooltiptext">This is going to be the Quiz's default start page for the respondents. You are
                  not able to change or delete these fields.
                </div>
              </div>
            </div>
            <div class="list-group list-group-flush border-top-0" id="courseList6">
              <mat-card>
                <mat-card-content>
                  <div>
                    <div class="mb-3 col-md-3">
                      <label class="form-label" for="firstName">First Name</label>
                      <input type="text" class="form-control" placeholder="Enter first name" id="firstName" readonly>
                    </div>
                    <div class="mb-3 col-md-3">
                      <label class="form-label" for="lastName">Last Name</label>
                      <input type="text" class="form-control" placeholder="Last Name" id="lastName" readonly>
                    </div>
                    <div class="mb-3 col-md-3">
                      <label class="form-label" for="email">Email</label>
                      <input type="email" class="form-control" placeholder="Enter email address" id="email" readonly>
                    </div>
                    <div class="mb-3 col-md-3">
                      <label class="form-label" for="phone">Phone</label>
                      <input type="number" class="form-control" placeholder="Enter phone number" id="phone" readonly>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4" [ngClass]="{'disableClick': quiz?.status === QuizStatus.ENDED}">
            <h4>Test end message</h4>
            <div class="list-group list-group-flush border-top-0" id="courseList7">
              <angular-editor placeholder="Add your Content here"
                              formControlName="endMessage" [config]="editorConfig"></angular-editor>
              <div class="validation"
                   *ngIf="setupQuizForm.get('endMessage')!.hasError('maxlength') && (setupQuizForm.get('endMessage')!.touched || setupQuizForm.get('endMessage')!.dirty)">
                Maximum character count is 150.
              </div>
            </div>
          </div>
          <div class="bg-light rounded p-2 mb-4">
            <div class="d-flex">
              <h4 class="me-3">Honest Respondent Technology</h4>
            </div>
            <div class="list-group list-group-flush border-top-0">
              <label><p class="text-black">To increase test results reliability, activate a mechanism that monitors browser tab movements. If any movement or tab switching is detected, the mechanism issues or warnings or blocks the test, according to the settings of your choice.</p></label>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group flex-row"
                formControlName="alert"
                [class.mat-radio-horizontal]="true">
                <mat-radio-button class="example-radio-button custom-scale" [value]="Alert.DISABLE">
                  Disable
                </mat-radio-button>
                <mat-radio-button (change)="onRadioChange('warning')" class="example-radio-button custom-scale" [value]="Alert.WARNING">
                  Enable warnings only
                </mat-radio-button>
                <mat-radio-button (change)="onRadioChange('testBlock')" class="example-radio-button custom-scale" [value]="Alert.TEST_BLOCK">
                  Enable warnings and test block
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngIf="isSectionHidden || (alertValue === Alert.TEST_BLOCK)">
              <div class="d-flex">
                <p class="mb-0 ms-1">Max. allowed number of times a respondent can leave test page</p>
              </div>
              <div>
                <input type="number" class="form-control" name="allowedTabNumber" formControlName="allowedTabNumber">
                <div class="text-danger ms-1" *ngIf="setupQuizForm.get('allowedTabNumber')?.invalid && (setupQuizForm.get('allowedTabNumber')?.touched || setupQuizForm.get('allowedTabNumber')?.dirty)">
                  <small *ngIf="setupQuizForm.get('allowedTabNumber')?.errors?.['min']">Value must be at least 0.</small>
                  <small *ngIf="setupQuizForm.get('allowedTabNumber')?.errors?.['max']">Value must be at most 10.</small>
                </div>
              </div>
              <div class="d-flex">
                <p class="fs-6 ms-1">We recommend using value greater than or equal to 2.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"
                [disabled]="!isFormModified() || readOnly || !EditorValid.isEditorValid(endMessageValue)">Save
        </button>
      </div>
    </div>
  </form>
</div>
