<div class="modal-header">
  <h4 class="modal-title mb-0">
    Question {{ serialNumber }}</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body" #modalBody [ngClass]="{'h-auto': addQuestionForm.get('questionType')?.value === QuestionType.DESCRIPTIVE}">
  <!-- Modal Content -->
  <div class="modal-content">
    <h5 *ngIf="loading" class="text-center text-lg-center">Loading...</h5>

    <form *ngIf="!loading" [formGroup]="addQuestionForm">
      <angular-editor [config]="questionEditorConfig" formControlName="ask"
                      placeholder="Add your question here"></angular-editor>
      <label for="questionType" class="mt-3 fw-light">Answer type</label><br>
      <select formControlName="questionType"
              class="form-select d-inline h-100 w-lg-100"
              [ngClass]="{'mb-3': addQuestionForm.get('questionType')?.value !== QuestionType.DESCRIPTIVE}"
              id="questionType"
              (change)="initializeForm(addQuestionForm.get('questionType')?.value, addQuestionForm.get('ask')?.value, addQuestionForm.get('score')?.value )">
        <option *ngFor="let questionType of questionTypes"
                [value]="questionType.enumType">{{ questionType.type }}
        </option>
      </select>

      <div
        *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.SINGLE_SELECT || addQuestionForm.get('questionType')?.value === QuestionType.MULTI_SELECT">
        <label class="fw-bold">Answers</label><br>
        <label class="mb-3 fw-light">After providing possible answers, select the correct one<span
          *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.MULTI_SELECT">s</span>.</label><br>
        <div formArrayName="answers">
          <div *ngFor="let answerGroup of answers.controls; let i = index" [formGroupName]="i"
               class="answer-wrapper">
            <div class="editor-delete-wrapper">
              <div *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.SINGLE_SELECT"
                   class="d-flex align-items-center">
                <input class="moved-left" type="radio"
                       formControlName="isSingleCorrect"
                       [value]="true"
                       (change)="selectSingleCorrectAnswer(i)">

              </div>
              <div *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.MULTI_SELECT"
                   class="d-flex align-items-center">
                <input class="cursor-pointer" type="checkbox"
                       formControlName="isMultiCorrect"
                       [checked]="answerGroup.get('isMultiCorrect')?.value"
                       value="true"
                       (change)="isMultiChoiceAnswersValid()">
              </div>

              <div class="w-100"
                   [ngClass]="{'ms-2': addQuestionForm.get('questionType')?.value === QuestionType.MULTI_SELECT}">
                <angular-editor [config]="config"
                                formControlName="variantName"
                                id="variantName-{{ i }}"
                                placeholder="Add your answer here">
                </angular-editor>
              </div>
              <span class="d-flex align-items-center" (click)="removeAnswer(i)">
                                <i class="fa fa-trash-alt cursor-pointer"></i>
                            </span>
            </div>
          </div>
        </div>
        <button type="button" (click)="addAnswer()" class="add-answer-button roww btn btn-light mb-5">
          <i class="fas"></i>Add answer
        </button>
      </div>

      <!-- True or False Question Type -->
      <div *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.TRUE_OR_FALSE" class="me-4 mb-5">
        <label class="fw-bold">Answers</label><br>
        <label class="mt-1 fw-light">After providing possible answers, select the correct one.</label><br>
        <div formGroupName="trueOrFalseAnswers" class="me-2">
          <div class="d-inline-box custom-font-size mt-3 d-flex align-items-center">
            <input type="radio" class="example-radio-button me-2 cursor-pointer"
                   formControlName="isPositiveOptionCorrect"
                   [value]="true"
                   (change)="selectTrueOrFalseAnswer(0)">
            <angular-editor [config]="questionEditorConfig" formControlName="positiveOptionVariantName"
                            placeholder="Add your answer here" class="w-100">
            </angular-editor>
          </div>
          <div class="d-inline-box custom-font-size mt-2 d-flex align-items-center w-100">
            <input type="radio" class="example-radio-button me-2 cursor-pointer"
                   formControlName="isNegativeOptionCorrect"
                   [value]="true"
                   (change)="selectTrueOrFalseAnswer(1)">

            <angular-editor [config]="questionEditorConfig" formControlName="negativeOptionVariantName"
                            placeholder="Add your answer here" class="w-100">
            </angular-editor>
          </div>
        </div>
      </div>

      <!-- Short Answer Question Type -->
      <div *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.SHORT_ANSWER"
           class="mb-2 w-100">
        <label class="text-dark fw-bold">Answers</label><br>
        <label class="mb-3 text-dark">Add possible answers to this question. Respondents will receive
          points only if their answers are exactly the same as those set by you.</label><br>
        <div formArrayName="shortAnswers">
          <div class="ms-3" *ngFor="let answerGroup of shortAnswers.controls; let i = index"
               [formGroupName]="i">
            <div class="mb-3 d-inline-block w-100">
              <div class="form-switch-container d-flex align-items-center">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input cursor-pointer"
                         id="letter-toggle" (click)="onToggleChange($event, 'isCapitalized')"
                         formControlName="isCapitalized">
                  <label>The response should vary based on if the letter is capitalized or in lowercase.</label>
                </div>
              </div>
            </div>
            <div class="mb-3 d-inline-block w-50">
              <input required formControlName="variantName" type="text" class="form-control"
                     placeholder="Short answer">
            </div>

            <div class="ms-4 d-inline-block" (click)="removeShortAnswer(i)">
              <i class="fa fa-trash-alt cursor-pointer"></i>
            </div>
          </div>
        </div>
        <button type="button" (click)="addShortAnswer()" class="add-answer-button roww btn btn-light mb-5"><i
          class="fas"></i>
          Add answer
        </button>
      </div>

      <div *ngIf="addQuestionForm.get('questionType')?.value === QuestionType.DESCRIPTIVE"
           class="mb-2 w-100">
        <br><label class="text-dark fw-bold">Answers</label><br>
        <label class="mb-3 text-dark">You don't need to provide a possible answer.</label><br>
      </div>

      <div class="mb-5 mx-3 score-input">
        <label for="score"
               class="fw-light">{{ addQuestionForm.get('questionType')?.value === QuestionType.DESCRIPTIVE ? 'Maximum possible score for this question' : 'Points for correct answer' }}</label>
        <input required type="number" formControlName="score" class="roww form-control mb-0"
               id="score">
        <div class="validation" for="score"
             *ngIf="addQuestionForm.get('score')?.invalid">
          Only numeric characters are allowed for the score.
        </div>
      </div>
      <div *ngIf="isIdenticalAnswersExists()" class="alert alert-warning mt-2">
        Please ensure that all answers are unique. Identical answers are not allowed.
      </div>
      <button type="button" data-bs-dismiss="modal" aria-label="Close" (click)="save()" class="me-2 btn btn-primary"
              [disabled]="!isFormValid() || !EditorValid.isEditorValid(askValue)">Save
      </button>
      <button type="button" *ngIf="!editQuestion" (click)="save()" class="me-2 btn btn-primary"
              [disabled]="!isFormValid() || !EditorValid.isEditorValid(askValue)">
        Save and add Next
      </button>
      <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-secondary">Cancel</button>
    </form>
  </div>
</div>
