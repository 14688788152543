<div class="bs-stepper-content mt-5">
  <form>
    <div id="test-l-1" role="tabpanel" class="bs-stepper-content " aria-labelledby="courseFormtrigger1">
      <!-- Card -->
      <div class="card mb-3">
        <div class="card-header border-bottom px-4 py-3 d-flex justify-content-between align-items-center">
          <h4 class="mb-0">Question Manager</h4>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div class="justify-content-end d-flex mb-var-geeks-card-spacer-y">
            <a class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addQuestion"
               (click)="this.addQuestionComponent.onSuccess();" [class.disabled]="isReadOnly()">Add a Question</a>
          </div>
          <h4 *ngIf="totalQuestions != 0" class="custom-margin"> {{ quiz?.name }}</h4>
          <div class="bg-light rounded p-2 mb-4">
            <!-- List group -->
            <div class="list-group list-group-flush border-top-0" id="courseList">
              <ng-container *ngIf="totalQuestions == 0; else noQuestions">
                <div class="text-center">
                  Currently there are no questions. Let's get started!
                </div>
              </ng-container>
              <ng-template #noQuestions>
                <ul class="moveable" id="items-list" (dragover)="cancelDefault($event)">
                  <li class="list-group-item rounded px-3 text-nowrap mb-1 draggable selectedQ hover-white">
                    <div class="d-flex align-items-center text-center">
                                  <span class="checkbox me-1 top-7 cursor-pointer"
                                        [ngClass]="{ 'custom-color': isChecked }"
                                        [class.deleteQuestion]="anyQuestionSelected()" (click)="toggleCheckboxForAll()">
                                    <span class="checkmarki"
                                          [ngClass]="{ 'checkedq': isChecked, 'custom-text-color': anyQuestionSelected() }">&#10004;</span>
                                  </span>
                      <ng-container *ngIf="anyQuestionSelected(); else notSelected">
                        <div class="top-7 d-flex">
                          {{ selectedQuestionCount() }} Selected
                          <strong>
                            <a [class.disabled]="isReadOnly()" class="btn btn-delete deleteQuestion"
                               data-bs-toggle="modal"
                               data-bs-target="#deleteConfirmations"
                               [attr.disabled]="!anyQuestionSelected() ? true : null"
                               *ngIf="anyQuestionSelected()">
                              <i class="fe fe-trash dropdown-item-icon opacity-100"></i>
                              <strong>Delete</strong>
                            </a>
                          </strong>
                        </div>
                      </ng-container>
                      <ng-template #notSelected>
                        <span class="top-7">  Questions {{ totalQuestions }}</span>
                      </ng-template>
                    </div>
                  </li>
                  <li *ngFor="let question of questions; let i = index"
                      class="list-group-item rounded px-3 text-nowrap mb-1 draggable hover-white"
                      id="{{ question.id }}" draggable="true" (dragstart)="dragStart($event)"
                      (dragover)="cancelDefault($event)"
                      (drop)="dropped($event)">
                    <div class="d-flex align-items-center justify-content-between">
                      <h5 class="mb-0 text-truncate cursor-pointer" (click)="toggleCheckmark(i,question.id)">
                        <a class="text-inherit">
                          <i class="text-muted align-middle"></i>
                          <span class="checkbox me-1"
                                [style.background-color]="isCheckedList[i] ? '#754ffe' : ''"
                                [class.checked]="isCheckedList[i]"><span class="checkmark"
                                                                         [style.color]="isCheckedList[i] ? '#fff' : ''"
                                                                         [class.checkedq]="isChecked">&#10004;</span></span>
                          <span class="align-middle question-number">Q. &nbsp;{{ question.serialNumber }}</span>
                        </a>
                      </h5>
                      <div>
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="text-inherit" data-bs-toggle="collapse text-right">
                            <span class="type-grey">Type:</span> {{ getQuestionType(question.questionType) }}&nbsp;
                          </div>
                          <div class="text-inherit text-right" data-bs-toggle="collapse text-right">
                            <span class="type-grey">| Points</span> {{ question.score }}
                          </div>
                          <span class="dropdown dropstart">
                                         <a class="btn-icon btn btn-ghost btn-sm rounded-circle" id="settingDropdown"
                                            data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">

                                          <i class="fe fe-more-vertical"></i></a>
                                        <span class="dropdown-menu" aria-labelledby="settingDropdown">
                                          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#addQuestion"
                                             [class.disabled]="isReadOnly()"
                                             (click)="onEditButtonClick(question)">
                                            <i class="fe fe-edit dropdown-item-icon"></i>Edit
                                          </a>
                                          <a class="dropdown-item" data-bs-toggle="modal"
                                             data-bs-target="#deleteConfirmation" [class.disabled]="isReadOnly()"
                                             (click)="toggleQuestion(i)">
                                            <i class="fe fe-trash dropdown-item-icon"></i>Remove</a>
                                        </span>
                                      </span>
                          <a class="text-inherit" (click)="toggleSection(i)">
                                        <span class="chevron-arrow">
                                          <i class="fe fe-chevron-down cursor-pointer" *ngIf="sectionStates[i]"></i>
                                          <i class="fe fe-chevron-up cursor-pointer" *ngIf="!sectionStates[i]"></i>
                                        </span>
                          </a>
                        </div>
                      </div>
                      <div class="modal fade" id="deleteConfirmation" tabindex="-1" role="dialog"
                           aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-body geeks-modal-padding">
                              <div class="modal-header">
                                <h4 class="modal-title">Delete Confirmation</h4>
                              </div>
                              <div class="modal-body">
                                Are you sure you want to delete this question?
                              </div>
                              <div class="modal-footer">
                                <button type="button" #closebutton class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        aria-label="Close" (click)="deleteQuestionIds()">Close
                                </button>
                                <button type="button" class="btn btn-primary"
                                        (click)="deleteQuestion()">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div [id]="'collapseList' + i" class="collapse show" [attr.aria-labelledby]="question.id"
                         data-bs-parent="#courseList" [class.show]="!sectionStates[i]">
                      <div
                        *ngIf="question.questionType === QuestionType.MULTI_SELECT || question.questionType === QuestionType.SINGLE_SELECT || question.questionType === QuestionType.TRUE_OR_FALSE ">
                        <div class="text-truncate truncate">
                          <div class="text-dark  flex-grow-1 w-90" [innerHTML]="getSanitizedHTML(question.ask)"></div>
                        </div>
                        <div *ngFor="let answer of question.answerOptions">
                          <div class="collapse show" id="courseTwo"
                               data-bs-parent="#courseAccordion course-details">
                            <div class="pt-2 pb-1 max-width-77"
                                 [ngClass]="{'correct-answer-container': answer.isCorrectAnswer}">
                              <div class="mb-2">
                                <div class="d-flex justify-content-between text-inherit text-decoration-none">
                                  <div>
                                      <span class="checkbox me-3" [ngClass]="{
                                            'circle-style': question.questionType === QuestionType.SINGLE_SELECT || question.questionType === QuestionType.TRUE_OR_FALSE,
                                            'rounded-style': question.questionType !== QuestionType.SINGLE_SELECT && question.questionType !== QuestionType.TRUE_OR_FALSE}">
                                      </span>
                                  </div>
                                  <div class="text-dark flex-grow-1 text-truncate"
                                       [innerHTML]="getSanitizedHTML(answer.variantName)"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="question.questionType === QuestionType.DESCRIPTIVE">
                        <div class="text-truncate truncate pl-4">
                          <div class="text-dark flex-grow-1 w-90" [innerHTML]="getSanitizedHTML(question.ask)"></div>
                        </div>
                        <br>
                        <div>
                          <div class="answer-container">
                            <div class="answer-left">Answer</div>
                            <div class="break-word-style">
                              <angular-editor [config]="configForDescriptiveEditor"
                                              class="pointer-events-none"></angular-editor>
                              <br>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="question.questionType === QuestionType.SHORT_ANSWER">
                        <div class="text-truncate truncate">
                          <div class="text-dark dark flex-grow-1 w-90" [innerHTML]="getSanitizedHTML(question.ask)"></div>
                        </div>
                        <div>
                          <div class="answer-container mt-1">
                            <div class="possible-answers-list">
                              <div class="question_item_possible_answers_label">&nbsp;Possible
                                answers
                              </div>
                              <div class="answer-variant"
                                   *ngFor="let answer of question.answerOptions">
                                <div class="possible-answer-option text-dark"
                                     [innerHTML]="getSanitizedHTML(answer.variantName)"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="totalQuestions >= 11" class="card-footer">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center mb-0">
            <li class="page-item">
              <a class="page-link mx-1 rounded" (click)="getQuestions(this.paginationModel.page - 1)"><i
                class="mdi mdi-chevron-left"></i></a>
            </li>
            <li class="page-item"
                [class.active]="totalPages >= this.paginationModel.page">
              <a class="page-link mx-1 rounded" (click)="getQuestions(totalPages)">{{ paginationModel.page }}</a>
            </li>
            <li class="page-item">
              <a class="page-link mx-1 rounded" (click)="getQuestions(this.paginationModel.page  + 1)"><i
                class="mdi mdi-chevron-right"></i></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </form>
</div>

<div class="modal fade" id="deleteConfirmations" tabindex="-1" role="dialog"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body geeks">
        <div class="modal-header">
          <h4 class="modal-title">Delete Confirmation</h4>
        </div>
        <div class="modal-body">
          Are you sure you want to delete the selected questions?
        </div>
        <div class="modal-footer">
          <button type="button" #closeButtonQuestions class="btn btn-secondary" data-bs-dismiss="modal"
                  aria-label="Close">Close
          </button>
          <button type="button" class="btn btn-primary" (click)="deleteSelectedQuestions()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade --geeks-modal-width-70" id="addQuestion" tabindex="-1" role="dialog"
     aria-labelledby="newQuestionLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <app-add-question #childAddQuestionComponentRef></app-add-question>
    </div>
  </div>
</div>
