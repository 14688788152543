import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegistrationComponent} from "./registration/registration.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from "@angular/common/http";
import {SharedComponent} from './shared/shared.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {SidebarComponent} from './layouts/sidebar/sidebar.component';
import {HeaderComponent} from './layouts/header/header.component';
import {RememberMeGuard} from "./guards/remember-me.guard";
import {AuthGuard} from "./guards/auth.guard";
import {JwtModule} from "@auth0/angular-jwt";
import {JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt';
import {AddQuizComponent} from './quiz/add-quiz/add-quiz.component';
import {AngularEditorModule} from "@kolkov/angular-editor";
import {TestConfigurationComponent} from './quiz/test-configuration/test-configuration.component';
import {QuestionManagerComponent} from './quiz/question-manager/question-manager.component';
import {AddQuizGuard} from "./guards/add-quiz.guard";
import {AddQuestionComponent} from './quiz/question-manager/add-question/add-question.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatRadioModule} from "@angular/material/radio";
import {OtherSettingsComponent} from "./quiz/other-settings/other-settings.component";
import {MatIconModule} from "@angular/material/icon";
import {MatSliderModule} from "@angular/material/slider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from "@angular/material/menu";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {ResultsTableComponent} from "./quiz/results-table/results-table.component";
import { StartPageComponent } from './quiz/start-page/start-page.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from './quiz/other-settings/custom-snackbar/custom-snackbar.component';
import { ExamComponent } from './exam/exam.component';
import { CustomSnackbarErrorsComponent } from './quiz/question-manager/custom-snackbar-errors/custom-snackbar-errors.component';
import { QuizDialogComponent } from './quiz/quiz-dialog/quiz-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormatTimePipe} from "./util/format-time.pipe";
import {RespondentResultComponent} from "./quiz/respondent-result/respondent-result.component";
import { QuizRespondentValidationComponent } from './quiz/quiz-respondent-validation/quiz-respondent-validation.component';
import { ResultsDatabaseComponent } from './results-database/results-database.component';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import { ResultDialogComponent } from './result-dialog/result-dialog.component';
import { RespondentMonitoringComponent } from './quiz/respondent-monitoring/respondent-monitoring.component';
import { WarningSnackbarComponent } from './exam/warning-snackbar/warning-snackbar.component';
import { QuizModalComponent } from './quiz/quiz-modal/quiz-modal.component';
import { QuizCategoryComponent } from './quiz/quiz-category/quiz-category.component';
import { SpinnerComponent } from './spinner/spinner.component';


@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    LoginComponent,
    SharedComponent,
    ForgotPasswordComponent,
    SidebarComponent,
    HeaderComponent,
    AddQuizComponent,
    TestConfigurationComponent,
    QuestionManagerComponent,
    AddQuestionComponent,
    OtherSettingsComponent,
    ResultsTableComponent,
    OtherSettingsComponent,
    StartPageComponent,
    CustomSnackbarComponent,
    ExamComponent,
    CustomSnackbarErrorsComponent,
    QuizDialogComponent,
    RespondentResultComponent,
    FormatTimePipe,
    QuizDialogComponent,
    QuizRespondentValidationComponent,
    ResultsDatabaseComponent,
    ResultDialogComponent,
    RespondentMonitoringComponent,
    WarningSnackbarComponent,
    QuizModalComponent,
    QuizCategoryComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    JwtModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule
  ],
  providers: [RememberMeGuard, AuthGuard, AddQuizGuard, {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService],
  bootstrap: [AppComponent]

})
export class AppModule {
}
