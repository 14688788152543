<main>
  <app-heading (menuClicked)="onMenuToggle()"></app-heading>
  <div class="justify-content w-100 d-flex" *ngIf="!isMenuClicked">
    <div class="justify-content w-1240">
      <div class="heading-div justify-content d-block w-100 d-flex">
        <div class="text-center align-items-center justify-content-center">
          <div class="heading-custom f-col text-center fw-semibold ls-01">Empower Your Assessments with AI-Driven
            <div
              class="bg-spec font-outfit br-10 d-inline-block quiz-creation-spec">&nbsp;Quiz Creation&nbsp;
            </div>
          </div>
          <div class="heading-custom-media f-col fw-semibold ls-01">&nbsp;Empower Your &nbsp;Assessments with &nbsp;AI-Driven
            Quiz
            <div
              class="bg-spec font-outfit br-10 d-inline-block quiz-creation-spec">&nbsp;Creation&nbsp;
            </div>
          </div>
          <div class="subheading justify-content fs-20-lh-30 col-grey font-outfit">
            Streamline test creation, track performance, and unlock the power of intelligent quizzes for unparalleled
            learning and development
          </div>
        </div>
        <div class="start-trial-div position-absolute">
          <button type="submit"
                  class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 btn-start-trial start-trial-p d-flex align-items-center justify-content-center">
        <span class="f-col text-center fw-medium">
          Start&nbsp;Free&nbsp;Trial
        </span>
          </button>
        </div>
      </div>
      <div class="back-img-div br-20">
        <div class="background-div position-relative w-1240 br-20">
          <div class="admin-back-div d-inline-block position-absolute">
            <img src="/assets/images/background/smaller-background.png" alt="admin-background"
                 class="admin-background-small br-20 w-1240 position-absolute d-flex"></div>
          <div class="mobile-back-div d-inline-block position-absolute">
            <img src="/assets/images/background/mobile-background.png" alt="admin-background"
                 class="mobile-background-small br-20 wh-mobile position-absolute d-flex"></div>
          <div class="assessment-div br-2-black d-inline-block position-relative bg-wh br-10">
            <div class="simplify-div f-col"><span class="fs-40-lh-48 ls-01">Simplify<span
              class="simplify-spec-div br-10 bg-spec d-inline-block">&nbsp;Assessment&nbsp;</span>with Intelligent Quizzes</span>
            </div>
            <div class="platform-div"><span class="fs-20-lh-30 col-grey font-outfit">Our platform combines powerful AI with an intuitive, user-friendly interface, making quiz creation easier than ever. Design custom quizzes, tailor questions, and access AI-generated suggestions in minutes. Perfect for educators, trainers, and institutions looking to streamline assessment with cutting-edge technology.</span>
            </div>
            <div class="dot-div mb-custom-12">
              <img src="/assets/images/dot/dot.png" alt="dot" class="dot-icon d-inline-block dot-mobile-m">
              <div class="dot-text-div d-inline-block align-middle">
                <span class="fs-24-lh-36 f-col fw-normal">AI-powered quiz creation for faster setup</span>
              </div>
            </div>
            <img src="/assets/images/dot/dot.png" alt="dot" class="dot-icon d-inline-block">
            <div class="dot-text-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-normal">Real-time progress tracking</span>
            </div>
          </div>
          <img src="/assets/images/background/avatar-background.png" alt="avatar-background"
               class="avatar-background br-20 position-absolute d-inline-block">
        </div>
      </div>
      <div id="for-every-need" class="every-need pb-0 bg-wh w-1240 br-20">
        <div class="every-need-right g-20">
          <div class="d-inline-block">
        <span class="every-need-span ls-01 f-col fw-semibold">
          Designed for Every<div class="every-need-spec-div br-10 bg-spec d-inline-block">&nbsp;Need&nbsp;</div></span>
          </div>
          <div class="d-inline-block mt-platform-text">
            <span class="fs-20-lh-30-mobile col-grey font-outfit">Our platform serves diverse educational and training contexts, each tailored for unique goals and users.</span>
          </div>
        </div>
        <img src="/assets/images/every-need/devider.png" alt="devider" class="devider-img d-inline-block">
        <img src="/assets/images/every-need/devider-horizontal.png" alt="devider"
             class="devider-horizontal d-inline-block">
        <div class="every-need-left g-20">
          <img src="/assets/images/every-need/school-hat.png" alt="school-hat"
               class="school-hat-icon position-absolute">
          <img src="/assets/images/every-need/school-icon.png" alt="school" class="school-icon position-absolute">
          <div class="university-text-div">
            <span class="university-text fs-24-lh-36-mobile f-col ls-01 fw-bold">Universities & Colleges</span></div>
          <div class="university-div">
            <span class="university-p fs-20-lh-30-mobile col-grey fw-light f-col">Empower instructors with adaptable tools that
              address varied learning needs.</span>
          </div>
          <div class="every-need-mobile mt-training-institutes g-20">
            <img src="/assets/images/every-need/edu-vector.png" alt="vector" class="edu-vector position-absolute">
            <img src="/assets/images/every-need/history-edu.png" alt="education" class="history-edu position-absolute">
            <div class="university-text-div">
              <span class="university-text fs-24-lh-36-mobile f-col ls-01 fw-bold">Training Institutes</span></div>
            <div class="university-div">
              <span class="university-p fs-20-lh-30-mobile col-grey f-col">Optimize skills retention and knowledge
                evaluation
                with streamlined
                assessments.</span>
            </div>
          </div>
          <div class="corporate-training g-20">
            <img src="/assets/images/every-need/corporate-training.png" alt="corporate-training"
                 class="corporate-training-img">
            <div class="corporate-training-div">
              <span class="university-text fs-24-lh-36-mobile f-col ls-01 fw-bold">Corporate Training</span></div>
            <div class="university-div">
              <span class="university-p fs-20-lh-30-mobile col-grey f-col">Track and measure employee growth with structured quizzes and analytics.</span>
            </div>
          </div>
          <div class="independent-educators g-20">
            <img src="/assets/images/every-need/independent-educators.png" alt="independent-educators"
                 class="corporate-training-img">
            <div class="corporate-training-div">
              <span class="university-text fs-24-lh-36-mobile f-col ls-01 fw-bold">Independent Educators</span></div>
            <div class="university-div">
              <span class="university-p fs-20-lh-30-mobile col-grey f-col">Simplify the process of reaching learners and customizing assessments, wherever you are.</span>
            </div>
          </div>
          <div class="not-mobile-div every-need-left g-20">
            <img src="/assets/images/every-need/rectangle.png" alt="rectangle" class="rectangle">
          </div>
        </div>
      </div>
      <div class="features-highlight-outer d-flex w-1240">
        <div class="highlight-background d-flex position-relative br-20">
          <img src="/assets/images/background/highlight-background.png" alt="highlight-background"
               class="highlight-background-img">
          <div
            class="highlight-background highlight-inner d-flex position-absolute justify-content-center align-items-center">
            <img src="/assets/images/background/ai-powered-creation.png" alt="highlight-background"
                 class="ai-powered-creation" *ngIf="isQuizCreationVisible">
            <img src="/assets/images/background/real-time-progress.png" alt="highlight-background"
                 class="real-time-progress" *ngIf="isRealTimeProgressVisible">
            <img src="/assets/images/background/optimized-devices.png" alt="highlight-background"
                 class="optimized-devices" *ngIf="isOptimizedDevicesVisible">
          </div>
        </div>
        <div>
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom">
          <div class="ai-powered-quiz-creation bg-wh g-20-custom br-20" *ngIf="isQuizCreationVisible">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-minus.png" alt="minus" class="rounded-minus">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-bold ls-01">AI-Powered Quiz Creation</span>
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <div class="pl-pt">
        <span class="fs-20-lh-30 font-outfit fw-light col-grey">
          Automate and streamline quiz building with intelligent suggestions, question banks, and adaptive formats that fit your style.
        </span>
              </div>
            </div>
          </div>
          <div class="ai-powered-quiz-creation-plus g-20" *ngIf="!isQuizCreationVisible"
               (click)="toggleSection('quizCreation')">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-plus.png" alt="plus" class="rounded-plus">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-bold ls-01">AI-Powered Quiz Creation</span>
            </div>
          </div>
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom">
          <div class="ai-powered-quiz-creation-plus g-20" *ngIf="!isRealTimeProgressVisible"
               (click)="toggleSection('realTimeProgress')">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-plus.png" alt="plus" class="rounded-plus">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-bold ls-01">Real-Time Progress Tracking</span>
            </div>
          </div>
          <div class="ai-powered-quiz-creation bg-wh g-20 br-20" *ngIf="isRealTimeProgressVisible">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-minus.png" alt="minus" class="rounded-minus">
            </div>
            <div class=" ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-bold ls-01">Real-Time Progress Tracking</span>
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <div class="pl-pt">
        <span class="fs-20-lh-30 font-outfit fw-light col-grey">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span>
              </div>
            </div>
          </div>
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom">
          <div class="ai-powered-quiz-creation bg-wh g-20 br-20" *ngIf="isOptimizedDevicesVisible">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-minus.png" alt="minus" class="rounded-minus">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-bold ls-01">Optimized for All Devices</span>
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <div class="pl-pt">
                <span class="fs-20-lh-30 font-outfit fw-light col-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
              </div>
            </div>
          </div>
          <div class="ai-powered-quiz-creation-plus g-20" *ngIf="!isOptimizedDevicesVisible"
               (click)="toggleSection('optimizedDevices')">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-plus.png" alt="plus" class="rounded-plus">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36 f-col fw-bold ls-01">Optimized for All Devices</span>
            </div>
          </div>
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom">
        </div>
      </div>
      <div class="features-highlight-mobile w-1240">
        <div class="position-relative">
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
          <div class="ai-powered-quiz-creation-mobile bg-wh g-20" *ngIf="isQuizCreationVisible">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-minus.png" alt="minus" class="rounded-minus">
            </div>
            <div class="ai-powered-first-div d-inline-block">
              <span class="fs-24-lh-36-mobile f-col fw-bold ls-01">AI-Powered Quiz Creation</span>
              <div class="ai-powered-first-text">
                <div class="pl-pt">
        <span class="fs-20-lh-30 font-outfit col-grey">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        </span>
                </div>
              </div>
            </div>
          </div>
          <img src="/assets/images/background/ai-powered-mobile.png" alt="ai-powered-quiz" class="ai-powered-quiz"
               *ngIf="isQuizCreationVisible">
          <div class="ai-powered-quiz-plus-mobile mt-plus-mobile g-20" *ngIf="!isQuizCreationVisible"
               (click)="toggleSection('quizCreation')">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-plus.png" alt="plus" class="rounded-plus-mobile">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36-mobile f-col fw-bold ls-01">AI-Powered Quiz Creation</span>
            </div>
          </div>
          <div class="hr-mobile-div d-inline-block mt-22-custom mb-custom-12" *ngIf="!isQuizCreationVisible">
            <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
          </div>
          <div class="hr-mobile-div t-hr-94 d-inline-block" *ngIf="!isRealTimeProgressVisible && isQuizCreationVisible">
            <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
          </div>
          <div class="ai-powered-quiz-plus-mobile hr-mobile-div t-hr-105 g-20"
               [ngClass]="!isQuizCreationVisible ? 'mt-77-custom' : ''" *ngIf="!isRealTimeProgressVisible"
               (click)="toggleSection('realTimeProgress')">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-plus.png" alt="plus" class="rounded-plus-mobile">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36-mobile f-col fw-bold ls-01">Real-Time Progress Tracking</span>
            </div>
          </div>
          <div class="real-time-progress-mobile bg-wh g-20" *ngIf="isRealTimeProgressVisible">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-minus.png" alt="minus" class="rounded-minus-mobile">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36-mobile f-col fw-bold ls-01">Real-Time Progress Tracking</span>
              <div class="ai-powered-first-text d-inline-block align-middle">
                <div class="pl-pt">
                <span class="fs-20-lh-30 font-outfit col-grey">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </span>
                </div>
              </div>
            </div>
          </div>
          <img src="/assets/images/background/real-time-progress-mobile.png" alt="real-time-progress"
               class="ai-powered-quiz" *ngIf="isRealTimeProgressVisible">
          <div class="hr-mobile-div t-hr-101" *ngIf="isRealTimeProgressVisible">
            <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
          </div>
          <div class="hr-mobile-div"
               [ngClass]="isQuizCreationVisible ? 't-hr-120' : 'mt-163-custom'" *ngIf="!isRealTimeProgressVisible">
            <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
          </div>
        </div>
        <div class="optimized-devices-mobile position-absolute bg-wh g-20 mt-174" *ngIf="isOptimizedDevicesVisible">
          <div class="p-optimized-devices">
            <div class="d-inline-block">
              <img src="/assets/images/dot/rounded-minus.png" alt="minus" class="rounded-minus-mobile-opt">
            </div>
            <div class="ai-powered-first-div d-inline-block align-middle">
              <span class="fs-24-lh-36-mobile f-col fw-bold ls-01">Optimized for All Devices</span>
              <div class="ai-powered-first-text d-inline-block align-middle">
                <div class="pl-pt">
                  <span class="fs-20-lh-30 font-outfit col-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                </div>
              </div>
            </div>
          </div>
          <img src="/assets/images/background/optimized-devices-mobile.png" alt="optimized-devices"
               class="ai-powered-quiz" *ngIf="isOptimizedDevicesVisible">
        </div>
        <div class="hr-mobile-div mt-687" *ngIf="isOptimizedDevicesVisible">
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
        </div>
        <div class="ai-powered-quiz-plus-mobile position-absolute g-20"
             [ngClass]="isRealTimeProgressVisible ? 'mt-49-custom' : 'mt-160-custom'" *ngIf="!isOptimizedDevicesVisible"
             (click)="toggleSection('optimizedDevices')">
          <div class="d-inline-block">
            <img src="/assets/images/dot/rounded-plus.png" alt="plus" class="rounded-plus-mobile">
          </div>
          <div class="ai-powered-first-div d-inline-block align-middle">
            <span class="fs-24-lh-36-mobile f-col fw-bold ls-01">Optimized for All Devices</span>
          </div>
        </div>
        <div class="hr-mobile-div" [ngClass]="isRealTimeProgressVisible ? 't-hr-123' : 't-hr-240'"
             *ngIf="!isOptimizedDevicesVisible">
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-mobile">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isMenuClicked" id="fit-needs"
       [ngClass]="{
              'h-plan-4': subscriptionPlanLength === 4,
              'h-plan-3': subscriptionPlanLength === 3,
              'h-plan-2': subscriptionPlanLength === 2
       }"
       class="fit-needs g-20 w-100 position-relative d-flex flex-column bg-wh justify-content d-flex">
    <div class="fit-needs-div position-relative text-center">
      <span class="fs-40-lh-48 f-col fw-semibold ls-01">Choose<span class="fit-needs-spec-div bg-spec br-10">&nbsp;the Plan&nbsp;</span>That Fits Your Needs</span>
    </div>
    <div class="fits-needs-subheader-div text-center">
      <span class="fs-20-lh-30 col-grey font-outfit fw-light">Explore our subscription options tailored to a wide range of educational and training needs. Start with a risk-free Free Trial to discover how IT Quiz can revolutionize your assessment process.</span>
    </div>
    <div class="fits-needs-toggle-div d-flex">
      <div class="monthly-div d-inline-block"><span class="word-span col-grey font-outfit fs-16-lh-20 fw-normal"
                                                    [class.checked]="!isChecked">Monthly</span></div>
      <label class="switch position-relative d-inline-block">
        <input type="checkbox" checked [(ngModel)]="isChecked" class="opacity-0 h-0 w-0">
        <span class="slider round position-absolute cursor-pointer top-0 end-0 start-0 bottom-0"></span>
      </label>
      <div class="annually-div d-inline-block"><span class="word-span col-grey font-outfit fs-16-lh-20 fw-normal"
                                                     [class.checked]="isChecked">Annually</span></div>
    </div>
    <div class="background-div-fit z-index-0 position-relative d-inline-block w-1240 br-20">
      <img src="/assets/images/background/smaller-background.png" alt="admin-background"
           class="background-fit-small br-20 w-1240 d-flex position-absolute">
      <div class="fit-column-section br-10 p-fit-column position-absolute d-inline-block"
           [ngClass]="{
            'm-40 w-282': subscriptionPlanLength === 4,
            'm-75 w-344': subscriptionPlanLength === 3,
            'm-85 w-528': subscriptionPlanLength === 2
           }">
        <div class="fs-24-lh-36 f-col fw-bold ls-01">Basic</div>
        <div class="pt-9-custom col-grey font-outfit fs-16-lh-20 fw-normal"
             [ngClass]="{
              'w-222': subscriptionPlanLength === 4,
              'w-284': subscriptionPlanLength === 3,
              'w-468': subscriptionPlanLength === 2
              }">
          Ideal for small classrooms and individual educators
        </div>
        <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30 ls-02"><span
          class="fs-40-lh-48 ls-01 fw-semibold">$29</span> / per month
        </div>
        <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit"
             [ngClass]="{
              'w-222': subscriptionPlanLength === 4,
              'w-284': subscriptionPlanLength === 3,
              'w-468': subscriptionPlanLength === 2
              }">
        <div class="blue-tick-div">
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/close.png" alt="close" class="close">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/close.png" alt="close" class="close">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/close.png" alt="close" class="close">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
        </div>
        <button type="submit"
                class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'w-222': subscriptionPlanLength === 4,
                  'w-284': subscriptionPlanLength === 3,
                  'w-468': subscriptionPlanLength === 2
                }">
          <span class="f-col text-center fw-medium">
          Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
        </span>
        </button>
      </div>
      <div class="fit-column-section br-10 pb-pro-div position-absolute d-inline-block br-2-black"
           [ngClass]="{
            'm-332 w-282': subscriptionPlanLength === 4,
            'm-445 w-344': subscriptionPlanLength === 3,
            'm-635 w-528': subscriptionPlanLength === 2
           }">
        <div class="fs-24-lh-36 f-col bg-spec w-70-custom br-10 fw-bold ls-01">&nbsp;&nbsp;&nbsp;Pro</div>
        <div class="p-fit-column">
          <div class="pt-9-custom col-grey font-outfit fs-16-lh-20 fw-normal"
               [ngClass]="{
                'w-222': subscriptionPlanLength === 4,
                'w-284': subscriptionPlanLength === 3,
                'w-468': subscriptionPlanLength === 2
                }">
            Perfect for institutions with advanced needs
          </div>
          <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30 ls-02"><span
            class="fs-40-lh-48 ls-01 fw-semibold">$29</span> / per month
          </div>
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit"
               [ngClass]="{
                'w-222': subscriptionPlanLength === 4,
                'w-284': subscriptionPlanLength === 3,
                'w-468': subscriptionPlanLength === 2
                }">
          <div class="blue-tick-div">
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
            <br>
            <div class="h-10"></div>
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
            <br>
            <div class="h-10"></div>
            <img src="/assets/images/dot/close.png" alt="close" class="close" *ngIf="subscriptionPlanLength !== 2">
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick"
                 *ngIf="subscriptionPlanLength === 2">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
            <br>
            <div class="h-10"></div>
            <img src="/assets/images/dot/close.png" alt="close" class="close" *ngIf="subscriptionPlanLength !== 2">
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick"
                 *ngIf="subscriptionPlanLength === 2">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          </div>
          <button type="submit"
                  class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center"
                  [ngClass]="{
                    'w-222': subscriptionPlanLength === 4,
                    'w-284': subscriptionPlanLength === 3,
                    'w-468': subscriptionPlanLength === 2
                  }">
          <span class="f-col text-center fw-medium">
            Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
          </span>
          </button>
        </div>
      </div>
      <div class="fit-column-section br-10 p-fit-column position-absolute d-inline-block"
           *ngIf="subscriptionPlanLength === 4 || subscriptionPlanLength === 3"
           [ngClass]="{
            'm-625 w-282': subscriptionPlanLength === 4,
            'm-815 w-344': subscriptionPlanLength === 3
           }">
        <div class="fs-24-lh-36 f-col fw-bold ls-01">Enterprise</div>
        <div class="pt-9-custom col-grey font-outfit fs-16-lh-20 fw-normal"
             [ngClass]="{
              'w-222': subscriptionPlanLength === 4,
              'w-284': subscriptionPlanLength === 3
              }">
          Customizable options for large-scale implementations
        </div>
        <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30 ls-02"><span
          class="fs-40-lh-48 ls-01 fw-semibold">$29</span> / per month
        </div>
        <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit"
             [ngClass]="{
              'w-222': subscriptionPlanLength === 4,
              'w-284': subscriptionPlanLength === 3
              }">
        <div class="blue-tick-div">
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick" *ngIf="subscriptionPlanLength !== 4">
          <img src="/assets/images/dot/close.png" alt="close" class="close" *ngIf="subscriptionPlanLength === 4">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
        </div>
        <button type="submit"
                class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center"
                [ngClass]="{
                  'w-222': subscriptionPlanLength === 4,
                  'w-284': subscriptionPlanLength === 3
                }">
        <span class="f-col text-center fw-medium">
          Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
        </span>
        </button>
      </div>
      <div class="fit-column-section br-10 m-920 w-282 p-fit-column position-absolute d-inline-block"
           *ngIf="subscriptionPlanLength === 4">
        <div class="fs-24-lh-36 f-col fw-bold ls-01">Super Enterprise</div>
        <div class="pt-9-custom col-grey w-222 font-outfit fs-16-lh-20 fw-normal">Customizable options for large-scale
          implementations
        </div>
        <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30 ls-02"><span
          class="fs-40-lh-48 ls-01 fw-semibold">$29</span> / per month
        </div>
        <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit w-222">
        <div class="blue-tick-div">
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
        </div>
        <button type="submit"
                class="trial-style-p fs-16-lh-20 ls-02 w-222 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center">
        <span class="f-col text-center fw-medium">
          Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
        </span>
        </button>
      </div>
    </div>
    <div class="background-fit-mobile z-index-0 position-relative d-inline-block w-1240 br-20">
      <img src="/assets/images/background/plan-background-2.png" alt="admin-background"
           class="sub-plan-2 br-20 d-flex position-absolute" *ngIf="subscriptionPlanLength === 2">
      <img src="/assets/images/background/plan-background-3.png" alt="admin-background"
           class="sub-plan-3 br-20 d-flex position-absolute" *ngIf="subscriptionPlanLength === 3">
      <img src="/assets/images/background/plan-background-4.png" alt="admin-background"
           class="sub-plan-4 br-20 d-flex position-absolute" *ngIf="subscriptionPlanLength === 4">
      <div class="fit-column-section br-10 p-fit-column position-absolute d-inline-block">
        <div class="fs-24-lh-36-mobile f-col fw-bold ls-01">Basic</div>
        <div class="pt-9-custom col-grey font-outfit fs-20-lh-30-fit-needs fw-normal">
          Ideal for small classrooms and individual educators
        </div>
        <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30-fit ls-02"><span
          class="fs-40-lh-48-mobile ls-01 fw-semibold">$29</span> / per month
        </div>
        <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit">
        <div class="blue-tick-div">
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/close.png" alt="close" class="close">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/close.png" alt="close" class="close">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/close.png" alt="close" class="close">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
        </div>
        <button type="submit"
                class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center">
          <span class="f-col text-center fw-medium">
          Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
        </span>
        </button>
      </div>
      <div class="fit-column-section mt-576 br-10 pb-pro-div position-absolute d-inline-block br-2-black">
        <div class="fs-24-lh-36-mobile f-col bg-spec w-70-custom br-10 fw-bold ls-01">&nbsp;&nbsp;&nbsp;Pro</div>
        <div class="p-fit-column">
          <div class="pro-text pt-9-custom col-grey font-outfit fs-20-lh-30-fit-needs fw-light">
            Perfect for institutions with advanced needs
          </div>
          <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30-fit ls-02"><span
            class="fs-40-lh-48-mobile ls-01 fw-semibold">$29</span> / per month
          </div>
          <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit">
          <div class="blue-tick-div">
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
            <br>
            <div class="h-10"></div>
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
            <br>
            <div class="h-10"></div>
            <img src="/assets/images/dot/close.png" alt="close" class="close" *ngIf="subscriptionPlanLength !== 2">
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick"
                 *ngIf="subscriptionPlanLength === 2">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
            <br>
            <div class="h-10"></div>
            <img src="/assets/images/dot/close.png" alt="close" class="close" *ngIf="subscriptionPlanLength !== 2">
            <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick"
                 *ngIf="subscriptionPlanLength === 2">
            <div class="plan-feature-div align-middle d-inline-block"><span
              class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          </div>
          <button type="submit"
                  class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center">
          <span class="f-col text-center fw-medium">
            Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
          </span>
          </button>
        </div>
      </div>
      <div class="fit-column-enterprise mt-1130 br-10 p-fit-column position-absolute d-inline-block"
           *ngIf="subscriptionPlanLength === 4 || subscriptionPlanLength === 3">
        <div class="fs-24-lh-36-mobile f-col fw-bold ls-01">Enterprise</div>
        <div class="pt-9-custom col-grey font-outfit fs-20-lh-30-fit-needs fw-light">
          Customizable options for large-scale implementations
        </div>
        <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30-fit ls-02"><span
          class="fs-40-lh-48-mobile ls-01 fw-semibold">$29</span> / per month
        </div>
        <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit">
        <div class="blue-tick-div">
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick" *ngIf="subscriptionPlanLength !== 4">
          <img src="/assets/images/dot/close.png" alt="close" class="close" *ngIf="subscriptionPlanLength === 4">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
        </div>
        <button type="submit"
                class="trial-style-p fs-16-lh-20 ls-02 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center">
        <span class="f-col text-center fw-medium">
          Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
        </span>
        </button>
      </div>
      <div class="fit-column-section mt-1715 br-10 m-920 w-282 p-fit-column position-absolute d-inline-block"
           *ngIf="subscriptionPlanLength === 4">
        <div class="fs-24-lh-36-mobile f-col fw-bold ls-01">Super Enterprise</div>
        <div class="pt-9-custom col-grey w-222 font-outfit fs-16-lh-20 fw-light">Customizable options for large-scale
          implementations
        </div>
        <div class="fit-needs-column-price f-col fw-medium fs-20-lh-30-fit ls-02"><span
          class="fs-40-lh-48-mobile ls-01 fw-semibold">$29</span> / per month
        </div>
        <img src="/assets/images/dot/hr-custom.png" alt="hr" class="hr-custom-fit w-222">
        <div class="blue-tick-div">
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature f-col fs-16-lh-20 fw-normal">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
          <br>
          <div class="h-10"></div>
          <img src="/assets/images/dot/blue-tick.png" alt="tick" class="blue-tick">
          <div class="plan-feature-div align-middle d-inline-block"><span
            class="plan-feature-close fw-normal f-col fs-16-lh-20">Plan Feature</span></div>
        </div>
        <button type="submit"
                class="trial-style-p fs-16-lh-20 ls-02 w-222 bgc-p border-0 column-btn mt-40 d-flex align-items-center justify-content-center">
        <span class="f-col text-center fw-medium">
          Start&nbsp;14&nbsp;Days&nbsp;for&nbsp;Free
        </span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isMenuClicked"
       class="not-mobile-animation g-20 d-flex flex-column position-relative w-100 justify-content-center align-items-center">
    <img src="/assets/images/background/admin-background.png" alt="background"
         class="smaller-background w-100 position-absolute">
    <div class="got-questions bg-spec d-flex br-10">
      <span class="fs-24-lh-36 fw-bold f-col text-center ls-01">&nbsp;Got Questions?&nbsp;</span>
    </div>
    <div>
      <span class="fs-40-lh-48 f-col fw-semibold ls-01">We've Got Answers!</span>
    </div>
    <div class="d-inline-flex w-1240 justify-content-center align-items-center">
      <button (click)="prevSlide()" class="nav-arrow border-0 d-inline-block bg-transparent">
        <img src="/assets/images/arrow/start-arrow.png" alt="start-arrow" class="start-arrow"
             *ngIf="currentPosition === 0">
        <img src="/assets/images/arrow/start-blue.png" alt="start-arrow" class="start-arrow"
             *ngIf="currentPosition !== 0">
      </button>
      <div class="m-0 carousel-custom position-relative d-flex justify-content-center align-items-center mt-40">
        <div class="start-free-trial br-10 br-2-black position-absolute d-inline-block bg-wh"
             *ngFor="let slide of slides; let i = index" [ngStyle]="getSlideStyles(i)">
          <span class="fs-24-lh-36 fw-bold f-col ls-01">{{ slide.title }}</span><br><br>
          <span class="fs-20-lh-30 col-grey font-outfit fw-light">{{ slide.text }}</span>
        </div>
      </div>
      <button (click)="nextSlide()"
              class="nav-arrow w-1240 justify-content-end d-flex border-0 d-inline-block bg-transparent">
        <img src="/assets/images/arrow/end-arrow.png" alt="end-arrow" class="end-arrow"
             *ngIf="currentPosition !== 4">
        <img src="/assets/images/arrow/end-grey.png" alt="end-arrow" class="end-arrow"
             *ngIf="currentPosition === 4">
      </button>
    </div>
  </div>
  <div *ngIf="!isMenuClicked"
       class="mobile-animation g-20 d-flex flex-column position-relative w-100 justify-content-center align-items-center">
    <img src="/assets/images/background/faq-background.png" alt="background"
         class="faq-background w-100 position-absolute">
    <div class="d-inline-block position-absolute">
      <div class="got-questions bg-spec br-10 text-center ml-120">
        <span class="fs-20-lh-30-fixed fw-medium f-col ls-02">&nbsp;Got Questions?&nbsp;</span>
      </div>
      <div class="text-center ml-90">
        <span class="fs-40-lh-48 f-col fw-semibold ls-01">We've Got Answers!</span>
      </div>
    </div>
    <div class="mt-110 d-inline-flex w-1240 justify-content-center align-items-center">
      <div class="m-0 carousel-custom position-relative d-flex justify-content-center align-items-center mt-40">
        <div class="start-free-trial br-10 br-2-black position-absolute d-inline-block bg-wh"
             *ngFor="let slide of slides; let i = index" [ngStyle]="getSlideStylesMobile(i)">
          <span class="fs-20-lh-30-fixed fw-medium f-col ls-01">{{ slide.title }}</span><br>
          <div class="mt-20-custom"></div>
          <span class="fs-20-lh-30 col-grey font-outfit ls-01">{{ slide.text }}</span>
        </div>
      </div>
      <button (click)="prevSlide()" class="nav-arrow border-0 d-inline-block bg-transparent">
        <img src="/assets/images/arrow/start-arrow.png" alt="start-arrow" class="start-arrow"
             *ngIf="currentPosition === 0">
        <img src="/assets/images/arrow/start-blue.png" alt="start-arrow" class="start-arrow"
             *ngIf="currentPosition !== 0">
      </button>
      <button (click)="nextSlide()"
              class="nav-arrow w-1240 border-0 d-inline-block bg-transparent">
        <img src="/assets/images/arrow/end-arrow.png" alt="end-arrow" class="end-arrow"
             *ngIf="currentPosition !== 4">
        <img src="/assets/images/arrow/end-grey.png" alt="end-arrow" class="end-arrow"
             *ngIf="currentPosition === 4">
      </button>
    </div>
  </div>
  <div *ngIf="!isMenuClicked">
    <app-footer></app-footer>
  </div>
</main>
