<ng-container *ngIf="data.showDeleteDialog">
  <h2 mat-dialog-title>Delete results</h2>
  <div *ngIf="selectedResultsForDelete.length > 1" mat-dialog-content>Are you sure you want to irreversibly
    delete {{ this.selectedResultsForDelete.length }} results (respondents will also lose access to deleted results)?
  </div>
  <div *ngIf="selectedResultsForDelete.length === 1" mat-dialog-content>Are you sure you want to irreversibly delete
    selected result (respondents will also lose access to it)?
  </div>
  <div mat-dialog-actions class="float-end">
    <button class="btn btn-light-secondary" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary mx-2" [mat-dialog-close]="true" (click)="deleteResults()">Delete</button>
  </div>
</ng-container>
