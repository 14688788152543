import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {QuizCategory} from '../models/quiz-category';
import {QuizCategoryService} from './quiz-category.service';

@Injectable({
  providedIn: 'root',
})
export class CategorySharedService {
  private categoriesSubject = new BehaviorSubject<QuizCategory[]>([]);
  categories$ = this.categoriesSubject.asObservable();

  constructor(private quizCategoryService: QuizCategoryService) {}

  setCategories(categories: QuizCategory[]) {
    this.categoriesSubject.next(categories);
  }

  loadCategories(): void {
    this.quizCategoryService.getAllCategories().subscribe((categories) => {
      this.categoriesSubject.next(categories);
    });
  }

  addCategory(category: QuizCategory): void {
    const categories = [...this.categoriesSubject.value, category]
    this.setCategories(categories);
  }
}
