<div class="header">
  <!-- navbar -->
  <nav class="navbar-default navbar navbar-expand-lg">
    <a id="nav-toggle" (click)="toggleSidebarMenu()" style="cursor: pointer">
      <i class="fe fe-menu"></i>
    </a>
    <!--Navbar nav -->
    <div class="ms-auto d-flex">
      <ul class="navbar-nav navbar-right-wrap ms-2 d-flex nav-top-wrap">
        <li class="ms-2 mt-1">
          <a class="rounded-circle" role="button" id="dropdownUser">
            <ul class="list-unstyled">
              <li>
                <a class="dropdown-item cursor-pointer sign-out" (click)="logout()">
                  <i class="fe fe-power me-2"></i> Sign Out
                </a>
              </li>
            </ul>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
