<h2 mat-dialog-title>Create New Category</h2>
<div mat-dialog-content>
  <form #categoryForm="ngForm" (ngSubmit)="addCategory(categoryForm)">
    <div class="mb-3">
      <label class="form-label" for="title">Title<span class="text-danger">*</span></label>
      <input type="text"
             name="category"
             class="form-control"
             [ngClass]="{'is-invalid': categoryName.touched && categoryName.errors?.['required']}"
             placeholder="Write a Category"
             ngModel
             #categoryName="ngModel"
             id="title" required>
      <div class="validation" *ngIf="categoryExistsMessage">{{ categoryExistsMessage }}</div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end p-4">
  <button class="btn btn-primary d-inline ms-3" (click)="closeDialog()">Cancel</button>
  <button class="btn btn-primary d-inline ms-3" [disabled]="categoryForm.form.invalid"
          (click)="addCategory(categoryForm)">Add New Category
  </button>
</div>
