<div class="bg-light rounded m-5">
    <div class="list-group list-group-flush">
      <mat-card>
        <mat-card-content>
          <div *ngIf="respondent">
            <div *ngIf="quiz?.status === QuizStatus.ACTIVE">
              <div class="mb-3">
                <div class="form-label text-center"> Verify your email address
                </div>
                <div class="text-center">  We emailed you a six-digit code to {{respondent.email}}.Enter the code below to
                  confirm your email address.
                </div><br><br>
                <form class="row px-md-20 text-center mx-auto mw-50" (ngSubmit)="onSubmit()">
                  <div class="mb-3 col ps-0 ms-2 ms-md-0">
                    <input type="email" class="form-control" placeholder="Verify your code" required [(ngModel)]="verificationCode" name="verificationCode">
                  </div>
                  <div class="mb-3 col-auto ps-0">
                    <button class="btn btn-primary" type="submit" [disabled]="verificationCode.length < 6">Submit</button>
                  </div>
                </form>
                <div *ngIf="!isVerificationCodeValid" class="text-center text-danger">
                  The verification code is incorrect. Please write the correct code.
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
</div>

