import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from 'src/environments/environment';
import {RespondentModel} from "../models/respondentModel";
import {Observable} from "rxjs";
import {QuestionAnswer} from "../models/QuestionAnswer";
import {ResultModel} from "../models/resultModel";
import {RespondentResponse} from "../models/RespondentResponse";
import {RespondentAnswerModel} from "../models/RespondentAnswerModel";
import {RespondentMonitoring} from "../models/RespondentMonitoring";
import {GeneralRequestModel} from "../models/generalRequestModel";
import {ApiResponse} from "../models/ApiResponse";

@Injectable({
  providedIn: 'root'
})
export class RespondentService {
  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {
  }

  saveRespondentAndStartQuiz(respondentModel: RespondentModel, quizId: string): Observable<RespondentResponse> {
    return this.http.post<RespondentResponse>(`${this.apiUrl}/respondents/save/${quizId}`, respondentModel)
  }

  getRespondentIdById(respondentId: string): Observable<RespondentResponse> {
    return this.http.get<RespondentResponse>(`${this.apiUrl}/respondents/${respondentId}`);
  }

  getQuestionAnswerByQuizAndByRespondent(quizId: string | undefined, respondentId: string | number | undefined, direction: string): Observable<QuestionAnswer> {
    let params = new HttpParams().set('direction', direction);
    if (respondentId) {
      params = params.set('respondentId', respondentId);
    }
    return this.http.get<QuestionAnswer>(`${this.apiUrl}/questions/test/${quizId}`, {params});
  }

  saveAnswers(quizId: string | undefined, questionId: string | undefined, respondentId: string | number | undefined, isSkip: boolean, answersName: string[], answerIds: string[]): Observable<void> {
    let params = new HttpParams()
      .set('quizId', quizId || '')
      .set('questionId', questionId || '')
      .set('respondentId', respondentId?.toString() || '')
      .set('isSkip', isSkip.toString())
      .set('answerIds', answerIds.join(', '));
    return this.http.post<void>(`${this.apiUrl}/responseAnswers/upsert`, answersName, {params});
  }

  saveResultAndGet(quizId: string | undefined, respondentId: string | undefined | number): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${this.apiUrl}/results/test/test-result/${quizId}/${respondentId}`, {});
  }

  checkRespondentVerify(verifyCode: string, quizId: string | undefined): Observable<RespondentModel> {
    let params = new HttpParams();
    if (quizId) {
      params = params
        .set('verifyCode', verifyCode)
        .set('quizId', quizId);
    }
    return this.http.get<RespondentModel>(`${this.apiUrl}/respondents/verify`, {params});
  }

  setEndTime(quizId: string | undefined, respondentId: string, ended: string): Observable<void> {
    let params = new HttpParams();
    if (quizId) {
      params = params
        .set('quizId', quizId)
        .set('respondentId', respondentId)
        .set('timeType', ended);
    }
    return this.http.patch<void>(`${this.apiUrl}/respondents/endTime`, {}, {params});
  }

  getRespondentAnswers(questionId: string | undefined, respondentId: string): Observable<RespondentAnswerModel> {
    return this.http.get<RespondentAnswerModel>(`${this.apiUrl}/responseAnswers/${questionId}/${respondentId}`);
  }

  getByQuizId(quizId: string, generalRequest: GeneralRequestModel): Observable<ApiResponse<RespondentMonitoring>> {
    let params = new HttpParams()
      .set('page', generalRequest.page)
      .set('count', generalRequest.count)
      .set('quizId', quizId)
    if (generalRequest.sortDirection) {
      params = params.set('sortDirection', generalRequest.sortDirection);
    }
    return this.http.get<ApiResponse<RespondentMonitoring>>(`${this.apiUrl}/admin/quiz-respondents/findByQuizId`, {params})
  }

  saveRespondent(respondent: RespondentResponse): Observable<RespondentResponse> {
    return this.http.post<RespondentResponse>(`${this.apiUrl}/respondents/saveRespondent`, respondent);
  }
}
