import {Component, OnInit} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {ResultModel} from "../../models/resultModel";
import {ResultService} from "../../services/result.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectionModel} from "@angular/cdk/collections";
import {GeneralRequestModel} from "../../models/generalRequestModel";
import {FormatUtil} from "../../util/format.util";
import {DateUtil} from "../../util/date.util";
import {ResultDialogComponent} from "../../result-dialog/result-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.css']
})
export class ResultsTableComponent implements OnInit {
  protected readonly FormatUtil = FormatUtil;
  protected readonly Math = Math;
  resultModel: ResultModel[] = []
  selectionResult = new SelectionModel<ResultModel>(true, []);
  totalPages: number = 1;
  pageSize: number = 10;
  quizId!: string
  resultsTotalCount: number = 0
  currentSortDirection: string = 'desc';

  constructor(
    private resultService: ResultService,
    private jwtHelper: JwtHelperService,
    private activeRouter: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,) {
    this.activeRouter.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['id']) {
        this.quizId = queryParams['id'];
      }
    });
  }

  ngOnInit(): void {
    this.resultService.updatedEvent.subscribe(_ => {
      this.totalPages = 1;
      this.pageSize = 10;
      this.getAllResultsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    })
    this.getAllResultsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
  }

  getAllResultsForQuiz(page: number, count: number, sortDirection: string) {
    const token = localStorage.getItem('fb-token');
    if (token && this.quizId) {
      const decodeToken = this.jwtHelper.decodeToken(token);
      const generalRequest: GeneralRequestModel = {page, count, sortDirection};
      this.resultService.getAllResultsByAdminIdAndQuizId(this.quizId, decodeToken?.userId, generalRequest)
        .subscribe(results => {
          this.resultModel = results.data;
          this.resultModel.forEach(result => {
            result.endTime = DateUtil.convertUTCtoLocal(result.endTime);
          })
          this.resultsTotalCount = results.meta.total
        });
    }
  }

  openDeleteDialog(resultsForDelete: ResultModel[]) {
    this.dialog.open(ResultDialogComponent, {
      width: '35%',
      data: {
        showDeleteDialog: true,
        selectedResultsForDelete: resultsForDelete
      }
    });
  }

  goToRespondentResult(result: ResultModel) {
    this.router.navigate(['respondent-result'], {
      queryParams: {
        quizId: result.quizId,
        respondentId: result.respondentId
      }
    })
  }


  isAllResultsSelected(): boolean {
    const numSelected = this.selectionResult.selected.length;
    const totalRows = this.resultModel?.length;
    return numSelected === totalRows;
  }

  toggleAllResults() {
    if (this.isAllResultsSelected()) {
      this.selectionResult.clear();
    } else {
      this.selectionResult.select(...this.resultModel);
    }
  }

  checkboxLabel(event: MouseEvent, result: ResultModel): void {
    this.selectionResult.toggle(result);
  }

  goToPreviousPage() {
    if (this.totalPages > 1) {
      this.totalPages--;
      this.getAllResultsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    }
  }

  goToNextPage() {
    if (this.resultModel.length >= this.pageSize) {
      this.totalPages++;
      this.getAllResultsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    }
  }

  goToPageNumber(page: number) {
    if (page > 0 && page <= this.totalPages) {
      this.totalPages = page;
      this.getAllResultsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection)
    }
  }

  toggleSort() {
    if (this.currentSortDirection === 'desc') {
      this.currentSortDirection = 'asc';
    } else {
      this.currentSortDirection = 'desc';
    }
    this.getAllResultsForQuiz(this.totalPages, this.pageSize, this.currentSortDirection);
  }
}
