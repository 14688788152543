import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {QuizForOtherSettingsModel} from "../../models/quizForOtherSettingsModel";
import {OtherSettingsService} from "../../services/other-settings.service";
import {QuizService} from "../../services/quiz.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Quiz} from "../../models/quiz";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AngularEditorConfigsUtil} from "../../util/angular-editor-configs.util";
import {ActivatedRoute} from "@angular/router";
import {CustomSnackbarComponent} from './custom-snackbar/custom-snackbar.component';
import {CustomMaxLengthValidator} from "../../validators/customMaxLengthValidator";
import {EditorValid} from "../../util/editor-valid";
import {Alert} from "../../models/alert.enum";
import {QuizStatusCheckService} from "../../services/quiz.status.check.service";
import {QuizStatus} from "../../models/quizStatus";

@Component({
  selector: 'app-other-settings',
  templateUrl: './other-settings.component.html',
  styleUrls: ['./other-settings.component.css'],
})
export class OtherSettingsComponent implements OnInit {
  initialFormValues!: Quiz;
  quiz: Quiz | undefined;
  apiUrl: string = "";
  appBaseUrl: string = "";
  setupQuizForm!: FormGroup;
  @ViewChild('copiedLink', {static: false}) copiedLink!: ElementRef;
  protected readonly EditorValid = EditorValid;
  hours = 0;
  minutes = 0;
  maxHours = 23;
  maxMinutes = 59;
  min = 0;
  step = 1;
  thumbLabel = true;
  readOnly = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    rawPaste: true,
    toolbarHiddenButtons: [
      AngularEditorConfigsUtil.toolbarHiddenButtons_undoRedo,
      AngularEditorConfigsUtil.toolbarHiddenButtons
    ]
  }
  isSectionHidden: boolean = false;
  protected readonly Alert = Alert;

  formatTime(hours: number, minutes: number): string {
    return `${this.padZero(hours)}:${this.padZero(minutes)}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  get endMessageValue(): string {
    return this.setupQuizForm.get("endMessage")?.value
  }

  get alertValue(): string {
    return this.setupQuizForm.get("alert")?.value;
  }

  constructor(private quizService: QuizService,
              private formBuilder: FormBuilder,
              private otherSettingsService: OtherSettingsService,
              private route: ActivatedRoute,
              private _snackBar: MatSnackBar,
              private quizStatusCheckService: QuizStatusCheckService
  ) {
    this.setupForm()
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      const quizId = queryParams['id'];
      if (quizId) {
        this.quizService.getQuizById(quizId).subscribe(quiz => {
          this.quiz = quiz
          this.updateReadOnlyStatus();
          this.setupForm();
          this.loadQuizSettings();
          this.initializeEditorConfig();
        })
      }
    })
    this.apiUrl = environment.apiUrl;
    this.appBaseUrl = location.origin;
  }

  updateReadOnlyStatus(): void {
    this.readOnly = this.quizStatusCheckService.isReadOnly(this.quiz?.status)
  }

  initializeEditorConfig(): void {
    this.editorConfig = AngularEditorConfigsUtil.initializeEditorConfig(this.editorConfig);
  }

  setupForm() {
    this.setupQuizForm = this.formBuilder.group({
      isRandom: new FormControl(false),
      endMessage: new FormControl('',
        Validators.compose([CustomMaxLengthValidator.customMaxLengthValidator(150)])),
      isAnswerInAnyOrder: new FormControl(false),
      isPercentage: new FormControl(false),
      isTime: new FormControl(false),
      passMark: [0, [Validators.min(0), Validators.max(100)]],
      minutes: new FormControl(0),
      hours: new FormControl(0),
      isReviewedByAI: new FormControl(false),
      allowedTabNumber: new FormControl('',
        [Validators.required, Validators.min(0), Validators.max(10)]),
      alert: new FormControl(Alert.DISABLE)
    });
  }

  copyLink() {
    const linkToCopy = this.copiedLink.nativeElement.href;
    this.copyToClipboard(linkToCopy);
  }

  private copyToClipboard(text: string) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  resetValues() {
    this.hours = 0;
    this.minutes = 0;
  }

  onToggleChange($event: MouseEvent, checked: boolean, controlName: string) {
    this.setupQuizForm.get(controlName)?.setValue(checked)
  }

  submit() {
    const quizSetup = this.setupQuizForm.getRawValue() as QuizForOtherSettingsModel;
    let hoursValue = this.setupQuizForm.get('hours')?.value;
    let minutesValue = this.setupQuizForm.get("minutes")?.value;
    quizSetup.id = this.quiz?.id!;
    quizSetup.duration = hoursValue * 60 + minutesValue
    this.otherSettingsService.saveOtherSettings(quizSetup)
      .subscribe(() => {
        this.loadQuizSettings();
      });
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 4000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: 'mat-toolbar',
    });
  }

  loadQuizSettings() {
    this.otherSettingsService.getQuizSettings(this.quiz?.id!).subscribe(
      (quizSettings: QuizForOtherSettingsModel) => {
        quizSettings.endMessage ??= '';
        quizSettings.isAnswerInAnyOrder ??= false;
        quizSettings.isPercentage ??= false;
        quizSettings.isTime ??= false;
        quizSettings.passMark ??= 0;
        this.setupQuizForm.patchValue(quizSettings);
        this.initialFormValues = this.setupQuizForm.value;
        this.hours = Math.floor(quizSettings.duration / 60);
        this.minutes = quizSettings.duration % 60;
      }
    );
  }

  isFormModified(): boolean {
    const currentFormValues = this.setupQuizForm.value;
    return JSON.stringify(currentFormValues) !== JSON.stringify(this.initialFormValues);
  }

  onRadioChange(selectedOption: string) {
    this.isSectionHidden = selectedOption === 'testBlock';
  }

  protected readonly QuizStatus = QuizStatus;
}
